import { toast } from "react-toastify"
import api from "../../../api"
import browserHistory from "../../../config/browserHistory"
import fire from "../../../config/fire"
import {  getOrdersFailure, getOrdersSuccess, setOrdersLoading, setPage, setTotalElements } from "../ordersSlice"
import storage from "redux-persist/lib/storage"


export const fetchOrders = (payload) => async (dispatch, getState) => {

    const {userSlice} = getState()

    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken

    console.log(uid)
   
    dispatch(setOrdersLoading())
  
    try {
        // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
        // console.log(token)

        const response = await api.get(`api/cj/enquiry/customer/orders/${uid}?`,{
            params : {
                pageNo : payload,
                size: 5
            }, 
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })

        let result = JSON.stringify(response.data)
        let res_data = JSON.parse(result)

        console.log(res_data.data)
        
        if(res_data.status == true){
            dispatch(getOrdersSuccess(res_data.data))
            dispatch(setPage(payload))
        }else{
            if(res_data.error){
                toast.error(res_data.error.message)
            }else{
                console.log(res_data.error.message)
            }
            
            dispatch(getOrdersFailure(res_data.error.message))
           
        }
    
    } catch (error) {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!")
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }
        }else{
            toast.error(error.message)
            dispatch(getOrdersFailure(error.message))
        }
    }
}


