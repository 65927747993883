import React, { Component } from 'react';
import './createEnquiry.css'
import api from '../../api/index';
import browserHistory from '../../config/browserHistory'
import LoadingOverlay from 'react-loading-overlay';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "antd/dist/antd.css";
import { Button, Col, Form, Input, Row } from "antd";
import { Select } from 'antd';
import { createEnquiry, enquiryFileUpload } from './services/enquiry';
import {connect} from 'react-redux';
import { fetchAddress } from '../profile/services/profile';
import moment from 'moment';
import { addLineItems, deleteLineItems, lineItemsHandleChange, setEnquiriesLoading, setInitialState } from './enquirySlice';
import { Collapse } from 'antd';
import Pdf from '../../images/pdf.png';
import Excel from '../../images/excel.png';
import Word from '../../images/word.png';
import Yantra from '../../images/yantra.png';
import UserList from '../../images/user-list.png';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 

const { Panel } = Collapse;


const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const layout1 = {
	labelCol: { span: 6 },
	wrapperCol: { span: 16 },
  };

class CreateEnquiry extends Component {

	formRef = React.createRef();
	lineItemFormRef = React.createRef();
	fileObj = [];
  	fileArray = [];
 	imageArray=[];
	imageName=[];
	currentUser={};
	
	constructor(props){
    super(props)
    this.state = {
			file: [],
			make:'',
			model:'',
			title:'',
			message:'',
			imageUrl:[],
			isLoading:false,
			calender:'',
			shippingAddressId: '',
			billingAddressId: '',
			application:'',
			assemblyModel:'',
			partName:'',
			partNumber:'',
			quantity: '',
			brand:'', 
			manufacturer: '',
			lineItems_error: {quantity:'',partName:'', status:"new"},
			img: [],
			lightBoxOpen: false,	

		}
	}

	async componentWillMount() {
		this.currentUser=JSON.parse(localStorage.getItem("currentUser"))
		await this.props.dispatch(fetchAddress(this.currentUser.uid))
	}
	
	componentDidMount(){
		this.props.dispatch(setInitialState())
		
	}

	fileChange =(e)=> {
		debugger
		this.props.dispatch(setEnquiriesLoading())
		
		this.fileObj.push(e.target.files)
		for (let i = 0; i < this.fileObj[0].length; i++) {
			this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
		}
		this.setState({file: this.fileArray})

    	const fd = new FormData();
		for (let j = 0; j < e.target.files.length; j++) {
		fd.append('file', e.target.files[j])
		}

		this.props.dispatch(enquiryFileUpload(fd))
		
	}

	handleChange=(e)=> {
    this.setState({[e.target.name]: e.target.value})
	}
	
	handleSubmit= async (e)=>{
		e.preventDefault();
		let date = new Date()


		try {
			
			const value = await this.formRef.current.validateFields();
			let data = {
				
				customerId: this.currentUser.uid,
				code:this.currentUser.uid,
				enquiryId: '',
				title: this.state.title,
				status: "New",
				month:moment(date).format("MM"),
				year:moment(date).format("YYYY"),
				docs: {"docs": this.props.imageUrl },
				application: null,
				assembly: null,
				assemblyName: null,
				source: 'NES',
				associateId:'',
				ylaId:'',
				ylxId:'',
				enquiryText:'',
				ylxRemarks:'',
				customerRemarks:'',
				make: this.state.make,
				//equipmentType : this.state.make,
				equipmentModel: this.state.model,
				message: this.state.message,
				billingAddressId: this.state.billingAddressId,
				deliveryAddressId: this.state.shippingAddressId,
				enquiryItemDto: this.props.lineItems,
			}

			console.log(data)

			this.props.dispatch(createEnquiry(data))
		
    	} catch (errorInfo) {
			console.log("failure", errorInfo);
			toast.info("Invalid form entry..!", {position: "top-center"})
    	}
	}

	handleDelivery = (value) => {
		
		this.setState({shippingAddressId : value})
	}

	handleBilling = (value) => {
		
		this.setState({billingAddressId : value})
	}

	menu =  (
		<Select defaultValue="Select Shipping Address" name="shippingAddressId" onChange =  {(value) => {
			
			this.setState({shippingAddressId : value})
		}}>
			{this.props.shippingAddress.map((el, index) => (
				<Option value={el.id}>{`${el.addressLine1} ${el.addressLine2} ${el.city} ${el.state} ${el.country} ${el.pinCode}`}</Option>
			))}
		</Select>
		)

	billingAddress = (
		<Select defaultValue="Select Billing Address" name="billingAddressId" onChange = {(val) => {
			
			this.setState({billingAddressId : val})
		}} >
			{this.props.billingAddress.map((el, index) => (
				<Option value={el.id}>{`${el.addressLine1} ${el.addressLine2} ${el.city} ${el.state} ${el.country} ${el.pinCode}`}</Option>
			))}
		</Select>
	)

	
	async addClick () {

		debugger

		let { application,
		assemblyModel,
		partName,
		partNumber,
		quantity,
		brand, 
		manufacturer } = this.state

		try{
			const value = await this.lineItemFormRef.current.validateFields();
			let data = {
				application,
				assemblyModel,
				partName,
				partNumber,
				quantity,
				brand, 
				manufacturer,
			}
			console.log(data)
			await this.props.dispatch(addLineItems(data))
			
			this.lineItemFormRef.current.setFieldsValue({ 
				application:"",
				assemblyModel:"",
				partName:"",
				partNumber:"",
				quantity: "",
				brand: "",
				manufacturer: ""
			});
			
			
		}catch (err){
			toast.error('Invalid Form', {position: "top-center"})

		}
		
	}

	removeClick =(index) => {
		this.props.dispatch(deleteLineItems(index))
	}

	validateDeliveryForm(i) {

		let { lineItems } = this.props
		let {lineItems_error} = this.state
		console.log(lineItems)
		
		let formIsValid = true;
		debugger

		if (!lineItems[i]["quantity"]) {
			formIsValid = false;
			lineItems_error['quantity']= "*Please enter Quantity";
			this.setState({lineItems_error})
			
		}

		if (!lineItems[i].partName) {
			formIsValid = false;
			lineItems_error['partName']= "*Please enter PartName";
			this.setState({lineItems_error})
			
		}

		if ((lineItems[i]["quantity"]).length != 0) {
			if (!lineItems[i]["quantity"].match(/^[0-9]/)) {
			  formIsValid = false;
			  lineItems_error.quantity = "*Please enter valid Quantity.";
			  this.setState({lineItems_error})
			  
			}
		  }

		  return formIsValid
	}

	addLineItems = (i) => {
		debugger
		if (this.validateDeliveryForm(i)) {
			toast.success('Added Successfully', {position: "top-center"})
		}else{
			toast.error('Invalid Form', {position: "top-center"})
		}
	}

	lineItemsHandleChange(e, i){
		let data = {
			index: i,
			name: e.target.name,
			value: e.target.value
		}

		this.props.dispatch(lineItemsHandleChange(data))
	}

	createLineItem = () => {
		const {lineItems } = this.props

		

		return (<React.Fragment>
		{(lineItems || []).map((item, index)=> {
				return (
					<Collapse accordion className="mb-2" >
					<Panel header={`Line Item ${index + 1}`} key={index}>
					<div className="row">
						<div class="col-md-6 col-sm-12">
							<table class="table borderless">
								<tbody>
									<tr>
										<td>Application:</td>
										<th>{item.application}</th>
									</tr>
									<tr>
										<td>assemblyModel:</td>
										<th>{item.assemblyModel}</th>
									</tr>
									<tr>
										<td>partName:</td>
										<th>{item.partName}</th>
									</tr>
									<tr>
										<td>partNumber:</td>
										<th>{item.partNumber}</th>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="col-md-6 col-sm-12">
							<table  class="table borderless">
								<tbody>
									
									<tr>
										<td>quantity:</td>
										<th>{item.quantity}</th>
									</tr>
									<tr>
										<td >brand:</td>
										<th>{item.brand}</th>
									</tr>
									<tr>
										<td>manufacturer:</td>
										<th>{item.manufacturer }</th>
									</tr>

								</tbody>
							</table>
						</div>
					</div>

					<Row className="float-right mb-4">
						<Button className="enquiry_remv_btn mr-4" shape="round" onClick={() => this.removeClick(index)}>
							Remove
						</Button>
					</Row>

						
					</Panel>
					</Collapse>
				)
				
			})}</React.Fragment>)
		

	}
	
	render() {
		let fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gmi
		console.log(this.props.shippingAddress)
		console.log(this.props.billingAddress)
		return (
			<React.Fragment>
				<div className='enquiry_body'>
					<ToastContainer />
					<LoadingOverlay active={this.props.loading} spinner styles={{overlay: (base) => ({ ...base, position: 'fixed', })}}>
					  
					<div className="row quotation_panel d-flex flex-column">  
						<h4 class="page--title h5 text-white ml-4 pl-4">Create Enquiry</h4>
						<ul class="d-flex flex-row">
							<li className="text-white"><Link style={{color:"#CACACA"}} className=" ml-4 pl-4" to="/dashboard">Dashboard &nbsp;/ &nbsp;</Link></li>
							<li className="text-white"><Link style={{color:"#CACACA"}} className="" to="/enquiry">My enquiry &nbsp;/ &nbsp;</Link></li>
							<li className=" "><span style={{color:'white'}}> Create Enquiry</span></li>
						</ul>
					</div>  

					<div className='pl-4 pr-4 ml-4 mr-4 list_table '>
						
						<div className='card card_shadow'>
							<div class="card-header pl-4 pr-4 ">
							<div className='table_top'>
								<h4 className='table_top_head d-flex flex-row '><div className="icon-top"><img src={UserList} width="20px" height="20px" ></img></div>&emsp;Create Enquiry</h4>
								<hr className="create__enq__hr"></hr>
								
							</div>
							
								<Form className="enq_form mt-4 pt-4" ref={this.formRef} >
									<Row >
										<Col span={11} className = 'ml-4'>
											<Form.Item name="model" label="Equipment Model" {...layout}
												rules={[{ required: true, message: "Please enter equipment model!" }]}
											>
												<Input autocomplete="off" name="model" onChange={this.handleChange} placeholder="3DX or PC200-2 or SY220" />
											</Form.Item>
										</Col>
										<Col span={10} className = 'ml-4'>
											<Form.Item name="title" label="Title" {...layout}
												rules={[{ required: true, message: "Please enter title!" }]}
											>
												<Input autocomplete="off" name="title" onChange={this.handleChange} placeholder="Subject line of your enquiry" />
											</Form.Item>
										</Col>	
									</Row>
									<Row >
										<Col span={11} className = 'ml-4'>
											<Form.Item name="make" label="Make" {...layout}
											rules={[{ required: true, message: "Please enter Make!" }]}>
												<Input autocomplete="off" name="make" onChange={this.handleChange} placeholder="JCB or Hitachi or Sany" />
											</Form.Item>
										</Col>
										<Col span={10} className = 'ml-4 '>
											<Form.Item name="message" label="Message" {...layout}
												rules={[{ required: this.props.lineItems.length==0, message: "Please enter message" }]}
											>
												<Input.TextArea rows={3} autocomplete="off" name="message" onChange={this.handleChange} placeholder="Type list of items and qty required" />
											</Form.Item>
										</Col>
										
									</Row>
									
									<Row >
										<Col span={11} className = 'ml-4'>
											{this.props.shippingAddress.length == 0 ?
												<p className="helper_text pl-2"><strong>Note:  </strong>No Shipping Address Found, Please add Address in profile page</p>
												: ''}
											<Form.Item name="shippingAddress" label="Shipping Address" {...layout}
												rules={[{ required: true, message: "Please enter Shipping Address!" }]}
											>
												{this.menu}
											</Form.Item>
										</Col>
										<Col span={10} className = 'ml-4'>
											{this.props.billingAddress.length == 0 ?
											<p className="helper_text pl-2"><strong>Note: </strong>No Blling Address Found, Please add Address in profile page</p>
											: ''}
											<Form.Item name="billingAddress" label="Billing Address"  {...layout}
												rules={[{ required: true, message: "Please enter Billing Address!" }]}
											>
												{this.billingAddress}
											</Form.Item>

										</Col>
										
									</Row>
									{/*<Row >
										
										<Col span={23} className = 'ml-4 mr-4'>
											{this.props.billingAddress.length == 0 ?
											<p className="helper_text pl-2"><strong>Note: </strong>No Blling Address Found, Please add Address in profile page</p>
											: ''}
											<Form.Item name="billingAddress" label="Billing Address"
												rules={[{ required: true, message: "Please enter Billing Address!" }]}
											>
												{this.billingAddress}
											</Form.Item>

										</Col>
									</Row> */}
									<Row className="line__items mt-4 mb-4 pb-4 d-flex flex-column">
										<Col className="jss103 ml-4 mt-4">Line Items</Col>
										<Col span={23} className = 'mt-4'>
											
												
												<Form ref={this.lineItemFormRef} id="lineItems_ref">					
													<Row>
														<Col span={11} className = 'ml-4 '>
															<Form.Item name="application" {...layout} label="Application"> 
																<Input autocomplete="off" name="application" onChange={this.handleChange} />
															</Form.Item>

														</Col>
														<Col span={11} className = 'ml-4'>
															<Form.Item name="assemblyModel" {...layout} label="Assembly Model">
																<Input autocomplete="off" name="assemblyModel" onChange={this.handleChange} />
															</Form.Item>
														</Col>
													</Row>
													<Row>
														<Col span={11} className = 'ml-4'>
															<Form.Item name="partName" label="Part Name" {...layout}
																rules={[{ required: true, message: "Please enter Part Name" }]}
															>
																<Input autocomplete="off" name="partName" onChange={this.handleChange} />
															</Form.Item>

														</Col>
														<Col span={11} className = 'ml-4'>
															<Form.Item name="partNumber"  {...layout} label="Part Number">
																<Input autocomplete="off" name="partNumber" onChange={this.handleChange} />
															</Form.Item>
														</Col>
													</Row>
													<Row>
														<Col span={11} className = 'ml-4'>
															<Form.Item name="quantity" label="Quantity"  {...layout}
																rules={[{ required: true, message: "Please enter quantity" },
																{ pattern: /^[0-9]*$/, message: "Invalid quantity!" },
																{ max: 10, message: "Should not exceed 10 digits!" },
															]}
															>
																<Input autocomplete="off" name="quantity" onChange={this.handleChange} />
															</Form.Item>

														</Col>
														<Col span={11} className = 'ml-4'>
															<Form.Item name="brand"  {...layout} label="Brand">
																<Input autocomplete="off" name="brand" onChange={this.handleChange} />
															</Form.Item>
														</Col>
													</Row>
													<Row>
														<Col span={11} className = 'ml-4'>
															<Form.Item name="manufacturer" {...layout} label="Manufacturer"> 
																<Input autocomplete="off" name="manufacturer" onChange={this.handleChange} />
															</Form.Item>

														</Col>
														<Col span={11} className = 'ml-4'>
															<Form.Item name="make" {...layout} label="Make">
																<Input autocomplete="off" name="make" onChange={this.handleChange} />
															</Form.Item>
														</Col>
													</Row>
													
												</Form>
												<Row className="float-right">
													<Button className="enquiry_remv_btn mr-4" shape="round" onClick={this.addClick.bind(this)}>
														Add Line Items
													</Button>
												</Row>
												<Row className="mt-5 pt-4">
													<Col span={24} className = 'ml-4'>
														{this.createLineItem()}
													</Col>
													
												</Row>

												

											
										</Col>
										
									</Row>
									
									<Row className="mt-4">
										<Col span={11} className = 'ml-4 mr-4'>
											<Form.Item name="file" label="Attach Image/Pdf/xlsx/doc" >
												<Input type="file" name='file' onChange={this.fileChange}  multiple />
														{/* <label className="custom-file-label"></label> */}
											</Form.Item>
										</Col>
										
									</Row>
								</Form>
							
							
							{this.state.file.length > 0 ?<fieldset className='col-lg-12 col-sm-12 mt-4 mb-4 selected_files'  > 
								<legend style={{color: "white"}} className="ml-2 mb-2">Selected Files</legend>
								{(this.props.imageUrl || []).map((url) => {

									return url['doc_url'].match(fileExtensionPattern)[0] == ".pdf" ?
									<a href={url['doc_url']} target="_blank">
									<img src={Pdf} alt="pdf" width='100px' height='100px' className='mr-3' />
									</a>		:
									url['doc_url'].match(fileExtensionPattern)[0] == ".xlsx" ?
									<a href={ url['doc_url']} >
									<img src={Excel} alt="pdf" width='100px' height='100px' className='mr-3' />
									</a>:

									url['doc_url'].match(fileExtensionPattern)[0] == ".docx" ?
									<a href={ url['doc_url']} >
									<img src={Word} alt="doc" width='100px' height='100px' className='mr-3' />
									</a>:
									
									<img src={url['doc_url']} alt="..." width='100px' height='100px' className='mr-3' onClick={() => this.setState({ lightBoxOpen: true, img: url['doc_url']})}/>
								})}
							</fieldset>:''}
							<button className='enquiry_sub_btn mb-4' onClick={this.handleSubmit}> Submit </button>
							{this.state.lightBoxOpen && (
								<Lightbox
								  mainSrc={this.state.img}
								 
								  onCloseRequest={() => this.setState({ lightBoxOpen: false })}
								  
								/>
							  )}
						
						
							</div>
						
						</div>
						</div>
					</LoadingOverlay>
					
				</div>
				<div className="dashboard_layout_footer pt-2 mb-4 text-blue" >
					<a className="text-blue ml-4  pl-4"> www.yantralive.com</a>
					<img src={Yantra}  height="60px"></img>
					<a className="text-blue pr-4 mr-4">© 2021 - All Right Reserved</a>
				</div>
			</React.Fragment>
		);
	}
}



const mapStateToProps = (state, ownProps) => {
	console.log(state)
    return {
		
		uid: state.userSlice.user.payload.uid,
		shippingAddress: state.profileSlice.shipping_address1,
		billingAddress:  state.profileSlice.billing_address1,
		loading: state.enquiriesSlice.loading,
		imageUrl: state.enquiriesSlice.imageUrl,
		lineItems: state.enquiriesSlice.lineItems
		
    }
};


export default connect(mapStateToProps)(CreateEnquiry);
