import React, { Component } from 'react';
import {TextField, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox} from '@material-ui/core';
import { Modal, Button, Row, Col, Input, Badge, Image, Form,} from 'antd';
import browserHistory from '../../config/browserHistory';
import { ToastContainer, toast } from 'react-toastify';
import fire from '../../config/fire';
import './profile.css';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createShippingAddress, deleteBilling, deleteShipping, fetchAddress, fetchProfile, setAddressSelectedOption, updateAddress, updateProfile } from './services/profile';
import {setBillingAddressHandleChange, addShippingAddress, deleteShippingAddress, setAddressHandleChange, setHandleChange, addBillingAddress } from './profileSlice';
import LoadingOverlay from 'react-loading-overlay';
import Yantra from '../../images/yantra.png';
import { Link } from "react-router-dom";
import ProfileIcon from '../../images/profile-user (2)1.png';



const useStyles = makeStyles((theme) => ({
	root: {
	  width: '100%',
	},
	heading: {
	  fontSize: theme.typography.pxToRem(15),
	  fontWeight: 500,
	  
	},
  }));
  

class Profile extends Component {
	currentUser={};
	customer={}
	profileFormRef = React.createRef();
	pwdFormRef = React.createRef();
	constructor(props){
		super(props)
		this.currentUser=JSON.parse(localStorage.getItem("currentUser"))
		
    this.state = {
			fullname:'',
			phone:'',
			company:'',
			email:'',
			contact_person_name:'',
			gst:'',
			contact_person_phone:'',
			billing_address:'',
			shipping_address:'',
			shipping_address1: [ ],
			billing_address1: [ ],
			sms:false,
			whatsapp:false,
			emailmsg:false,
			flagdissable:false,
			visible:false,
			newpwd:'',
			confirmpwd:'',
			update_id:'',
			set_id: {id:'', index: ''},
			setBilling_id: {id:'', index: ''},
			deliverySelectedOption: 'noData',
			billingSelectedOption: 'noData',
			delivery_error: {addressLine1:'',addressLine2:'', city:'', state:'',  country:'', pinCode:'', status:"new"},
			billing_error: {address_line_1:'',address_line_2:'', city:'', state:'',  country:'', pincode:'', status:"new"},
			editable: true,
			emailEdit: false,
			accnt: true,
			pwd: false,
			addr: false,

		}
	}

	async componentWillMount () {
	
		await this.props.dispatch(fetchProfile())	
		await this.props.dispatch(fetchAddress(this.currentUser.uid))
		if(this.props.fullname == '' || this.props.fullname == null){
			this.setState({editable: true})
		}else{
			this.setState({editable: false})
		}
		if(this.props.email == '' || this.props.email == null){
			this.setState({emailEdit: true})
		}else{
			this.setState({emailEdit: false})
		}
		
		this.setState({emailmsg: this.props.emailmsg, whatsapp: this.props.whatsapp, sms: this.props.sms})
		
		this.profileFormRef.current.setFieldsValue({ 
			phone:this.props.phone,
			company:this.props.company,
			email: this.props.email,
			fullname: this.props.fullname
			
		});
	}

	

	handleChange=(e)=>{
		let data = {
			name : e.target.name,
			value: e.target.value
		}
		this.props.dispatch(setHandleChange(data))

		//this.setState({[e.target.name]:e.target.value})
	}




	handleMessage=(e)=>{
		// debugger
		// this.setState({[e.currentTarget.name]:e.currentTarget.checked})
		let data = {
			name : e.currentTarget.name,
			value: e.currentTarget.checked
		}
		this.props.dispatch(setHandleChange(data))
	}

	

	submit=()=>{

		const {sms, whatsapp, emailmsg} = this.props

		

		if(JSON.parse(sms) == true || JSON.parse(whatsapp) == true || JSON.parse(emailmsg) == true){
			let msg_data = {
				"sms": this.props.sms,
				"whatsApp": this.props.whatsapp,
				"email": this.props.emailmsg
			}
	
			console.log(msg_data)
	
			let data={
				id: this.props.update_id,
				code: this.currentUser.uid,
				customer: this.props.fullname,
				company: this.props.company,
				gstType: null,
				gst: this.props.gst,
				streetAddress: "",
				city: "",
				state: "",
				country: "",
				pinCode: "",
				phone:  this.props.phone,
				email: this.props.email,
				currency: "",
				customerType: "",
				noOfMachinesOwned: 0,
				billingAddress: this.props.billing_address,
				deliveryAddress: this.props.shipping_address,
				designation: "",
				contactPerson1: this.props.contact_person_name,
				email1: "",
				mobile1: this.props.contact_person_phone,
				alternatePhone: "",
				contactPerson2: "",
				email2: "",
				mobile2: "",
				panNo:'',
				status: "",
				website: "",
				lastPurchase: "2050-01-01",
				rating: 0,
				fsCustomerId: null,
				preferredLanguage: null,
				customerGroup: null,
				messagePreference: msg_data
				
			}
	
			console.log(data)
	
			this.props.dispatch(updateProfile(data))

		}else{
			toast.error("Please select atleast one default Messaging Preferences!!")

		}

		


		
	}

	resetPassword = async ()=>{
		try{
			const value = await this.pwdFormRef.current.validateFields();
			fire.auth().sendPasswordResetEmail(this.state.newpwd).then(res => {
				console.log(res)
				this.pwdFormRef.current.setFieldsValue({ 
					newpwd:"",	
				});
				this.setState({newpwd: ""})
				toast.success("Submitted successfully, Please Check Your Inbox!")
			}).catch(err=>{
				toast.info(err.message, {position: "top-center"})
			})
		}catch (err){
			toast.error(err.message, {position: "top-center"})
		}
		
	}

	clearModal = () => {
		this.setState({visible:false})
		browserHistory.push('/profile')
	}


	onDeliveryValueChange = (e) => {

		let val = e.target.value
		let data = {
			addressType: 'delivery',
			customerId : this.currentUser.uid,
			isActive: 1,
			id: parseInt(val),
		}

		this.props.dispatch(setAddressSelectedOption(data))
		
	}


	createUI(){
		const {delivery_error} = this.state
		const {shipping_address1, deliverySelectedOption} = this.props
		console.log(shipping_address1)
		return shipping_address1.map((el, i) => 

		<div className="mt-3 delivery_address_items d-flex flex-row justify-content-start align-items-center">
			
			<Accordion>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography className={useStyles.heading}> {`Shipping Address ${i + 1}`} </Typography>
				</AccordionSummary>
				<AccordionDetails>
					<fieldset className=" pl-4 pr-4 bg-white" key={i}>
						<div className='d-flex flex-column bg-white' >
							<div className='address_input bg-white'>
								<div className='row mt-2 '>
									<TextField label="Address Line 1" name='addressLine1' type='text' className='col mr-5' autoComplete='off' value={el.addressLine1  } onChange={e => this.handleClickChange(e, i)} helperText = {shipping_address1[i].status == "new" ? delivery_error.addressLine1 : ''} size="small" required  />
									<TextField label="Address Line 2" name='addressLine2' type='text' className='col mr-5' autoComplete='off' value={el.addressLine2} onChange={e => this.handleClickChange(e, i)} size="small"  />
								</div>
								<div className='row mt-4 '>
									<TextField label="Name" name='name' type='text' className='col mr-5' autoComplete='off' value={el.name} onChange={e => this.handleClickChange(e, i)} helperText = {shipping_address1[i].status == "new" ? delivery_error.name : ''} size="small" required />
									<TextField label="Phone" name='contact' type='text' className='col mr-5' autoComplete='off' value={el.contact} onChange={e => this.handleClickChange(e, i)} size="small" helperText = {shipping_address1[i].status == "new" ? delivery_error.contact : ''} size="small" required />
									
								</div>
								<div className='row mt-4 '>
									
									<TextField label="GSTIN" name='gstIn' type='text' className='col mr-5' autoComplete='off' value={el.gstIn} onChange={e => this.handleClickChange(e, i)} size="small" helperText = {shipping_address1[i].status == "new" ? delivery_error.gstIn : ''} size="small"   />
									<TextField label="City" name='city' type='text' className='col mr-5' autoComplete='off' size="small"  value={el.city } onChange={e => this.handleClickChange(e, i)} helperText = {shipping_address1[i].status == "new" ? delivery_error.city : ''} required  />
								</div>
								<div className='row mt-4 '>
									<TextField label="State" name='state' type='text' className='col mr-5' autoComplete='off' size="small"  value={el.state} onChange={e => this.handleClickChange(e, i)} helperText = {shipping_address1[i].status == "new" ? delivery_error.state : ''} required  />
									<TextField label="Country" name='country' type='text' className='col mr-5' autoComplete='off' size="small" value={el.country } onChange={e => this.handleClickChange(e, i)} helperText = {shipping_address1[i].status == "new" ? delivery_error.country : ''} required  />
								</div>
								<div className='row mt-4 mb-4'>
									<TextField label="Pin Code" name='pinCode' type='text' className='col mr-5' autoComplete='off' size="small" value={el.pinCode} onChange={e => this.handleClickChange(e, i)} helperText = {shipping_address1[i].status == "new" ? delivery_error.pinCode : ''} required  />
								</div>
							</div>
							<div className="mt-4 mr-4 d-flex justify-content-end">
								{el.status == "new" ? 
								<button id= {`shippingSubmit_${i}`} className="enquiry_sub_btn mr-4" onClick={() => this.submitShipping(i)} > Submit </button>
								: <button id= {`shippingEdit_${i}`}  className="enquiry_sub_btn mr-4"  onClick = {()=>this.updateShipping(i)}  > Save Changes </button>
								}
								
								<Button className="btn btn-outline-info sb_btn1"   onClick = {()=>this.setId(i, el.id)}  data-toggle="modal" data-target="#delete"> Delete </Button>
							</div>
						</div>    
					</fieldset>
					</AccordionDetails>  
				</Accordion>
			</div>
		)
	}

	

	setId = (i, id) => {
		this.setState({set_id: {id: id, index: i}})
	}


	handleClickChange(e, i) {

		let data = {
			index: i,
			name: e.target.name,
			value: e.target.value
		}
		
		
		this.props.dispatch(setAddressHandleChange(data))
		this.setState({ 
			//shipping_address1: value ,
			delivery_error: {addressLine1:'',addressLine2:'', city:'', state:'',  country:'', pinCode:'',name:'', contact:'', gstIn:'', status:"new"}
		
		});
		
	}

	handleClickSubmit(event) {
		const {shipping_address1} = this.state
		console.log(shipping_address1);
		event.preventDefault();
	}


	addClick(){
		let data = {addressLine1:'',addressLine2:'', city:'', state:'',  country:'', pinCode:'',name:'', contact:'',gstIn:'', isActive: 0, status:"new"}
		this.props.dispatch(addShippingAddress(data))
		//this.setState(prevState => ({ shipping_address1: [...prevState.shipping_address1, {address_line_1:'',address_line_2:'', city:'', state:'',  country:'', pincode:'', status:"new"}]}))
	}

	removeClick =() => {

		const {set_id} = this.state
		const {shipping_address1} = this.props

		this.props.dispatch(deleteShipping(set_id))
		
		
	}


	validateDeliveryForm(i) {

		debugger

		let { shipping_address1 } = this.props
		let {delivery_error } = this.state
		
		let formIsValid = true;

		if (!shipping_address1[i].addressLine1) {
			formIsValid = false;
			delivery_error['addressLine1']= "*Please enter Address Line 1";
			this.setState({delivery_error})
			
		}

		if (!shipping_address1[i].city) {
			formIsValid = false;
			delivery_error.city= "*Please enter City";
			this.setState({delivery_error})
			
		}

		if (!shipping_address1[i].state) {
			formIsValid = false;
			delivery_error.state= "*Please enter State";
			this.setState({delivery_error})
			
		}

		if (!shipping_address1[i].country) {
			formIsValid = false;
			delivery_error.country= "*Please enter Country";
			this.setState({delivery_error})
			
		}

		if (!shipping_address1[i].pinCode) {
			formIsValid = false;
			delivery_error.pinCode= "*Please enter Pincode";
			this.setState({delivery_error})
			
		}
		if (!shipping_address1[i].name) {
			formIsValid = false;
			delivery_error.name= "*Please enter Name";
			this.setState({delivery_error})
			
		}
		if (!shipping_address1[i].contact) {
			formIsValid = false;
			delivery_error.contact= "*Please enter Contact";
			this.setState({delivery_error})
			
		}
		
		if (typeof shipping_address1[i].city !== "undefined") {
			if (!shipping_address1[i].city.match(/^[a-zA-Z ]*$/)) {
			  formIsValid = false;
			  delivery_error.city = "*Please enter alphabet characters only.";
			  this.setState({delivery_error})
			  
			}
		  }

		  if (typeof shipping_address1[i].state !== "undefined") {
			if (!shipping_address1[i].state.match(/^[a-zA-Z ]*$/)) {
			  formIsValid = false;
			  delivery_error.state = "*Please enter alphabet characters only.";
			  this.setState({delivery_error})
			  
			}
		  }
		  if (typeof shipping_address1[i].country !== "undefined") {
			if (!shipping_address1[i].country.match(/^[a-zA-Z ]*$/)) {
			  formIsValid = false;
			  delivery_error.country = "*Please enter alphabet characters only.";
			  this.setState({delivery_error})
			  
			}
		  }

		  if (typeof shipping_address1[i].pinCode !== "undefined") {
			if (!shipping_address1[i].pinCode.match(/^[0-9]/)) {
			  formIsValid = false;
			  delivery_error.pinCode = "*Please enter valid pincode.";
			  this.setState({delivery_error})
			  
			}
		  }
		  if ((shipping_address1[i].contact).length != 0) {
			if (!shipping_address1[i].contact.match(/^[0-9]/)) {
			  formIsValid = false;
			  delivery_error.contact = "*Please enter valid phone.";
			  this.setState({delivery_error})
			  
			}
		  }
		  if ((shipping_address1[i].gstIn).length != 0) {
			if (!shipping_address1[i].gstIn.match(/^[0-9]/)) {
			  formIsValid = false;
			  delivery_error.gstIn = "*Please enter valid phone.";
			  this.setState({delivery_error})
			  
			}
		  }
		  
		  return formIsValid
	}

	submitShipping = ( i) => {

		if (this.validateDeliveryForm(i)) {

			var data = {
				customerId:this.currentUser.uid,
				addressType:"delivery",
				addressLine1:this.props.shipping_address1[i].addressLine1,
				addressLine2:this.props.shipping_address1[i].addressLine2,
				city:this.props.shipping_address1[i].city,
				state:this.props.shipping_address1[i].state,
				country:this.props.shipping_address1[i].country,
				pinCode:this.props.shipping_address1[i].pinCode,
				name:this.props.shipping_address1[i].name,
				contact: this.props.shipping_address1[i].contact,
				gstIn: this.props.shipping_address1[i].gstIn,
			}

			console.log(data)
			this.props.dispatch(createShippingAddress(data))
			
		} else{
			console.log('Invalid Form')
		}
	}


	updateShipping = ( i) => {

		if (this.validateDeliveryForm(i)) {

			var data = {
				customerId:this.currentUser.uid,
				addressType:"delivery",
				addressLine1:this.props.shipping_address1[i].addressLine1,
				addressLine2:this.props.shipping_address1[i].addressLine2,
				city:this.props.shipping_address1[i].city,
				state:this.props.shipping_address1[i].state,
				country:this.props.shipping_address1[i].country,
				pinCode: this.props.shipping_address1[i].pinCode,
				id: this.props.shipping_address1[i].id,
				name:this.props.shipping_address1[i].name,
				contact: this.props.shipping_address1[i].contact,
				gstIn: this.props.shipping_address1[i].gstIn,
			}

			console.log(data)
			this.props.dispatch(updateAddress(data, this.props.shipping_address1[i].id))
			
		} else{
			toast.error('Invalid Form')
		}
	}


	billingUI(){
		const {billing_error } = this.state
		const {billing_address1, billingSelectedOption} = this.props

		return billing_address1.map((el, i) => 

		<div className="mt-3 delivery_address_items d-flex flex-row justify-content-start align-items-center">
			

			<Accordion>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography className={useStyles.heading}> {`Billing Address ${i + 1}`} </Typography>
				</AccordionSummary>
					<AccordionDetails>
						<fieldset className="pl-4 pr-4 bg-white" key={i}>
							<div className='d-flex flex-column bg-white' >
								<div className='address_input bg-white'>
									<div className='row mt-2 '>
										<TextField label="Address Line 1" name='addressLine1' type='text' className='col mr-5' autoComplete='off' value={el.addressLine1 } onChange={e => this.billinghandleChange(e, i)} helperText = {billing_address1[i].status == "new" ? billing_error.addressLine1 : ''} size="small" required />
										<TextField label="Address Line 2" name='addressLine2' type='text' className='col mr-5' autoComplete='off' value={el.addressLine2} onChange={e => this.billinghandleChange(e, i)} size="small"  />
									</div>
									<div className='row mt-4 '>
										<TextField label="Name/Company Name" name='name' type='text' className='col mr-5' autoComplete='off' value={el.name  } onChange={e => this.billinghandleChange(e, i)} helperText = {billing_address1[i].status == "new" ? billing_error.name : ''} size="small" required  />
										<TextField label="Phone" name='contact' type='text' className='col mr-5' autoComplete='off' value={el.contact} onChange={e => this.billinghandleChange(e, i)} size="small" helperText = {billing_address1[i].status == "new" ? billing_error.contact : ''} size="small" required />
										
									</div>
									<div className='row mt-4 '>
										
										<TextField label="GSTIN" name='gstIn' type='text' className='col mr-5' autoComplete='off' value={el.gstIn} onChange={e => this.billinghandleChange(e, i)} size="small" helperText = {billing_address1[i].status == "new" ? billing_error.gstIn : ''} size="small"   />
										<TextField label="City" name='city' type='text' className='col mr-5' autoComplete='off' size="small"  value={el.city } onChange={e => this.billinghandleChange(e, i)} helperText = {billing_address1[i].status == "new" ? billing_error.city : ''} required />
									</div>
									<div className='row mt-4'>
										
										<TextField label="State" name='state' type='text' className='col mr-5' autoComplete='off' size="small"  value={el.state} onChange={e => this.billinghandleChange(e, i)} required helperText = {billing_address1[i].status == "new" ? billing_error.state : ''} />
										<TextField label="Country" name='country' type='text' className='col mr-5' autoComplete='off' size="small" value={el.country } onChange={e => this.billinghandleChange(e, i)} required  helperText = {billing_address1[i].status == "new" ? billing_error.country : ''}/>
										
									</div>
									<div className='row mt-4 mb-4'>
										
										
										<TextField label="Pin Code" name='pinCode' type='text' className='col mr-5' autoComplete='off' size="small" value={el.pinCode} onChange={e => this.billinghandleChange(e, i)} required helperText = {billing_address1[i].status == "new" ? billing_error.pinCode : ''} />
									</div>
								</div>
								<div className="mt-4 mr-4 d-flex justify-content-end">
								{el.status == "new" ? 
									<button className="enquiry_sub_btn mr-4" onClick={() => this.submitBilling( i)}  > Submit </button>
									: <button className="enquiry_sub_btn mr-4" onClick = {()=>this.updateBilling(i)}  > Edit </button>
								}
									<Button className="btn btn-outline-info sb_btn1 " onClick={()=>this.setBillingId(i, el.id)}  data-toggle="modal" data-target="#delete_billing"> Remove</Button>
								</div>
							</div>    
						</fieldset>  
					</AccordionDetails> 
					
				</Accordion>
			</div>
		)
	}


	setBillingId = (i, id) => {
		this.setState({setBilling_id: {id: id, index: i}})
	}


	billinghandleChange(e, i) {

		let data = {
			index: i,
			name: e.target.name,
			value: e.target.value
		}

		this.props.dispatch(setBillingAddressHandleChange(data))
		this.setState({ 
			//shipping_address1: value ,
			billing_error: {addressLine1:'',addressLine2:'', city:'', state:'',  country:'', pinCode:'', name:'', contact:'', gstIn:'', status:"new"}
		
		});
	
		
	}


	addBilling(){
		let data = {addressLine1:'',addressLine2:'', city:'', state:'',  country:'', pinCode:'',name:'',contact:'',gstIn:'', isActive: 0, status:"new"}
		this.props.dispatch(addBillingAddress(data))
		//this.setState(prevState => ({ billing_address1: [...prevState.billing_address1, {address_line_1:'',address_line_2:'', city:'', state:'',  country:'', pincode:'', status:"new"}]}))
	}

	removeBilling(i){

		const {setBilling_id } = this.state
		
		const {billing_address1} = this.props

		this.props.dispatch(deleteBilling(setBilling_id))

		
	}



	validateBillingForm(i) {


		let {billing_error } = this.state
		let { billing_address1 } = this.props
		
		let formIsValid = true;

		if (!billing_address1[i].addressLine1) {
			formIsValid = false;
			billing_error.addressLine1= "*Please enter Address Line 1";
			this.setState({billing_error})
			
		}

		if (!billing_address1[i].city) {
			formIsValid = false;
			billing_error.city= "*Please enter City";
			this.setState({billing_error})
			
		}

		if (!billing_address1[i].state) {
			formIsValid = false;
			billing_error.state= "*Please enter State";
			this.setState({billing_error})
			
		}

		if (!billing_address1[i].country) {
			formIsValid = false;
			billing_error.country= "*Please enter Country";
			this.setState({billing_error})
			
		}

		if (!billing_address1[i].pinCode) {
			formIsValid = false;
			billing_error.pinCode= "*Please enter Pincode";
			this.setState({billing_error})
			
		}

		if (!billing_address1[i].name) {
			formIsValid = false;
			billing_error.name= "*Please enter Name";
			this.setState({billing_error})
			
		}
		if (!billing_address1[i].contact) {
			formIsValid = false;
			billing_error.contact= "*Please enter Phone";
			this.setState({billing_error})
			
		}
		
		if (typeof billing_address1[i].city !== "undefined") {
			if (!billing_address1[i].city.match(/^[a-zA-Z ]*$/)) {
			  formIsValid = false;
			  billing_error.city = "*Please enter alphabet characters only.";
			  this.setState({billing_error})
			  
			}
		  }

		  if (typeof billing_address1[i].state !== "undefined") {
			if (!billing_address1[i].state.match(/^[a-zA-Z ]*$/)) {
			  formIsValid = false;
			  billing_error.state = "*Please enter alphabet characters only.";
			  this.setState({billing_error})
			  
			}
		  }
		  if (typeof billing_address1[i].country !== "undefined") {
			if (!billing_address1[i].country.match(/^[a-zA-Z ]*$/)) {
			  formIsValid = false;
			  billing_error.country = "*Please enter alphabet characters only.";
			  this.setState({billing_error})
			  
			}
		  }

		  if (typeof billing_address1[i].pinCode !== "undefined") {
			if (!billing_address1[i].pinCode.match(/^[0-9]/)) {
			  formIsValid = false;
			  billing_error.pinCode = "*Please enter valid pincode.";
			  this.setState({billing_error})
			  
			}
		  }

		  if (typeof billing_address1[i].contact !== "undefined") {
			if (!billing_address1[i].contact.match(/^[0-9]/)) {
			  formIsValid = false;
			  billing_error.contact = "*Please enter valid Phone.";
			  this.setState({billing_error})
			  
			}
		  }

		 
		  return formIsValid
	}


	submitBilling(i){

		if (this.validateBillingForm(i)) {
		
			var data = {
				customerId:this.currentUser.uid,
				addressType:"billing",
				addressLine1:this.props.billing_address1[i].addressLine1,
				addressLine2:this.props.billing_address1[i].addressLine2,
				city:this.props.billing_address1[i].city,
				state:this.props.billing_address1[i].state,
				country:this.props.billing_address1[i].country,
				pinCode:this.props.billing_address1[i].pinCode,
				name:this.props.billing_address1[i].name,
				contact: this.props.billing_address1[i].contact,
				gstIn: this.props.billing_address1[i].gstIn,
			}

			this.props.dispatch(createShippingAddress(data))

			
			

			}else{
				console.log('Invalid Form')
		}

	}

	updateBilling = ( i) => {

		debugger

		if (this.validateBillingForm(i)) {

			var data = {
				customerId:this.currentUser.uid,
				addressType:"billing",
				addressLine1:this.props.billing_address1[i].addressLine1,
				addressLine2:this.props.billing_address1[i].addressLine2,
				city:this.props.billing_address1[i].city,
				state:this.props.billing_address1[i].state,
				country:this.props.billing_address1[i].country,
				pinCode: this.props.billing_address1[i].pinCode,
				id: this.props.billing_address1[i].id,
				name:this.props.billing_address1[i].name,
				contact: this.props.billing_address1[i].contact,
				gstIn: this.props.billing_address1[i].gstIn,
			}

			console.log(data)
			this.props.dispatch(updateAddress(data, this.props.billing_address1[i].id))
			
		} else{
			console.log('Invalid Form')
		}
	}

	



	render() {
		const {whatsapp,emailmsg ,sms} = this.props
		return (
			<React.Fragment>
			<div className='enquiry_body' >
				<LoadingOverlay active={this.props.loading} spinner styles={{overlay: (base) => ({ ...base, position: 'fixed', })}}>
				<ToastContainer />
				 
				<div className="row quotation_panel d-flex flex-column">  
					<h4 class="page--title h5 text-white ml-4 pl-4">Settings</h4>
					<ul class="d-flex flex-row">
						<li className="text-white"><Link style={{color:'#CACACA'}} className="ml-4 pl-4" to="/dashboard">Dashboard &nbsp;/ &nbsp;</Link></li>
						<li className="text-white"><span >Profile Settings</span></li>
					</ul>
				</div> 
				<div className="row pl-2 pr-2 ml-4 mr-4 profile_table">
					<div class=" col-lg-3 col-md-6 col-sm-6 mt-4">
						<div class="card card_shadow">
							<div class=" card-header p-0 d-flex mb-2 flex-column">
								<div className='pl-4 pt-3 pb-2 text-left text-blue jss102 '>Profile Settings</div>
								<div className={this.state.accnt ? 'text-left text-blue jss102 profile_account profile_active': 'text-left text-blue jss102 profile_account'}
									onClick = {()=> this.setState({accnt: true, pwd: false, addr: false})}
								>
										Account
								</div>
								<div className={this.state.pwd ? 'text-left text-blue jss102 profile_account profile_active': 'text-left text-blue jss102 profile_account'}
									onClick = {()=> this.setState({accnt: false, pwd: true, addr: false})}
								>
										Change Password
								</div>
								<div className={this.state.addr ? 'text-left text-blue jss102 profile_account profile_active': 'text-left text-blue jss102 profile_account'}
									onClick = {()=> this.setState({accnt: false, pwd: false, addr: true})}
								>
										Add Address
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-9 col-md-6 col-sm-6 mt-4">
						<div className='card card_shadow'>
							<div class="card-header pl-4 pr-4 ">
							
								<div className='table_top '>
									<h4 className=' d-flex flex-row jss103 ' hidden={this.state.accnt === false}>Info</h4>
									<h4 className=' d-flex flex-row jss103' hidden={this.state.pwd === false}>Reset Password</h4>
								</div>
								<div hidden={this.state.accnt === false}>
									<Form ref={this.profileFormRef} colon={false}>
										<div className="d-flex justify-content-between">
											<div className="d-flex flex-column w-100">
												<Row >
													<Col span={11} className = 'profile_form'>
													{this.state.editable == true ?
														<Form.Item name="fullname" label="Full Name"
														>
															<Input className="profile_input" autocomplete="off"  name="fullname" onChange={this.handleChange}  />
														</Form.Item>
														:
														<Form.Item name="fullname" label="Full Name"
														>
															<Input autocomplete="off" name="fullname" onChange={this.handleChange} disabled />
														</Form.Item>
													}
													</Col>
													
													<Col span={11} className = 'profile_form'>
													{this.state.emailEdit == true ?
														<Form.Item name="email" label="Email"
														>
															<Input autocomplete="off" name="email" onChange={this.handleChange}  />
														</Form.Item>
														:
														<Form.Item name="email" label="Email"
														>
															<Input autocomplete="off" name="email" onChange={this.handleChange}  disabled/>
														</Form.Item>
													}

													</Col> 
													

											
												</Row>
												<Row >
													<Col span={11} className = 'profile_form'>
														<Form.Item name="phone" label="Phone Number"
														>
															<Input autocomplete="off" name="phone" onChange={this.handleChange}  />
														</Form.Item>
													</Col>
													
													<Col span={11} className = 'profile_form'>
														<Form.Item name="company" label="Company name"
														>
															<Input autocomplete="off" name="company" onChange={this.handleChange}  />
														</Form.Item>
													</Col> 
												</Row>
											
											</div>
										
										
											<div className="d-flex flex-column mr-4 align-items-center">
												<img src={ProfileIcon} width="120px" height="120px" style={{marginTop:"-30px"}}></img>
												<h4 className="jss103 pt-2">{this.props.fullname}</h4>
											</div>	
										</div>	
									</Form>
								</div>

								<div className='row mt-2' hidden={this.state.pwd === false}>
									<Form ref={this.pwdFormRef} colon={false}>
										<Row >
											<Col span={24} className = 'profile_form'>
												<Form.Item name="newpwd" label="Email"
													rules={[{ required: true, message: "Please enter email!" }]}
												>
													<Input style={{width:"350px"}} autocomplete="off" type="email" name="newpwd" onChange={(e) => this.setState({newpwd: e.target.value})}  />
												</Form.Item>
											</Col>	
										</Row>
									</Form>
								</div>


								<div className='row' hidden={this.state.addr === false}>
									<div className='col pl-0 '>
										<div className='row d-flex justify-content-between mr-2 ' >
											<FormLabel>Shipping Address</FormLabel>
											<button class="float-right ab_btn " onClick={this.addClick.bind(this)}>+ Add</button>
										</div>

										{this.createUI()} 
									</div>
								</div>
								<div className='row mt-5 pt-2' hidden={this.state.addr === false}>
									<div className='col  pl-0 mr-2 '>
										<div className='row d-flex justify-content-between'>
											<FormLabel>Billing Address</FormLabel>
											<button class="float-right ab_btn " onClick = {this.addBilling.bind(this)}>+ Add</button>
											
										</div>
										{this.billingUI()} 
									</div>
								</div>
								<div className='row mt-4' hidden={this.state.accnt === false}>

									<div className="profile_msg_preference">
										<FormLabel className="ml-0 pl-0 jss103">Messaging Preferences</FormLabel>
										<FormGroup className="d-flex flex-row w-100 mt-2">
											<FormControlLabel name='sms' value="sms" control={<Checkbox color="primary" checked={JSON.parse(sms)}  onChange={this.handleMessage}/>} label="SMS" disabled/>
											<FormControlLabel name='whatsapp' value="whatsapp" control={<Checkbox color="primary" checked={JSON.parse(whatsapp)} onChange={this.handleMessage} disabled = {(this.props.phone == "" || this.props.phone == null || this.props.phone == undefined) ? true: false} />} label="WhatsApp" />
											<FormControlLabel name='emailmsg' value="emailmsg" control={<Checkbox color="primary" checked={JSON.parse(emailmsg)}  onChange={this.handleMessage} disabled = {(this.props.email == "" || this.props.email == null || this.props.email == undefined) ? true: false}/>} label="Email"/>
										</FormGroup>
										{
											this.props.phone == '' || this.props.phone == null ?  
											<p className="helper_text">Note: Please update Phone no. to get whatsapp/sms notifications</p> : ''
										}
										{
											this.props.email == '' || this.props.email == null ?  
											<p className="helper_text">Note: Please update Email. to get email notifications</p> : ''
										}
										
									</div>
								</div>

								<div className='row m-4  float-right'>
									<button className='profile_update_btn' onClick={this.submit} hidden={this.state.accnt === false}>Save Changes</button>
									<button className='profile_pwd_btn' onClick={this.resetPassword} hidden={this.state.pwd === false}>Change password</button>
								</div>

								

							

								<div className="row justify-content-left">
									<div className="col-lg-5">
										<div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
											<div class="modal-dialog modal-dialog-centered" role="document">
												<div className="modal-content card shadow-lg border border-grey rounded-lg">
													<div className="card-body modal-body">
														<p className="text-left">Confirm to Delete Address ?</p>
														<div className = "confirm-btns-container">
															<button type="button" className = "confirm-btns mr-3" data-dismiss="modal">No</button>
															<button type="button" className = "confirm-btns" data-dismiss="modal" onClick={() => this.removeClick()}>Yes</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="row justify-content-left">
									<div className="col-lg-5">
										<div class="modal fade" id="delete_billing" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
											<div class="modal-dialog modal-dialog-centered" role="document">
												<div className="modal-content card shadow-lg border border-grey rounded-lg">
													<div className="card-body modal-body">
														<p className="text-left">Confirm to Delete Address ?</p>
														<div className = "confirm-btns-container">
															<button type="button" className = "confirm-btns mr-3" data-dismiss="modal">No</button>
															<button type="button" className = "confirm-btns" data-dismiss="modal" onClick={() => this.removeBilling()}>Yes</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
				
				
				</LoadingOverlay>
			</div>
			<div className="dashboard_layout_footer mb-4 text-blue" >
				<a className="text-blue ml-4  pl-4"> www.yantralive.com</a>
				<img src={Yantra}  height="60px"></img>
				<a className="text-blue pr-4 mr-4">© 2021 - All Right Reserved</a>
			</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, ownProps) => {

    return {
		uid: state.userSlice.user.payload.uid,
		loading: state.profileSlice.loading,
		shipping_address1: state.profileSlice.shipping_address1,
		billing_address1: state.profileSlice.billing_address1,
		update_id:state.profileSlice.update_id,
		fullname:state.profileSlice.fullname,
		email:state.profileSlice.email,
		phone:state.profileSlice.phone,
		company:state.profileSlice.company,
		gst:state.profileSlice.gst,
		contact_person_phone:state.profileSlice.contact_person_phone,
		contact_person_name:state.profileSlice.contact_person_name,
		deliverySelectedOption:state.profileSlice.deliverySelectedOption,
		billingSelectedOption:state.profileSlice.billingSelectedOption,
		sms: state.profileSlice.sms,
		emailmsg: state.profileSlice.emailmsg,
		whatsapp: state.profileSlice.whatsapp,
    }
};


export default connect(mapStateToProps)(Profile);

