import React, { Component } from 'react';
import './App.css';
import Enquiry from '../src/features/enquiries/Enquiry'
import Login from './features/auth/Login';
import fire from './config/fire';
import Layout from '../src/features/layout/Layout';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user : {}
    }
  }
  componentDidMount(){
    this.authListner();   
  }

  authListner(){
    fire.auth().onAuthStateChanged((user)=>{
      if(user){
        this.setState({user})
        localStorage.setItem('currentUser',JSON.stringify(user))
      } else {
        this.setState({user:null})
        localStorage.removeItem('currentUser')
      }
    })
  }
  render() {
    return (
      <div className="App">
        {/* {this.state.user ? <Enquiry/> : <Login/>} */}
        {!localStorage.getItem("currentUser") ? <Login/> : <Layout/>}
      </div>
    );
  }
}

export default App;

