import React,  { Component }  from 'react'
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import Pdf from '../../images/pdf.png';
import Excel from '../../images/excel.png';
import Word from '../../images/word.png';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import Cart from '../../images/shopping-cart.png';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import './orders.css';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Modal} from 'antd';
import Yantra from '../../images/yantra.png';


class ViewOrders extends Component {
    state={
        order:{},
        packages:[],
        isLoading:true,
        lightBoxOpen: false,
        img: [],
        visible: false,
        items: []
    }

    componentDidMount(){
        if(this.props.location.state.order){
          this.setState({isLoading:false})
        }
        console.log(this.props.location.state.order)
        
        let packages = this.props.location.state.order.enquiryOrderPackageDtoList
        
        this.setState({order:this.props.location.state.order, packages:packages})
    }

    

    render(){
        let fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gmi
        const {order} = this.state
        console.log(this.state)
        return (
            <React.Fragment>
            <div className='enquiry_body'>
			<LoadingOverlay active={this.state.isLoading} spinner styles={{overlay: (base) => ({ ...base, position: 'fixed', })}}>
				
				<div className="row quotation_panel d-flex flex-column">  
					<h4 class="page--title h5 text-white ml-4 pl-4">My Order</h4>
					<ul class="d-flex flex-row">
						<li className="text-white"><Link style={{color:"#CACACA"}} className="ml-4 pl-4" to="/dashboard">Dashboard &nbsp;/ &nbsp;</Link></li>
						<li className="text-white"><Link style={{color:"#CACACA"}} to="/orders">My Order &nbsp;/ &nbsp;</Link></li>
						<li className=" "><span style={{color:'white'}}> {this.state.order.zohoOrderNo}</span></li>
					</ul>
				</div>  
				<div className='pl-4 pr-4 ml-4 mr-4 list_table '>
						
					<div className='card card_shadow'>
						<div class="card-header pl-4 pr-4 ">
							  
                            <div className='  p-2 mt-2'>
                                <div className="row" >
                                    <div class="col-md-9 col-sm-12 ">
                                        <table class="table borderless viewOrdermain_table">
                                            <tbody>
                                                <tr >
                                                    <td>Order no:</td>
                                                    <th>{this.state.order.zohoOrderNo}</th>
                                                </tr>
                                                <tr>
                                                    <td>Order Placed:</td>
                                                    <th>{this.state.order.createdAt}</th>
                                                </tr>	
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-md-3 col-sm-12">
                                        <table  class="table borderless viewOrder_table">
                                            <tbody>
                                                <tr>
                                                    <td>Amount:</td>
                                                    <th>{this.state.order.amount}</th>
                                                </tr>
                                
                                                <tr>
                                                    <td>Status:</td>
                                                    <th>{this.state.order.status}</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>	
                            {( this.state.packages == null || (Array.isArray(this.state.packages) && (this.state.packages.length <= 0))) ? 
                                <div class="col-md-12 col-sm-12 mb-2">
                                    <div className="d-flex flex-column">
                                            
                                            
                                            <div className="w-100 d-flex flex-row justify-content-around mt-4 ml-2 pl-1">
                                                <span 
                                                    className={
                                                         "progress-color" 
                                                    }
                                                >
                                                    <CheckCircleOutlineIcon/>
                                                </span>
                                                <span 
                                                className={
                                                    (order.status == "confirmed")  ? "progress-color " : ''
                                                }
                                                >
                                                    <CheckCircleOutlineIcon/>
                                                </span>
                                                <span
                                                   
                                                >
                                                    <CheckCircleOutlineIcon/>
                                                </span>
                                                <span
                                                    
                                                >
                                                    <CheckCircleOutlineIcon/>
                                                </span>
                                                <span
                                                    
                                                >
                                                    <CheckCircleOutlineIcon/>
                                                </span>
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar d-flex flex-row justify-content-between" role="progressbar" 
                                                aria-valuenow={( this.state.order.status == "Draft" )? "20" : ( this.state.order.status == "confirmed" )? "40" : "0"} 
                                                style={{width: (this.state.order.status == "Draft" )? "20%" : ( this.state.order.status == "confirmed" )? "40%" : "0%"}}
                                                aria-valuemin="0" aria-valuemax="100"></div>
                                                
                                            </div>

                                            <div className="d-flex flex-row justify-content-around mt-1">
                                                <span className= "progress-color" >Order Placed</span>
                                                <span className={(order.status) == "confirmed"  ? "progress-color" :  ''}>Confirmed</span>
                                                <span >Processing</span>
                                                <span >Shipped</span>
                                                <span >Delivered</span>
                                            </div>
                                        
                                        </div>
                                </div>
                                
                                : ''
                            }
                            {(this.state.packages|| []).map((order_package, index)=>{
                                return(
                                    <fieldset className='col-lg-12 mb-4 col-sm-12 pb-2 selected_files'  > 
                                    <legend style={{color: "white"}} className="ml-4">Package {index+1}</legend>
                                        
                                        <div className="row pt-2 pb-2">
                                            <div class="col-md-4 col-sm-12">
                                                <table class="table borderless viewOrder_table">
                                                    <tbody>
                                                        <tr>
                                                            <td>Package Status:</td>
                                                            <th>{order_package.status}</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Packed Date:</td>
                                                            <th>{order_package.packedDate}</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Shipment Date:</td>
                                                            <th>{order_package.shipmentDate}</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Warehouse:</td>
                                                            <th>{order_package.wareHouse}</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="col-md-4 col-sm-12">
                                                <table  class="table borderless viewOrder_table">
                                                    <tbody>
                                                        <tr>
                                                            <td>Courier:</td>
                                                            <th>{order_package.carrier}</th>
                                                        </tr>
                                                        
                                                        <tr>
                                                            <td>Tracking no:</td>
                                                            <th>{order_package.trackingNumber}</th>
                                                        </tr>  
                                                        <tr>
                                                            <td >Deliver Date:</td>
                                                            <th>{order_package.deliveryDate}</th>
                                                        </tr>
                                                        <tr>
                                                            <td className="float-left">
                                                                <button class="mt-2 ml-0 btn btn-sm btn-outline-info sb_btn" onClick={()=> this.setState({visible: true, items:order_package.items.items})}>View Items</button>
                                                            </td>
                                                            
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="col-md-4 col-sm-12">
                                                <table  class="table borderless viewOrder_table">
                                                    <tbody>
                                                        <tr>
                                                            <td>Product Image:</td>
                                                            <th style={{cursor:"pointer"}}>
                                                                {
                                                                    order_package.zohoProductImage && (
                                                                        order_package.zohoProductImage.match(fileExtensionPattern)[0] == ".pdf" ?
                                                                        <a href={order_package.zohoProductImage} target="_blank"><img src={Pdf} alt="pdf" height="50px"/></a>
                                                                        :
                                                                        order_package.zohoProductImage.match(fileExtensionPattern)[0] == ".xlsx" ?
                                                                        <a href={order_package.zohoProductImage} ><img src={Excel}  alt="xlxs" height="50px"/></a>
                                                                        :
                                                                        order_package.zohoProductImage.match(fileExtensionPattern)[0] == ".docx" ?
                                                                        <a href={order_package.zohoProductImage} ><img src={Word} alt="docx" height="50px"/></a>
                                                                        :
                                                                        <img src={order_package.zohoProductImage} onClick={() => this.setState({ lightBoxOpen: true, img: order_package.zohoProductImage })} height="50px"></img>
                                                                    )
                                                                }
                                                                                        
                                                                                        
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td width="200px">LR Image:</td>
                                                            <th style={{cursor:"pointer"}}>
                                                            {
                                                                order_package.zohoLrImage && (
                                                                order_package.zohoLrImage.match(fileExtensionPattern)[0] == ".pdf" ?
                                                                    <a href={order_package.zohoLrImage} target="_blank"><img src={Pdf} alt="pdf" height="50px"/></a>
                                                                    :
                                                                    order_package.zohoLrImage.match(fileExtensionPattern)[0] == ".xlsx" ?
                                                                    <a href={order_package.zohoLrImage} ><img src={Excel}  alt="xlxs" height="50px"/></a>
                                                                    :
                                                                    order_package.zohoLrImage.match(fileExtensionPattern)[0] == ".docx" ?
                                                                    <a href={order_package.zohoLrImage} ><img src={Word} alt="docx" height="50px"/></a>
                                                                    :
                                                                    <img src={order_package.zohoLrImage} onClick={() => this.setState({ lightBoxOpen: true, img: order_package.zohoLrImage })} height="50px"></img>
                                                                )
                                                            }
                                                                
                                                            </th>
                                                        </tr>
                                                        
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="col-md-12 col-sm-12">
                                                <div className="d-flex flex-column">
														
														
														<div className="w-100 d-flex flex-row justify-content-around mt-4 ml-2 pl-1">
															<span 
																className={
																	((order.status).toLowerCase() == "draft" && order_package.status == "NA") || (order.status == "confirmed" && order_package.status == "packed") || order_package.status == "packed" || order_package.status == "shipped" || order_package.status == "delivered"  ? "progress-color" : ''
																}
															>
																<CheckCircleOutlineIcon/>
															</span>
															<span 
																className={
																	(order.status == "confirmed" && order.status == "packed") || order_package.status == "packed" || order_package.status == "shipped" || order_package.status == "delivered"  ? "progress-color " : ''
																}
															>
																<CheckCircleOutlineIcon/>
															</span>
															<span
																className={
																	order_package.status == "delivered" || order_package.status == "shipped" || order_package.status == "packed"  ? "progress-color" : ''
																}
															>
																<CheckCircleOutlineIcon/>
															</span>
															<span
																className={
																	order_package.status == "shipped" || order_package.status == "delivered"  ? "progress-color" : ''
																}
															>
																<CheckCircleOutlineIcon/>
															</span>
															<span
																className={
																	order_package.status == "delivered"  ? "progress-color" : ''
																}
															>
																<CheckCircleOutlineIcon/>
															</span>
														</div>
														<div className="progress">
															<div className="progress-bar d-flex flex-row justify-content-between" role="progressbar" 
															aria-valuenow={( (order.status).toLowerCase() == "draft" && order_package.status == "NA")? "20" : ( order.status == "confirmed" && order_package.status == "NA")? "40" :  order_package.status == "packed"? "62" : order_package.status == "shipped"? "80" : order_package.status == "delivered"? "100" :"0"} 
															style={{width: ((order.status).toLowerCase() == "draft" && order_package.status == "NA")? "20%" : ( order.status == "confirmed" && order_package.status == "NA")? "40%" : order_package.status == "packed"? "62%": order_package.status == "shipped"? "80%" : order_package.status == "delivered"? "100%":"0%"}}
															aria-valuemin="0" aria-valuemax="100"></div>
															
														</div>

														<div className="d-flex flex-row justify-content-around mt-1">
															<span className={((order.status).toLowerCase() == "draft" && order_package.status == "NA") || (order.status == "confirmed" && order_package.status == "NA") || order_package.status == "packed" || order_package.status == "shipped" || order_package.status == "delivered"  ? "progress-color" : ''}>Order Placed</span>
															<span className={((order.status).toLowerCase() == "confirmed" && order_package.status == "NA") || order_package.status == "packed" || order_package.status == "shipped" || order_package.status == "delivered"  ? "progress-color" :  ''}>Confirmed</span>
															<span className={order_package.status == "delivered" || order_package.status == "shipped" || order_package.status == "packed"  ? "progress-color" : ''}>Processing</span>
															<span className={ order_package.status == "shipped" || order_package.status == "delivered"  ? "progress-color" :  order_package.status == null ? '' : ''}>Shipped</span>
															<span className={  order_package.status == "delivered"  ? "progress-color" :  order_package.status == null ? '' : ''}>Delivered</span>
														</div>
													
													</div>
                                            </div>
                                        </div>
                            </fieldset>
                        )}) }	
                
						
					
					{this.state.lightBoxOpen && (
						<Lightbox
						  mainSrc={this.state.img}
						 
						  onCloseRequest={() => this.setState({ lightBoxOpen: false })}
						  
					    />
                    )}

                    <Modal
                      visible={this.state.visible}
                      title={
                            <div className="d-flex justify-content-between">
                                <p style={{color:"#0D4A61", fontWeight:"bold", fontSize:"18px"}}>Item Details</p>
                                <p style={{color:"#0D4A61", fontWeight:"bold", fontSize:"18px"}}>{`Total Items: ${this.state.items.length}`}</p>   
                            </div>
                        }
                        onCancel={null}
                        focusTriggerAfterClose = {false}
                        closable ={false}
                      
                      footer={[
                          <button style={{color:"white", backgroundColor:"#FDC32C", border:"none", outline:"none", cursor:"Pointer"}} className='mr-1 mb-2 pl-4 pr-4 pt-2 pb-2' onClick={()=> this.setState({visible: false})}>Close</button>,
                          
                      ]}
                  >
                  {(this.state.items|| []).map((item, index)=>{
                    return(
                        <fieldset className='col-lg-12  mb-2 col-sm-12 pb-2 selected_files'  > 
								<legend style={{color: "white"}} className="ml-4">Item {index+1}</legend>
                        <div className="row ">
                            <table class="table borderless viewOrder_table">
                                <tbody>
                                    <tr>
                                        <td>Item Name:</td>
                                        <th>{item.name}</th>
                                    </tr>
                                    <tr>
                                        <td>Quantity:</td>
                                        <th>{item.quantity}</th>
                                    </tr>
                                    <tr>
                                        <td>SKU:</td>
                                        <th>{item.sku}</th>
                                    </tr>
                                   
                                </tbody>
                            </table>
                           
                        </div>
                        </fieldset>

                    )})}
                      
                  </Modal>
					  </div></div></div>
			</LoadingOverlay>
        </div>
        <div className="dashboard_layout_footer mb-4 text-blue" >
            <a href="https://www.yantralive.com/" target="_blank" className="text-blue ml-4  pl-4"> www.yantralive.com</a>
            <img src={Yantra}  height="60px"></img>
            <a className="text-blue pr-4 mr-4">© 2021 - All Right Reserved</a>
        </div>
        </React.Fragment>
        )
    }
    
}

export default ViewOrders;
