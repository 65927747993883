import firebase from 'firebase';

var firebaseConfig = {
	apiKey: "AIzaSyBlDV2DlXaK2lNbpj01LcBzMy7MNQFen7A",
	authDomain: "yantralive12.firebaseapp.com",
	databaseURL: "https://yantralive12.firebaseio.com/",
	projectId: "yantralive12",
	storageBucket: "yantralive12.appspot.com",
	messagingSenderId: "1099470798455",
	appId: "1:1099470798455:web:23068275ca2440d831d072",
	measurementId: "G-QFVKW5HE17"
};

// const firebaseConfig = {
// 	apiKey: "AIzaSyB1U7dEhZO6lKal5N_Be0-d162WGfCY0FU",
// 	authDomain: "yantralive-cj-test.firebaseapp.com",
// 	projectId: "yantralive-cj-test",
// 	storageBucket: "yantralive-cj-test.appspot.com",
// 	messagingSenderId: "739956033597",
// 	appId: "1:739956033597:web:6e518ab7ee82d7457b0254",
// 	measurementId: "G-YD6H7NKK3R"
//   };

const fire = firebase.initializeApp(firebaseConfig);

export default fire;