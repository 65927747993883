import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import { Link } from "react-router-dom";
import Pdf from '../../images/pdf.png';
import Excel from '../../images/excel.png';
import Word from '../../images/word.png';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import TicketIcon from '../../images/tickets.png';
import './tickets.css'


class ViewTicket extends Component {
  state={
    ticket:{},
	isLoading:true,
	attachments: [],
	image:'',
	lightBoxOpen: false,
	img: []
	
	
  }

  componentWillMount(){
    if(this.props.location.state.ticket){
      this.setState({isLoading:false})
	}
	console.log(this.props.location.state.ticket)
	
	let attachments = this.props.location.state.ticket.zohoTicketDetails.attachments
	let images 
	if(attachments != null){
		images = attachments.attachments
	}else{
		images = []
	}
    this.setState({ticket:this.props.location.state.ticket, attachments:images})
  }

  render() {
	 
	  const {attachments} = this.state
	
	  let fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gmi
    return (
      	<div className='enquiry_body'>
			<LoadingOverlay active={this.state.isLoading} spinner styles={{overlay: (base) => ({ ...base, position: 'fixed', })}}>
				
				<div className="row quotation_panel d-flex flex-column">  
					<h4 class="page--title h5 text-white ml-4 pl-4">My Ticket</h4>
					<ul class="d-flex flex-row">
						<li className="text-white"><Link style={{color:"#CACACA"}} className="ml-4 pl-4" to="/dashboard">Dashboard &nbsp;/ &nbsp;</Link></li>
						<li className="text-white"><Link style={{color:"#CACACA"}} to="/tickets">My Ticket &nbsp;/ &nbsp;</Link></li>
						<li className=" "><span style={{color:'white'}}> {this.state.ticket.zohoTicketDetails.ticketNumber}</span></li>
					</ul>
				</div>  
				<div className='pl-4 pr-4 ml-4 mr-4 list_table '>
						
					<div className='card card_shadow'>
						<div class="card-header pl-4 pr-4 ">
							  
				<div className='mb-4  p-2 mt-2'>
					<div className="row">
						<div class="col-md-6 col-sm-12">
							<table class="table borderless tickets_table">
								<tbody>
									<tr>
										<td>Ticket Number:</td>
										<th>{this.state.ticket.zohoTicketDetails.ticketNumber}</th>
									</tr>
									<tr>
										<td>Order no:</td>
										<th>{this.state.ticket.zohoOrderNo}</th>
									</tr>
									<tr>
										<td>Enquiry Title:</td>
										<th>{this.state.ticket.enquiryTitle}</th>
									</tr>
                 
                  					<tr>
										<td>Created Time:</td>
										<th>{this.state.ticket.createdAt}</th>
									</tr>
									<tr>
										<td>Contact Id:</td>
										<th>{this.state.ticket.zohoTicketDetails.contactId}</th>
									</tr>
									
									
								</tbody>
							</table>
						</div>
						<div class="col-md-6 col-sm-12">
							<table  class="table borderless tickets_table">
								<tbody>
									<tr>
										<td>Subject:</td>
										<th>{this.state.ticket.zohoTicketDetails.subject}</th>
									</tr>
					
                  					<tr>
										<td>Description:</td>
										<th>{this.state.ticket.zohoTicketDetails.description}</th>
									</tr>
                  
                  <tr>
										<td>Status:</td>
										<th>{this.state.ticket.zohoTicketDetails.status}</th>
									</tr>
                  {/*<tr>
										<td>DueDate:</td>
										<th>{moment(this.state.ticket.zohoTicketDetails.dueDate).format("LLL")}</th>
				  </tr> */}
                  <tr>
										<td>Updated Time:</td>
										<th>{moment(this.state.ticket.updatedAt).format("DD MMM YYYY hh:mm A")}</th>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>	
							
				
					{attachments?
						<fieldset className='col-lg-11 col-sm-12 m-4 pb-4 selected_files'  > 
							<legend style={{color: "white"}} className="ml-4 mb-4">Files</legend>
							{((attachments) || []).map(image=>{
								return image.fileUrl.match(fileExtensionPattern)[0] == ".pdf" ?
									<a href={image.fileUrl} target="_blank">
										<img  src={Pdf} alt="pdf" width='100px' height='100px' className='mr-3 pr-2' />
									</a>
									:
									image.fileUrl.match(fileExtensionPattern)[0] == ".xlsx" ?
									<a href={image.fileUrl} >
										<img src={Excel} alt="pdf" width='100px' height='100px' className='mr-3 pr-2' />
									</a>
									:

									image.fileUrl.match(fileExtensionPattern)[0] == ".docx" ?
									<a href={image.fileUrl} >
										<img src={Word} alt="doc" width='100px' height='100px' className='mr-3 pr-2' />
									</a>
									
									:
									
									<img className='mr-3 pr-2' src={image.fileUrl} width='100px' height='100px' onClick={() => this.setState({ lightBoxOpen: true, img: image.fileUrl})}/>
									
									
							}) }	
							
						</fieldset>: ""
						
					} 
					
					{this.state.lightBoxOpen && (
						<Lightbox
						  mainSrc={this.state.img}
						 
						  onCloseRequest={() => this.setState({ lightBoxOpen: false })}
						  
						/>
					  )}
					  </div></div></div>
			</LoadingOverlay>
      </div>
    );
  }
}

export default ViewTicket;
