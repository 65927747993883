import React, { Component } from 'react';
import './layout.css'
import Enquiry from '../enquiries/Enquiry';
import fire from '../../config/fire';
import { BrowserRouter as Router, Redirect, Link, Switch, Route } from "react-router-dom";
import Dashboard from '../dashboard/Dashboard';
import Quotation from '../quotations/Quotation';
import Orders from '../orders/Orders';
import Login from '../auth/Login';
import CreateEnquiry from '../enquiries/CreateEnquiry';
import ViewEnquiry from '../enquiries/ViewEnquiry';
import EditEnquiry from '../enquiries/EditEnquiry';
import Fleets from '../fleets/Fleets';
import Profile from '../profile/Profile';
import CreateFleet from '../fleets/CreateFleet';
import api from '../../api/index';
import browserHistory from '../../config/browserHistory'
import Ticket from '../tickets/Ticket';
import ViewTicket from '../tickets/ViewTicket';
import {connect} from 'react-redux';
import { fetchCustomer } from './services/layout';
import { fetchAddress, fetchProfile } from '../profile/services/profile';
import storage from 'redux-persist/lib/storage';
import Logo from '../../images/Logo.png';
import Home from '../../images/home.png';
import Fleet from '../../images/fleets.png';
import UserList from '../../images/user-list.png';
import LeftQuote from '../../images/left-quotes-sign.png';
import Crane from '../../images/crane.png';
import Cart from '../../images/shopping-cart.png';
import TicketIcon from '../../images/tickets.png';
import ProfileIcon from '../../images/profile-user (2)1.png';
import RightArrow from '../../images/arrow-point-to-right.png';
import SettingsIcon from '../../images/settings (2).png';
import LogoutIcon from '../../images/log-out.png';
import LogoutIcon1 from '../../images/log-out2.png';
import LoadingOverlay from 'react-loading-overlay';
import Yantra from '../../images/yantra.png';
import pwd from '../../images/lock.png';
import ViewOrders from '../orders/ViewOrders';


class Layout extends Component {
	constructor(props){
		super(props)
		this.currentUser=JSON.parse(localStorage.getItem("currentUser"))
		this.state = {
			username:'',
			isLoading: false,
			footer: true
		}
		
	}

	async componentDidMount(){
		

		if(this.currentUser.uid == undefined || this.currentUser.uid == null ){
			browserHistory.push('/login')
		}else{
			this.setState({isLoading: true})
			await this.props.dispatch(fetchProfile())	
			await this.props.dispatch(fetchCustomer(this.currentUser.uid))
			await this.props.dispatch(fetchAddress(this.currentUser.uid))
			this.setState({isLoading: false})
		}
		
	}

	logout =()=> {
		fire.auth().signOut();
		localStorage.removeItem('currentUser');
		window.localStorage.clear();
		storage.removeItem('persist:root')
		browserHistory.push('/login')
	}

	render() {
		console.log()
		const {user} = this.props
		return (
			<div>
				<LoadingOverlay active={this.state.isLoading} spinner styles={{overlay: (base) => ({ ...base, position: 'fixed', })}}></LoadingOverlay>
				<Router>
				<input type="checkbox" id="check" defaultChecked/>				
				<label htmlFor="check" style={{position:"absolute"}}>
					<i className="fas fa-bars" id='btn'></i>
					{/* <i className="fas fa-times" id='cancel'></i> */}
				</label>
				<div className='sidebar'>
					<header>
						<div><img className="pb-2" alt="logo" width='100px' height='120px'  src={Logo}/></div>
					</header>
					<ul>
						<li className='navItems'><Link to='/dashboard'><img src={Home} width="20px" height="20px"></img>&emsp; Dashboard</Link></li>
						<li className='navItems'><Link to='/enquiry'><img src={UserList} width="20px" height="20px"></img>&emsp; My Enquiries</Link></li>
						<li className='navItems'><Link to='/quotation'><img src={LeftQuote} width="20px" height="20px"></img>&emsp; My Quotations</Link></li>
						<li className='navItems'><Link to='/orders'><img src={Cart} width="20px" height="20px"></img>&emsp; My Orders</Link></li>
						<li className='navItems'><Link to='/tickets'><img src={TicketIcon} width="20px" height="20px"></img>&emsp; My Tickets</Link></li>
						<li className='navItems'><Link to='/fleets'><img src={Fleet} width="20px" height="20px"></img>&emsp; My Fleets</Link></li>
						<li className='navItems'><Link to='/profile'><img src={ProfileIcon} width="20px" height="20px"></img>&emsp; Profile &emsp; &emsp; &emsp;<img src={RightArrow} width="15px" height="15px"></img></Link></li>
						{/* <li className='navItems'><Link to='/profile'><i class="fa fa-user-circle-o" aria-hidden="true"></i>My Profile</Link></li> */}
						{/* <li className='navItems nav_logout' onClick={this.logout}><Link ><img src={Home} width="30px" height="30px"></img>&emsp;  Logout</Link></li>  */}
					</ul>
				</div>
				<section>
					<div className="layout_header">
						{/*<img className='logo' src={Crane} width= "83px" height= '86px'/>
						<h3>My Yantra</h3>

						 <div className='input_box d-flex align-items-center'>
							<i className='fa fa-search' style={{color:'#a6a6a6'}}></i> 
							<input class="form-control" type='text' placeholder='search'/>
						</div> */}
						

						<img className="input_box " width="62px" height="30px" src={SettingsIcon} id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></img>
						<div class="dropdown-menu">
							<Link class="dropdown-item" to="/profile"><img src={ProfileIcon} width="20px" height="20px"></img>&emsp;  View Profile</Link>
							<Link class="dropdown-item mt-1" to="/profile"><img src={pwd} width="20px" height="20px"></img>&emsp;  Change Password</Link>
							<div class="dropdown-divider"></div>
							<Link class="dropdown-item" onClick={this.logout}><img src={LogoutIcon1} width="20px" height="20px"></img>&emsp;  Sign out</Link>
						</div>

						<img className="input_box1" width="62px" height="30px" src={LogoutIcon} onClick={this.logout}></img>
						
						{/*<Link className='profile' to='/profile'>
							<i class="fa fa-user text-yellow" aria-hidden="true"></i>
							{/* <img src={require('../images/user-solid.png')}/> 
						</Link>*/}
					</div>
					{/* <Router> */}
						<Switch>
							<Route exact path='/' component={Dashboard}></Route>
							<Route exact path='/dashboard' component={Dashboard}></Route>
							<Route exact path='/enquiry' component={Enquiry}></Route>
							<Route exact path='/quotation' component={Quotation}></Route>
							<Route exact path='/orders' component={Orders}></Route>
							<Route exact path='/tickets' component={Ticket}></Route>
							<Route exact path='/fleets' component={Fleets}></Route>
							<Route exact path='/profile' component={Profile}></Route>
							<Route exact path='/enquiry/create-enquiry' component={CreateEnquiry}></Route>
							<Route exact path='/fleets/create-fleet' component={CreateFleet}></Route>
							<Route exact path='/enquiry/view/:id' component={ViewEnquiry}></Route>
							<Route exact path='/ticket/view/:id' component={ViewTicket}></Route>
							<Route exact path='/enquiry/edit/:id' component={EditEnquiry}></Route>
							<Route exact path='/order/view' component={ViewOrders}></Route>
						</Switch>
					{/* </Router> */}
					
						
					
					
				</section>
				</Router>
			</div>
		);
	}
}


const mapStateToProps = (state, ownProps) => {
	console.log(state)
    return {
		uid: state.userSlice.user.payload ? state.userSlice.user.payload.uid : '' ,
		user: state.profileSlice.fullname
		
    }
};


export default connect(mapStateToProps)(Layout);


