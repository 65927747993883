import React, { Component } from 'react';
import api from '../../api/index'
import fire from '../../config/fire';
import LoadingOverlay from 'react-loading-overlay';
import moment from "moment";
import {connect} from 'react-redux';
import { fetchMetrics } from './services/dashboard';
import { toast, ToastContainer } from 'react-toastify';
import './dashboard.css'
import UserList from '../../images/user-list.png';
import LeftQuote from '../../images/left-quotes-sign.png';
import Fleet from '../../images/fleets.png';
import Cart from '../../images/shopping-cart.png';
import Yantra from '../../images/yantra.png';

class Dashboard extends Component {
	currentUser={};

	// state={
	// 	enquiries_count:'',
	// 	fleet_count:'',
	// 	quotations_count:'',
	// 	orders_count:'',
	// 	enquiries:[],
	// 	quatations:[],
	// 	orders:[],
	// 	fleets:[],
	// 	isLoading:false
	// }

	componentDidMount() {
		this.setState({isLoading:true})
		fire.auth().onAuthStateChanged((user)=>{
			if(user){
			  localStorage.setItem('currentUser',JSON.stringify(user))
			  this.setState({isLoading:false})
			} else {
			  localStorage.removeItem('currentUser')
			  this.setState({isLoading:false})
			}
		  })
		this.currentUser=JSON.parse(localStorage.getItem("currentUser"))
		
		console.log(this.currentUser.uid)
		this.props.dispatch(fetchMetrics(this.currentUser.uid))
		
	}


	render() {
		
		const {enquiries_count,fleet_count,quotations_count,orders_count,enquiries,quotations,orders,fleets, loading} = this.props
		return (
			<div className='enquiry_body dashboard' >
			<ToastContainer />	
			<LoadingOverlay active={loading} spinner styles={{overlay: (base) => ({ ...base, position: 'fixed', })}}>
				<div className='p-3 dashboard_body'>
					<div class=" metrix">
						<h3 className="metrix_heading pl-4">Welcome Back, {this.props.user}! </h3>
						<div className="row mt-4 pt-2">
							<div class=" col-lg-3 col-md-6 col-sm-6 mt-4">
								<div class="card card_shadow">
									<div class=" card-header pl-4 pr-4 pb-4">
										
										<div className='card-top'><img src={UserList} width="25px" height="25px"></img></div>
										<p className='text-left text-blue jss99'>Total Enquiries</p>
										<h3 className='jss101 text-left'>{enquiries_count? enquiries_count:0}</h3>
									</div>
								</div>
							</div>

						<div class="col-lg-3 col-md-6 col-sm-6 mt-4">
							<div class="card card_shadow">
								<div class="card-header pl-4 pr-4 pb-4">
									<div className='card-top'><img src={LeftQuote} width="25px" height="25px"></img></div>
									<p className='text-left text-blue jss99'>Total Quotations</p>
									<h3 className='jss101 text-left display-3'>{quotations_count? quotations_count:0}</h3>
								</div>
							</div>
						</div>

						{/* <div class="col-lg-3 col-md-6 col-sm-6">
							<div class="card card_shadow">
								<div class="card-header pl-4 pr-4 ">
									<div className='card-top'><i className='fa fa-shopping-cart fa-2x'></i></div>
									<p className='text-right jss99'>Total Orders</p>
									<h3 className='jss101 text-right display-3'>02</h3>
								</div>
								<div class="card-footer" style={{background: '#0b6588', padding: '4px'}}>
									
								</div>
							</div>
						</div> */}

						<div class="col-lg-3 col-md-6 col-sm-6 mt-4">
							<div class="card card_shadow">
								<div class="card-header pl-4 pr-4 pb-4">
									<div className='card-top'><img src={Cart} width="25px" height="25px"></img></div>
									<p className='text-left text-blue jss99'>Total Orders</p>
									<h3 className='jss101 text-left display-3'>{ orders_count? orders_count:0}</h3>
								</div>
							</div>
						</div>

						<div class="col-lg-3 col-md-6 col-sm-6 mt-4">
							<div class="card card_shadow">
								<div class="card-header pl-4 pr-4 pb-4">
									<div className='card-top'><img src={Fleet} width="25px" height="25px"></img></div>
									<p className='text-left text-blue jss99'>Total Fleets</p>
									<h3 className='jss101 text-left display-3'>{ fleet_count? fleet_count:0}</h3>
								</div>
							</div>
						</div> 
						</div> 
					</div>

					{/* recent tabls */}

					<div class="row  dashboard_tables">
						<div class="col-lg-6 col-md-12 col-sm-12 mt-4">
							<div class="card card_shadow">
								<div class="card-header ">
									<div className='table_top mb-4'>
										<h4 className='table_top_head'> Enquiry Stat</h4>
										<p className='table_top_subhead'>Recent Enquiries</p><hr></hr>
									</div>
									<div className="table-wrapper">
										{(enquiries != undefined && enquiries.length > 0)? 
										<table class="table">
											<thead className="dashboard_th">
												<tr>
													<th scope="col">#</th>
													<th scope="col">Title</th>
													<th scope="col">Created At</th>
												</tr>
											</thead>
											<tbody>
												{ enquiries.map((enquiry,index) => {
													return(<tr role="row" className="odd">
														<td>{index +1}</td>
														<td>{enquiry.title}</td>
														<td>{moment(enquiry.createdAt).format("DD MMM YYYY")}</td>								
													</tr>)
												})}		
											</tbody>
										</table>:<div className='text-center  p-2 text-blue'>No enquiries found</div>}
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-6 col-md-12 col-sm-12 mt-4">
							<div class="card card_shadow">
								<div class="card-header ">
									<div className='table_top mb-4'>
										<h4 className='table_top_head'> Quotation Stat</h4>
										<p className='table_top_subhead'>Recent Quotations</p><hr></hr>
									</div>
									<div className="table-wrapper">
										{(quotations != undefined && quotations.length > 0)? 
										<table class="table">
											<thead className="dashboard_th">
												<tr>
													<th scope="col">#</th> 
													<th scope="col">Enquiry Id</th>
													{/* <th scope='col'>Title</th> */}
													<th scope="col">Created At</th>
												</tr>
											</thead>
											<tbody>
												{quotations.map((quatation,index) => {
													return(<tr role="row" className="odd">
														<td>{index +1}</td>
														<td>{quatation.enquiryId}</td>
														{/* <td>{quatation.title}</td> */}
														<td>{moment(quatation.createdAt).format("DD MMM YYYY")}</td>								
													</tr>)
												})}
											</tbody>
											</table>:<div className='text-center p-2 text-blue'>No quatations found</div>}
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-6 col-md-12 col-sm-12">
							<div class="card card_shadow">
								<div class="card-header">
									<div className='table_top mb-4'>
										<h4 className='table_top_head'> Order Stat</h4>
										<p className='table_top_subhead'>Recent Orders</p><hr></hr>
									</div>
									<div className="table-wrapper">
									{(orders != undefined && orders.length > 0)?
									<table class="table">
										<thead className="dashboard_th">
											<tr>
												<th scope="col">#</th>
												<th scope="col">Enquiry Id</th>
												{/* <th scope='col'>Title</th> */}
												<th scope="col">Created At</th>
											</tr>
										</thead>
										<tbody>
											{ orders.map((order,index) => {
												return(<tr role="row" className="odd">
													<td>{index +1}</td>
													<td>{order.enquiryId}</td>
													{/* <td>{order.title}</td> */}
													<td>{moment(order.createdAt).format("DD MMM YYYY")}</td>								
												</tr>)
											})}
										</tbody>
									</table>
									:<div className='text-center  p-2 text-blue'>No orders found</div>}
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-6 col-md-12 col-sm-12">
							<div class="card card_shadow" >
								<div class="card-header">
									<div className='table_top mb-4'>
										<h4 className='table_top_head'> Fleet Stat</h4>
										<p className='table_top_subhead'>Recent Fleets</p><hr></hr>
									</div>
									<div className="table-wrapper">
										{fleets?
										<table class="table">
											<thead className="dashboard_th">
												<tr>
													<th scope="col">#</th>
													<th scope="col">Type</th>
													<th scope='col'>Model</th>
													<th scope="col">Created At</th>
												</tr>
											</thead>
											<tbody>
												{ fleets.map((fleet,index) => {
													return(<tr role="row" className="odd">
														<td>{index +1}</td>
														<td>{fleet.equipmentType}</td>
														<td>{fleet.equipmentModel}</td>
														<td>{moment(fleet.createdAt).format("DD MMM YYYY")}</td>								
													</tr>)
												})}	
											</tbody>
										</table>
										:<div className='text-center  p-2 text-blue'>No fleets found</div>}
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
				</LoadingOverlay>
				<div className="dashboard_layout_footer text-blue" >
					<a className="text-blue pt-4 pl-4"> www.yantralive.com</a>
					<img src={Yantra}  height="60px"></img>
					<a className="text-blue pt-4 pr-4">© 2021 - All Right Reserved</a>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	console.log(state)
    return {
		metrics:  state.dashboardSlice.metrics ,
		user: state.profileSlice.fullname,
		enquiries_count: state.dashboardSlice.enquiriesCount,
		fleet_count:  state.dashboardSlice.customerFleetCount,
		quotations_count: state.dashboardSlice.enquiryQuotationCount ,
		orders_count: state.dashboardSlice.enquiryOrdersCount,
		enquiries:state.dashboardSlice.enquiryDtos ,
		quotations:state.dashboardSlice.enquiryQuotationDtos ,
		orders:state.dashboardSlice.enquiryOrdersDtoList ,
		fleets:state.dashboardSlice.customerFleetDtos ,
		loading: state.dashboardSlice.loading,
		error: state.dashboardSlice.hasErrors
    }
};


export default connect(mapStateToProps)(Dashboard);
