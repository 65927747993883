import { createSlice, createAction} from '@reduxjs/toolkit';


export const fleetsSlice = createSlice({
    name:'fleets',
    initialState: {
        loading: false,
        isLoading: false,
        hasErrors: null,
        fleets: [],  
        imageUrl:[],
    },

    reducers: {
        setInitial: state => {
            state.hasErrors = null
            state.imageUrl = []
            state.loading = false
            state.isLoading = false
            state.fleets = []
        },

        setFleetsLoading : state => {
            state.loading = true
        },
        setFleetsImageLoading : state => {
            state.loading = true
        },

        unsetFleetsImageLoading : state => {
            state.loading = false
        },

        getFleetsSuccess : (state, action) => {
            state.fleets = action.payload
            state.loading = false
            state.hasErrors = null
        },

        getFleetsFailure : (state, action) => {
            state.loading = false
            state.hasErrors = action.payload
        },

        setFleetImageUrl: (state, action) => {
            state.imageUrl = action.payload
            state.loading = false
        }, 

        unsetFleetImageUrl : (state, action) => {
            state.imageUrl = []
            state.hasErrors = action.payload
            state.loading = false
        },

        createFleetSuccess : (state, action) => {
            state.loading = false
            state.hasErrors = null
            state.imageUrl = []
        },

        createFleetFailure : (state, action) => {
            state.loading = false
            state.hasErrors = action.payload
            state.imageUrl = []
        },
        
    },
 
})

export const { 

    setFleetsLoading, 
    setFleetsImageLoading,
    unsetFleetsImageLoading,
    getFleetsSuccess,
    getFleetsFailure, 
    setFleetImageUrl,
    unsetFleetImageUrl,
    createFleetSuccess,
    createFleetFailure,
    setInitial
   
} = fleetsSlice.actions



export default fleetsSlice.reducer;


