import { toast } from "react-toastify"
import api from "../../../api"
import browserHistory from "../../../config/browserHistory"
import fire from "../../../config/fire"
import storage from 'redux-persist/lib/storage';


import { 
    loginFailure, 
    setLoading, 
    loginSuccess, 
    googleLoginSuccess,
    googleLoginFailure, 
    registerFailure,
    registerSuccess,
    otpLoginSuccess,
    otpLoginFailure,
    otpFailure,
    otpSuccess,
    googleLoginData,
    setHide,
    setHAuthToken
} from "../userSlice"
import firebase from 'firebase';

export const login = (payload) => async (dispatch) => {
   
    dispatch(setLoading())
  
    try {
        const response = await fire.auth().signInWithEmailAndPassword(payload.email, payload.password)
        let data = response.user
        data = {
            uid : data.uid,
            userData: data.providerData,
            token: data.xa
        }
        console.log(data)
        dispatch(loginSuccess(data))
        dispatch(createCustomerGoogle(data, {loginType:'google'}))
        
    
    } catch (error) {
        dispatch(loginFailure(error.message))
        toast.error(error.message, {position: "top-center"})
    }
}


export const googleLogin = () => async (dispatch) => {
   
    dispatch(setLoading())
  
    try {
        var provider = new firebase.auth.GoogleAuthProvider();
        const response = await fire.auth().signInWithPopup(provider)
        console.log(response)
        
        let data = response.user
        data = {
            uid : data.uid,
            userData: data.providerData,
            token: data.xa
        }

        console.log(data)
        dispatch(googleLoginData(data))
        dispatch(createCustomerGoogle(data, {loginType:'google'}))

    } catch (error) {
        dispatch(googleLoginFailure(error.message))
        toast.error(error.message, {position: "top-center"})
    }
}


export const register = (payload) => async (dispatch) => {

    console.log(payload.name)

    dispatch(setLoading())

    try {
       
        const response = await fire.auth().createUserWithEmailAndPassword(payload.email, payload.password)
        console.log(response.user)
        let data = response.user
        data = {
            uid : data.uid,
            userData: data.providerData,
            userName: payload.name,
            email: payload.email,
            token: data.xa
        }

        console.log(data)
        dispatch(googleLoginData(data))
        dispatch(createCustomerGoogle(data, {loginType:'register'}))
        
    } catch (error) {
        dispatch(registerFailure(error.message))
        toast.error(error.message, {position: "top-center"})
    }
}


export const otpLogin = (payload) => async (dispatch) => {
    //dispatch(setLoading())
    debugger
    
        console.log(payload.phone)
        console.log(payload.recaptcha)
    
        fire.auth().signInWithPhoneNumber(payload.phone, payload.recaptcha).then(res => {
            console.log(res)
            let data = res
            dispatch(otpLoginSuccess(data))
        }).catch(error => {
            dispatch(otpLoginFailure(error.message))
            console.log(error)
            toast.info("Invalid Phonenumber, please try again.!!", {position: "top-center"})
        })
        
        
    
}

export const otpRequestLogin = (result, otp) => async (dispatch) => {

    debugger

    dispatch(setLoading())
    

    console.log(result)

    console.log(otp)

    try {
        const response = await result.confirm(otp)
        console.log(response)
        let data = response.user
        data = {
                uid : data.uid,
                userData: data.providerData,
                token: data.xa
            }
       
        console.log(data)
        //dispatch(googleLoginData(data))
        //dispatch(createCustomerGoogle(data, {loginType:'otp'}))
        
    } catch (error) {
        debugger
        console.log(error)
        // dispatch(otpFailure(error.message))
        // dispatch(setHide(false))
        toast.info("Invalid OTP", {position: "top-center"})
		// browserHistory.push('/login')
    }

}




export const createCustomerGoogle = (user, payload) => async (dispatch) => {
    

    let user_name = ''

    let msg_data = {
        "sms": false,
        "whatsApp": false,
        "email": false
    }

    if(payload.loginType == 'google' || payload.loginType == 'register'){
        msg_data = {
            "sms": false,
            "whatsApp": false,
            "email": true
        }
    }else if(payload.loginType == 'otp'){
        msg_data = {
            "sms": true,
            "whatsApp": true,
            "email": false
        }
    }
  

    if(payload.loginType == 'google' || payload.loginType == 'otp'){
        user_name = user.userData[0].displayName
    }else if(payload.loginType == 'register'){
        user_name = user.userName
    }

    let data={
        customer: user_name,
        code: user.uid ? user.uid : '',         
        company: "",
        gstType: "",
        gst: "",
        streetAddress: "",
        city: "",
        state: "",
        country: "",
        pinCode: "",
        phone: user.userData[0].phoneNumber ? user.userData[0].phoneNumber : '',
        email: user.userData[0].email,
        currency: "",
        customerType: "",
        noOfMachinesOwned: '',
        billingAddress: "",
        deliveryAddress: "",
        contactPerson: "",
        designation: "",
        contactPerson1: "",
        email1: "",
        mobile1: "",
        alternatePhone: "",
        contactPerson2: "",
        email2: "",
        mobile2: "",
        panNo: "",
        status: "",
        website: "",
        rating: 0,
        fsCustomerId: "",
        preferred_language: "",
        customerGroup: "",
        associateId: "",
        lastPurchase: "2050-01-01",
        zohoDeskCustomerId: "",
        id: '',
        messagePreference: msg_data
        
    }

    console.log(data)

    // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
    // console.log(token)
    
    dispatch(setHAuthToken(user.token))
  
    try {
        const response = await api.post(`api/cj/customers/add`,data, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        console.log(response)

        let result = JSON.stringify(response.data)
        let res_data = JSON.parse(result)
        
        console.log(res_data)
       
        if(res_data.status == true){
            if(payload.loginType == 'google') {
                dispatch(googleLoginSuccess(res_data.data))
                browserHistory.push('/dashboard')
            }else if(payload.loginType == 'otp' ){
                dispatch(otpSuccess(res_data.data)) 
                browserHistory.push('/dashboard')
            }else{
                dispatch(registerSuccess(res_data.data)) 
                browserHistory.push('/dashboard')
            }
        }else {
            console.log(res_data.error.message)
        }
        
    } catch(error) {
        if(payload.loginType == 'google'){
            dispatch(googleLoginFailure(error.message))
            toast.error(error.message, {position: "top-center"})
            fire.auth().signOut();
            localStorage.removeItem('currentUser');
            window.localStorage.clear();
            storage.removeItem('persist:root')
            browserHistory.push('/login')
            
        }else if( payload.loginType == 'otp'){
            dispatch(registerFailure(error.message))
            toast.error(error.message, {position: "top-center"})
            fire.auth().signOut();
            localStorage.removeItem('currentUser');
            window.localStorage.clear();
            storage.removeItem('persist:root')
            browserHistory.push('/login')
            
        }else{
            dispatch(otpFailure(error.message))
            toast.error(error.message, {position: "top-center"})
            fire.auth().signOut();
            localStorage.removeItem('currentUser');
            window.localStorage.clear();
            storage.removeItem('persist:root')
            browserHistory.push('/login')
            
        } 
    }
}



