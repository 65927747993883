import React, { Component } from 'react';
import api from '../../api/index';
import browserHistory from '../../config/browserHistory'
import '../enquiries/createEnquiry.css'
import LoadingOverlay from 'react-loading-overlay';
import { Select } from "antd";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { Form, Input, Row, Col} from "antd";
import "antd/dist/antd.css";
import YearPicker from "react-year-picker";
import {connect} from 'react-redux';
import { createFleet, fleetFileUpload } from './services/fleets';
import Pdf from '../../images/pdf.png';
import Excel from '../../images/excel.png';
import Word from '../../images/word.png';
import { setFleetsImageLoading, setFleetsLoading, setInitial, unsetFleetsImageLoading } from './fleetsSlice';
import Fleet from '../../images/fleets.png';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 


const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const layout1 = {
	labelCol: { span: 6 },
	wrapperCol: { span: 16 },
};



const { Option } = Select;
const children = [];
for (let i = 0; i < 1000; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

class CreateFleet extends Component {

	formRef = React.createRef();

	fileObj = [];
  	fileArray = [];
  	imageArray=[];
	imageName=[];
	currentUser={};
	
	constructor(props){
    super(props)
    this.state = {
			file: [],
			make:'',
			model:'',
			type:'',
			year:'',
			brand:'',
			no_of_units:'',
			vin:'',
			imageUrl:'',
			isLoading:false,
			img: [],
			lightBoxOpen: false,	

    }
	}

	handleChange=(e)=> {
    	this.setState({[e.target.name]: e.target.value})
	}

	fileChange = async(e)=> {
		
		this.props.dispatch(setFleetsImageLoading())
		this.fileObj.push(e.target.files)
		for (let i = 0; i < this.fileObj[0].length; i++) {
			this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
		}
		this.setState({file: this.fileArray})

   		 const fd = new FormData();
		fd.append('file', e.target.files[0])

		await this.props.dispatch(fleetFileUpload(fd))

		
	
		// api.post(`/v1/upload/file`, fd).then(res => {
		// this.setState({imageUrl:res.data[0]})
		// 	}).catch(err => {
		// 		console.log(err)
		// 	})
	}

	componentDidMount(){
		this.currentUser=JSON.parse(localStorage.getItem("currentUser"))
		this.props.dispatch(setInitial())
	}

	handleSubmit= async(e)=>{
		debugger
		e.preventDefault();
		try {
			const value = await this.formRef.current.validateFields();
			let data = {
				customerId: this.currentUser.uid,
				equipmentType: this.state.type,
				equipmentMake : this.state.make,
				equipmentModel: this.state.model,
				imageUrl: this.props.imageUrl.fileUrl,
				equipmentYear: this.state.year,
				brand: this.state.brand,
				noOfUnits: this.state.no_of_units,
				vin: this.state.vin,
			}

			await this.props.dispatch(createFleet(data))
	
		} catch (errorInfo) {
			console.log("failure", errorInfo);
			toast.info("Invalid form entry..!", {position: "top-center"})
		}
	}

	render() {
		let fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gmi
		return (
			<div className='enquiry_body'>
				<ToastContainer />
				<LoadingOverlay active={this.props.loading} spinner styles={{overlay: (base) => ({ ...base, position: 'fixed', })}}>
				

				<div className="row quotation_panel d-flex flex-column">  
					<h4 class="page--title h5 text-white ml-4 pl-4">Create Fleet</h4>
					<ul class="d-flex flex-row">
						<li className="text-white"><Link style={{color:"#CACACA"}} className=" ml-4 pl-4" to="/dashboard">Dashboard &nbsp;/ &nbsp;</Link></li>
						<li className="text-white"><Link style={{color:"#CACACA"}} className="" to="/fleets">My Fleets &nbsp;/ &nbsp;</Link></li>
						<li className=" "><span style={{color:'white'}}> Create Fleet</span></li>
					</ul>
				</div>  

			

				<div className='pl-4 pr-4 ml-4 mr-4 list_table '>
						
						<div className='card card_shadow'>
							<div class="card-header pl-4 pr-4 ">
							<div className='table_top'>
								<h4 className='table_top_head d-flex flex-row '><div className="icon-top"><img src={Fleet} width="20px" height="20px" ></img></div>&emsp;Create Fleet</h4>
								<hr className="create__enq__hr"></hr>
								
							</div>
							<Form ref={this.formRef} className="enq_form mt-4 pt-4">
								<Row >
									<Col span={11} className = 'ml-4'>
									<Form.Item name="make" label="Equipment Make"  {...layout}
										rules={[{ required: true, message: "Please enter equipment make!" }]}
									>
										<Input autocomplete="off" name="make" onChange={this.handleChange} placeholder="JCB or Hitachi or Sany"/>
									</Form.Item>
									</Col>
									<Col span={10} className = 'ml-4'>
										
										<Form.Item name="no_of_units" label="Number of units" {...layout}
											rules={[{ required: true, message: "Please enter Number of units!" },{pattern:/^[1-9][0-9]*$/, message:"Number of units should be positive integer greater than ZERO.!"}]}
										>
											<Input type='number' autocomplete="off" name="no_of_units" onChange={this.handleChange} />
										</Form.Item>
									</Col>	
								</Row>
								<Row >
									<Col span={11} className = 'ml-4'>
										<Form.Item name="model" label="Equipment Model" {...layout}
										rules={[{ required: true, message: "Please enter equipment model!" }]}
										>
											<Input autocomplete="off" name="model" onChange={this.handleChange} placeholder="3DX or PC200-2 or SY220" />
										</Form.Item>
									</Col>
									<Col span={10} className = 'ml-4'>
										
										<Form.Item name="type" label="Equipment Type" {...layout}>
											<Input autocomplete="off" name="type" onChange={this.handleChange} placeholder="Excavator" />
										</Form.Item>
									</Col>	
									
								</Row>
								<Row >
									<Col span={11} className = 'ml-4'>
										<Form.Item name="year" label="Year" {...layout}>
											{/* <YearPicker name="year" onChange={this.} /> */}
											<Input autocomplete="off" name="year" onChange={this.handleChange} />
										</Form.Item>
									</Col>
									<Col span={10} className = 'ml-4'>
										<Form.Item name="vin" label="Vin" {...layout}>
											<Input autocomplete="off" name="vin" onChange={this.handleChange} />
										</Form.Item>
									</Col>	
								</Row>
								<Row >
									<Col span={11} className = 'ml-4' {...layout1}>
										
										<Form.Item name="file" label="Attach Image/docx/pdf/xlsx" >
											<Input type="file" name='file' onChange={this.fileChange}  />
										</Form.Item>
									</Col>	
								</Row>
							</Form>
					




						
							{this.props.imageUrl != '' ?<fieldset className='col-lg-11 col-sm-12 ml-4 mt-4 mb-4 selected_files' > 
								<legend style={{color: "white"}} className="ml-2 mb-2">Selected Files</legend>
								
								{/* {(this.state.imageUrl || []).map(url => ( */}
									{
										this.props.imageUrl["fileUrl"].match(fileExtensionPattern)[0] == ".pdf" ?
										<a href={this.props.imageUrl["fileUrl"]} target="_blank">
											<img src={Pdf} alt="pdf" width='100px' height="100px" className='mr-3' />
										</a>:
										this.props.imageUrl["fileUrl"].match(fileExtensionPattern)[0] == ".xlsx" ?
										<a href={ this.props.imageUrl["fileUrl"]} >
											<img src={Excel} alt="xlxs" width='100px' height="100px" className='mr-3' />
										</a>:

										this.props.imageUrl["fileUrl"].match(fileExtensionPattern)[0] == ".docx" ?
										<a href={ this.props.imageUrl["fileUrl"]} >
											<img src={Word} alt="docx" width='100px' height="100px" className='mr-3' />
										</a>:
										<img src={this.props.imageUrl["fileUrl"]} alt="img" width='100px' height="100px" className='mr-3' onClick={() => this.setState({ lightBoxOpen: true, img: this.props.imageUrl["fileUrl"]})} />

								}
                        			
				{/* ))} */}
				
								
							</fieldset>:''}

							<button className='enquiry_sub_btn mb-4' onClick={this.handleSubmit}> Submit </button>
							{this.state.lightBoxOpen && (
								<Lightbox
								  mainSrc={this.state.img}
								 
								  onCloseRequest={() => this.setState({ lightBoxOpen: false })}
								  
								/>
							  )}
						
					</div>
					<div className='col-lg-3 col-sm-12'></div>
				</div>
				</div>
				</LoadingOverlay>
			</div>
		);
	}
}


const mapStateToProps = (state, ownProps) => {
	console.log(state)
    return {
		uid: state.userSlice.user.payload.uid,
		loading: state.fleetsSlice.loading,
		imageUrl: state.fleetsSlice.imageUrl,
		
    }
};


export default connect(mapStateToProps)(CreateFleet);
