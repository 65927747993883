import React, { Component } from 'react';
import api from '../../api/index'
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import { fetchFleets } from './services/fleets';
import { ToastContainer } from 'react-toastify';
import './fleets.css';
import { setInitial } from './fleetsSlice';
import Pdf from '../../images/pdf.png';
import Excel from '../../images/excel.png';
import Word from '../../images/word.png';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import Yantra from '../../images/yantra.png';
import Fleet from '../../images/fleets.png';
import NoData from '../../images/no-data-concept-illustration_114360-536.jpg';

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
};
  
const layout1 = {
	labelCol: { span: 6 },
	wrapperCol: { span: 16 },
};

class Fleets extends Component {
	currentUser={};

	state={
		fleets:[],
		fleetSize:null,
		isLoading:false,
		image:'',
		lightBoxOpen: false,
		img: []
	}

	componentDidMount() {
		this.setState({isloading:true})
		this.currentUser=JSON.parse(localStorage.getItem("currentUser"))
		this.props.dispatch(setInitial())
		this.props.dispatch(fetchFleets())
	}

	
	onSort=(event, sortKey)=>{
		const data = this.state.fleets;
		data.sort((a,b) => a[sortKey].localeCompare(b[sortKey]))
		this.setState({fleets:data})
	}

	render() {
		const {fleets, loading} = this.props
		let fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gmi
		return (
			<React.Fragment>
			<div className='enquiry_body' >
				<LoadingOverlay active={loading} spinner styles={{overlay: (base) => ({ ...base, position: 'fixed', })}}>
				<ToastContainer />	
				 
				<div class="panel row">
					<div className='col'><h4 className='text-white ml-4 pl-4'> All Fleets</h4>
					<p className='text-white ml-4 pl-4'>Found Total {fleets ? fleets.length : '' } Fleets </p></div>
					
				</div>

				
		
				<div className="pl-4 pr-4 ml-4 mr-4 list_table">
					<div className='card card_shadow'>
						<div class="card-header pl-4 pr-4 ">
							
							<div className='table_top d-flex flex-row justify-content-between align-middle mb-4'>
								<div className="d-flex flex-column">
									<h4 className='table_top_head d-flex flex-row '><div className="icon-top"><img src={Fleet} width="20px" height="20px" ></img></div>&emsp;Fleets List</h4>
									<p className="pl-4 note_text">&emsp; &emsp;   Note: Add list of your machines, you will be the first one to get exciting deals and offers for your machines.</p>
								</div>
								<div className='col mt-4'><Link to='/fleets/create-fleet' class="btn btn-sm btn-outline-info sb_btn ">+ Add Fleet</Link></div>
							</div>
							{fleets.length > 0 ? 
							<div class="table-responsive">
								<table class="table ">
									<thead className="enquiry_list_th">
										<tr>
											<th scope="col">Id</th>
											
											<th scope="col"  onClick={e => this.onSort(e, 'equipment_type')}>Type</th>
											<th scope="col" onClick={e => this.onSort(e, 'equipment_model')}>Model</th>
											<th scope="col" onClick={e => this.onSort(e, 'equipment_make')}>Make</th>
											<th scope="col" onClick={e => this.onSort(e, 'equipment_year')}>Year</th>
											{/* <th scope="col" onClick={e => this.onSort(e, 'brand')}>Brand</th> */}
											<th scope="col">No Of Units</th>
											<th scope="col" onClick={e => this.onSort(e, 'vin')}>Vin</th>
											<th scope="col" onClick={e => this.onSort(e, 'created_at')}>Created At</th>
											<th scope="col" onClick={e => this.onSort(e, 'created_at')}>Updated At</th>
											<th scope="col">Image</th>
										</tr>
									</thead>
									<tbody className="enquiry_list_tbody">
									{fleets ? fleets.map((fleet,index) => {
										console.log(fleet.imageUrl.match(fileExtensionPattern) ? fleet.imageUrl.match(fileExtensionPattern)[0]: '')
										return(<tr role="row" className="odd">
											<td>{fleet.id}</td>
											
											<td>{fleet.equipmentType}</td>
											<td>{fleet.equipmentModel}</td>
											<td>{fleet.equipmentMake}</td>
											<td>{fleet.equipmentYear}</td>	
											{/* <td>{fleet.brand}</td>	 */}
											<td>{fleet.noOfUnits}</td>	
											<td>{fleet.vin}</td>	
											<td>{(fleet.createdAt)}</td>	
											<td>{(fleet.updatedAt)}</td>	
											<td style={{cursor:"pointer"}}>
												{
													fleet.imageUrl.match(fileExtensionPattern) ? 
													fleet.imageUrl.match(fileExtensionPattern)[0] == ".pdf" ?
													<a href={fleet.imageUrl} target="_blank"><img src={Pdf} alt="pdf" height="30px"/></a>
													:
													fleet.imageUrl.match(fileExtensionPattern)[0] == ".xlsx" ?
													<a href={fleet.imageUrl} ><img src={Excel}  alt="xlxs" height="30px"/></a>
													:
													fleet.imageUrl.match(fileExtensionPattern)[0] == ".docx" ?
													<a href={fleet.imageUrl} ><img src={Word} alt="docx" height="30px"/></a>
													:
													
													<img src={fleet.imageUrl}  alt="img" height="30px" onClick={() => this.setState({ lightBoxOpen: true, img: fleet.imageUrl })} />
												: ''
												}
											</td>						
										</tr>)
									}):""}
									</tbody>
								</table>

							</div>
							:
							<React.Fragment>
								<hr className="create__enq__hr"></hr>
								<div class="table-responsive d-flex flex-column justify-content-center align-items-center">
										<span className="no_data_text mt-4 mb-2">
											You have not created your fleet, Add list of machines you own or maintain. 
										
										</span>
										<span className="no_data_text mb-2">
											After you have added your list of machines, you will be the first one to get exciting deals and offers for your machines.   
										</span>
										<img src={NoData} height="300px"></img>
								</div>
							</React.Fragment>
							}

						</div>
					</div>
				</div>
				{this.state.lightBoxOpen && (
					<Lightbox
					  mainSrc={this.state.img}
					 
					  onCloseRequest={() => this.setState({ lightBoxOpen: false })}
					  
					/>
				  )}
				</LoadingOverlay>
			</div>
			<div className="dashboard_layout_footer mb-4 text-blue" >
				<a className="text-blue ml-4 pl-4"> www.yantralive.com</a>
				<img src={Yantra}  height="60px"></img>
				<a className="text-blue pr-4 mr-4">© 2021 - All Right Reserved</a>
			</div>
			</React.Fragment>
		);
	}
}


const mapStateToProps = (state, ownProps) => {
	console.log(state)
    return {
		uid: state.userSlice.user.payload.uid,
		fleets: state.fleetsSlice.fleets,
		loading: state.fleetsSlice.loading,
    }
};


export default connect(mapStateToProps)(Fleets);

