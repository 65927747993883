import { toast } from "react-toastify"
import api from "../../../api"
import browserHistory from "../../../config/browserHistory"
import fire from "../../../config/fire"
import { getFleetsFailure, getFleetsSuccess, setFleetImageUrl, setFleetsLoading, unsetFleetImageUrl, createFleetSuccess, createFleetFailure } from "../fleetsSlice"
import storage from "redux-persist/lib/storage"


export const fetchFleets = () => async (dispatch, getState) => {

    const {userSlice} = getState()

    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken

    console.log(uid)
   
    dispatch(setFleetsLoading())
  
    try {

        // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
        // console.log(token)

        const response = await api.get(`api/customer/fleet/${uid}`, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
        console.log(response.data)

        let result = JSON.stringify(response.data)
        let res_data = JSON.parse(result)

        console.log(res_data)
        if(res_data.status == true){
            dispatch(getFleetsSuccess(res_data.data))
        }else{
            toast.error(res_data.error.message)
            dispatch(getFleetsFailure(res_data.error.message))
            
        }
        
        
    
    } catch (error) {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!")
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }
        }else{
            toast.error(error.message)
            dispatch(getFleetsFailure(error.message))
        }
    }
}



export const fleetFileUpload = (payload) => async (dispatch, getState) =>  {

    dispatch(setFleetsLoading())
    
    const {userSlice} = getState()

    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken
    
    // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
    // console.log(token)


    
    api.post(`api/upload`, payload, {
        headers: {
            'Authorization': `Bearer ${authToken}`
        }
    })

    .then(result => {

        let data = JSON.stringify(result.data)
        let result_data = JSON.parse(data)
        console.log(result_data)

        if(result_data.status == true){
           
            console.log(result_data)
            dispatch(setFleetImageUrl(result_data.data[0]))
        }else{
            toast.error(result_data.error.message)
            dispatch(unsetFleetImageUrl(result_data.error.message))
        }
    }).catch(error => {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!")
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }
        }else{
            toast.error(error.message)
            dispatch(unsetFleetImageUrl(error.message))
        }
    })

}


export const createFleet = (payload) => async (dispatch, getState) => {

   
    dispatch(setFleetsLoading())

    const {userSlice} = getState()

    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken
  
    try {

        // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
        // console.log(token)

        const response = await api.post('api/customer/fleet/add', payload, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        } )
       
        
        let data = JSON.stringify(response.data)
        let result_data = JSON.parse(data)
        console.log(result_data)

        

        if(result_data.status == true){
            
            dispatch(createFleetSuccess())
            browserHistory.push('/fleets')
           
        }else{
            toast.error(result_data.error.message)
            dispatch(createFleetFailure(result_data.error.message))
        }
        
        
    
    } catch (error) {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!")
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }
        }else{
            toast.error(error.message)
            dispatch(createFleetFailure(error.message))
        }
        
    }
}



