import React, { Component } from 'react';
import fire from '../../config/fire';
import firebase from 'firebase'
import browserHistory from '../../config/browserHistory'
import { ToastContainer, toast } from 'react-toastify';
import api from '../../api/index'
import "antd/dist/antd.css";
import { Form, Input, Button } from "antd";
import {connect} from 'react-redux'
import { createCustomerGoogle, otpLogin, otpRequestLogin } from './services/user';
import { googleLoginData, otpLoginSuccess } from './userSlice';

class OtpLogin extends Component {

	state={
		phone:'',
		// name:'',
		otp:'',
		hide:false,
		otp_err:'',
		result:{},
	}

	
	handleChange = (e) => {
		this.setState({[e.target.name]:e.target.value})
	}

	handleClick = async (e) => {
		
		if(this.state.phone.match(/^[0-9]\d{9}$/)){
			var ph_no="+91"+this.state.phone
			let recaptcha = new firebase.auth.RecaptchaVerifier('recaptcha-container');
			

			console.log(recaptcha)
			
			let data = {
				phone: ph_no,
				recaptcha: recaptcha
			}

			console.log(data)
			try{
				await this.props.dispatch(otpLogin(data))

			}catch(err){
				console.log(err)
				toast.error(err.message, {position: "top-center"})
			}
			

			
		}else{
			toast.info("Please enter valid phone number", {position: "top-center"})
		}
	}

	verifyOTP = async() => {
		
		
		 (this.props.result).confirm(this.state.otp).then(res=>{
			
			console.log(res)
			
			let data = res.user
			data = {
					uid : data.uid,
					userData: data.providerData,
					token: data.xa
				}
			console.log(data)
		   
			this.props.dispatch(googleLoginData(data))
			this.props.dispatch(createCustomerGoogle(data, {loginType:'otp'}))
			
		}).catch(err=>{
			console.log(err.message)
			this.setState({otp_err:"Invalid OTP", otp:''})
		})
	}

	createCustomer = (user) => {
		var data={
			id: '',
			code: user.uid,
			customer: "",
			company: "",
			gst_type: null,
			gst: null,
			street_address: "",
			city: "",
			state: "",
			country: "",
			pin_code: "",
			phone: user.phoneNumber,
			email: user.email,
			currency: "",
			customer_type: "",
			no_of_machines_owned: 0,
			billing_address: "",
			delivery_address: "",
			contact_person: "",
			designation: "",
			contact_person_1: "",
			email_1: "",
			mobile_1: "",
			alternate_phone: "",
			contact_person_2: "",
			email_2: "",
			mobile_2: "",
			pan_no: "",
			status: "",
			website: "",
			last_purchase: "2050-01-01",
			rating: 0,
			fs_customer_id: null,
			preferred_language: null,
			customer_group: null
		}
		api.post(`api/cj/customers/add`,data).then(res=>{
			console.log(res)
			this.setState({isLoading:false})			
		}).catch(err=>{
			console.log(err)
		})
	}


	

	render() {
		
		return (
			<div>
				<ToastContainer />

				<Form>
					<Form.Item name="phone" className='mb-2' 
					rules={[
						{ required: true, message: "Please input your phone munber!" },
						{ pattern: /^[0-9]*$/, message: "Invalid phone number!" },
						{ max: 10, message: "Should accept only 10 degits!" },
						{ min: 10, message: "Please enter 10 degits phone number!" },
					]}
				>
						<Input 
              				autocomplete="off"
							name="phone" 
							placeholder='Phone number' 
							onChange={this.handleChange} 
							hidden={this.props.hide}
						/>
					</Form.Item>

					
				</Form>

				<input
					className='form-control mb-2' 
					name='otp' type='number' 
					placeholder='Enter OTP' 
					onChange={this.handleChange} 
					hidden={!this.props.hide}
					style={{position:"absolute", top:"40%",width:'90%'}}
				/>
				<button 
					style={{ color:"white",borderRadius:"7px", backgroundColor:"#FDC32C", border:"none", outline:"none", cursor:"Pointer"}}
					className=' mr-1  mt-4 mb-2 pl-4 pr-4 pt-2 pb-2' 
					onClick={this.handleClick} 
					hidden={this.props.hide}
				>
					Send OTP
				</button>
				<button 
					style={{ color:"white",borderRadius:"7px", backgroundColor:"#FDC32C", border:"none", outline:"none", cursor:"Pointer"}}
					className=' mr-1  mt-4 mb-2 pl-4 pr-4 pt-2 pb-2' 
					onClick={() => this.verifyOTP()} 
					hidden={!this.props.hide}
				>
					Verify
				</button>
				<p className='text-danger'>{this.state.otp_err}</p>
				<div className='mt-3' id="recaptcha-container" hidden={this.props.hide}></div>
			</div>
		);
	}
}


function  mapStateToProps  (state, ownProps)  {
	return {
		user: state.userSlice.user,
		error: state.userSlice.error,
		loading: state.userSlice.loading,
		hide:  state.userSlice.hide,
		result: state.userSlice.otp_result,
	}
}


export default connect(mapStateToProps)(OtpLogin);
