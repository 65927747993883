import { toast } from "react-toastify"
import api from "../../../api"
import browserHistory from "../../../config/browserHistory"
import fire from "../../../config/fire"
import { getQuotationFailure, getQuotationSuccess, reviseOkFailure, reviseOkSuccess, setPage, setquotationsLoading, setTotalElements, setVisible, updateFailure, updateSuccess } from "../quotationsSlice"
import storage from "redux-persist/lib/storage"
import { TonalitySharp } from "@material-ui/icons"


export const fetchQuotations = (payload) => async (dispatch, getState) => {

    debugger

    const {userSlice} = getState()

    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken

    console.log(uid)
   
    dispatch(setquotationsLoading())

    
    const {quotationsSlice} = getState()

    const nextPage = quotationsSlice.nextPage

    const nextPageSize = quotationsSlice.nextPageSize


    try {

        // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
        // console.log(token)

        const response = await api.get(`api/cj/enquiry/customer/quotations/${uid}`, {
            params: {
				pageNo: payload,
                size:5,
                nextPage: nextPage,
                nextPageSize: nextPageSize
             },headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
        console.log(response.data)

        if(response.data.status == true){
            let res = JSON.stringify(response.data)
            let result = JSON.parse(res).data 
            console.log(result.enquiryQuotations.length)
            let data = []
          
            for(let i=0;i<result.enquiryQuotations.length;i++){
                if(result.enquiryQuotations[i].status != "draft"){
                    data.push(result.enquiryQuotations[i])
                }
            }
    
            console.log(data)
            console.log(result)
            
            dispatch(getQuotationSuccess(data))
            dispatch(setTotalElements(result))
            dispatch(setPage(payload))
        }else {
            dispatch(getQuotationFailure("Failed to load, Please Try Later!!"))
            toast.error("Failed to load, Please Try Later!!")
        }

       
    
    } catch (error) {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!")
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }
        }else{
            dispatch(getQuotationFailure(error.message))
            toast.error(error.message)
        }
    }
}


export const reviseOk = (payload) => async (dispatch, getState) => {


    dispatch(setquotationsLoading())

    const {userSlice} = getState()

    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken

    try {
        // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
        // console.log(token)

        const response = await api.post(`api/quotations/update/customer/clarification `, payload, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
        console.log(response.data)

        let res = JSON.stringify(response.data)
        let result = JSON.parse(res)

        if(result.status == true){
            dispatch(setVisible(false))
            dispatch(reviseOkSuccess())
            dispatch(fetchQuotations(1))
        }else {
            dispatch(setVisible(false))
            toast.error(result.error.message)
            dispatch(reviseOkFailure(result.error.message))
           
        }
        
      
    
    } catch (error) {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!")
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }
        }else{
            dispatch(setVisible(false))
            toast.error(error.message)
            dispatch(reviseOkFailure(error.message))
        }
        
    }
}


export const updateStatus = (payload, state) => async (dispatch, getState) => {

    
    dispatch(setquotationsLoading())

    const {userSlice} = getState()

    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken

    try {

        // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
        // console.log(token)

        const response = await api.post(`api/update/quotations/status`, payload, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
        console.log(response.data)

        let res = JSON.stringify(response.data)
        let result = JSON.parse(res)
        console.log(result)
       if(result.status == true){
            dispatch(setVisible(false))
            dispatch(updateSuccess(result.data))
            state=="rejected"? 
           
            toast.success(`This Quotation with ID ${payload.quotationId} has been rejected`)
           : toast.success(`Order Placed Successfully`)
            state=="rejected"? dispatch(fetchQuotations(1)):  browserHistory.push('/orders')
        }else {
            if(result.data == "Could not create order,Please update your Full name/Email"){
                toast.error(result.data)
            }
            dispatch(setVisible(false))
            console.log(result.error.message)
            dispatch(updateFailure(result.error.message))
        }
    
    } catch (error) {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!")
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }
        }else{
            dispatch(setVisible(false))
            dispatch(updateFailure(error))
            console.log(error.message)
        }
    }
}

