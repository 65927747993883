import { createSlice, createAction} from '@reduxjs/toolkit';


export const dashboardSlice = createSlice({
    name:'dashboard',
    initialState: {
        loading: false,
        hasErrors: null,
        metrics: {},
        customerFleetDtos: [],
        enquiryDtos: [],
        enquiryQuotationDtos: [],
        enquiryOrdersDtoList: [],
        enquiriesCount: 0,
        customerFleetCount: 0,
        enquiryQuotationCount: 0,
        enquiryOrdersCount: 0
    },
    reducers: {

        getMetrics: state => {
            state.loading = true
        },

        getMetricsSuccess: (state,  action ) => {
            state.metrics = action.payload
            state.customerFleetDtos = action.payload.customerFleetDtos
            state.enquiryDtos = action.payload.enquiryDtos
            state.enquiryQuotationDtos = action.payload.enquiryQuotationDtos
            state.enquiryOrdersDtoList = action.payload.enquiryOrdersDtoList
            state.enquiriesCount = action.payload.enquiriesCount
            state.customerFleetCount = action.payload.customerFleetCount
            state.enquiryQuotationCount = action.payload.enquiryQuotationCount
            state.enquiryOrdersCount = action.payload.enquiryOrdersCount
            state.loading = false
            state.hasErrors = null
        },


        getMetricsFailure: (state, payload) => {
            state.loading = false
            state.hasErrors = payload
        },

    },
 
})

export const { getMetrics, getMetricsSuccess, getMetricsFailure } = dashboardSlice.actions



export default dashboardSlice.reducer;


