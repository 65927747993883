import React, { Component } from 'react';
import api from '../../api/index'
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import { getEnquiryById } from './services/enquiry';
import Pdf from '../../images/pdf.png';
import Excel from '../../images/excel.png';
import Word from '../../images/word.png';
import { setInitialState } from './enquirySlice';
import { ToastContainer } from 'react-toastify';
import jsPDF from 'jspdf';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import Yantra from '../../images/yantra.png';
import UserList from '../../images/user-list.png';



class ViewEnquiry extends Component {
	state={
		enquiry:'',
		images:[],
		isLoading:false,
		image: '',
		lightBoxOpen: false,
		img: []
	}


	
	componentDidMount(){

		// this.props.dispatch(getEnquiryById(this.props.location.state.id))
		this.getEnquiry(this.props.location.state.id);
	}

	getEnquiry = async(id) => {
		this.props.dispatch(setInitialState())
		await this.props.dispatch(getEnquiryById(id))
		
	}

	generatePdf = (file) => {
	
		
		if (!window.ActiveXObject) {
			var save = document.createElement('a');
			save.href = file;
			save.target = '_blank';
			var filename = file.substring(file.lastIndexOf('/')+1);
			save.download = filename ;
			   if ( navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
					document.location = save.href; 
	
				}else{
					var evt = new MouseEvent('click', {
						'view': window,
						'bubbles': true,
						'cancelable': false
					});
					save.dispatchEvent(evt);
					(window.URL || window.webkitURL).revokeObjectURL(save.href);
				}	
		}
	
		// for IE < 11
		else if ( !! window.ActiveXObject && document.execCommand)     {
			var _window = window.open(file, '_blank');
			_window.document.close();
			_window.document.execCommand('SaveAs', true, filename || file)
			_window.close();
		}
	}

	render() {
		const {enquiry} = this.props
		console.log(enquiry)
		let fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gmi
		return (
			<React.Fragment>
			<div className='enquiry_body'>
				<ToastContainer />
				<LoadingOverlay active={this.props.loading} spinner styles={{overlay: (base) => ({ ...base, position: 'fixed', })}}>
				
				<div className="row quotation_panel d-flex flex-column">  
					<h4 class="page--title h5 text-white ml-4 pl-4">My Enquiry</h4>
					<ul class="d-flex flex-row">
						<li className="text-white"><Link style={{color:"#CACACA"}} className="ml-4 pl-4" to="/dashboard">Dashboard &nbsp;/ &nbsp;</Link></li>
						<li className="text-white"><Link style={{color:"#CACACA"}} to="/enquiry">My Enquiry &nbsp;/ &nbsp;</Link></li>
						<li className=" "><span style={{color:'white'}}> {enquiry.enquiryId}</span></li>
					</ul>
				</div>
				<div className='pl-4 pr-4 ml-4 mr-4 list_table '>
						
						<div className='card card_shadow'>
							<div class="card-header pl-4 pr-4 ">
							 
				<div  className='mb-4  p-2 mt-2'>
					<div className="row">
						<div class="col-md-6 col-sm-12">
							<table class="table borderless tickets_table">
								<tbody>
									<tr>
										<td>Enquiry Id:</td>
										<th>{enquiry.enquiryId}</th>
									</tr>
									
									<tr>
										<td>Status:</td>
										<th>{enquiry.status}</th>
									</tr>
									<tr>
										<td>Date:</td>
										<th>{moment(enquiry.createdAt).format("DD MMM YYYY hh:mm A")}</th>
									</tr>
								
									<tr>
										<td >Message:</td>
										<th style={{textAlign:"justify",wordWrap: "break-word",maxWidth:"300px" }}>{enquiry.message}</th>
									</tr>
									
									<tr>
										<td>Mobile:</td>
										<th>{enquiry.customerPhone}</th>
									</tr>

									<tr>
										<td>Customer Remarks:</td>
										<th style={{textAlign:"justify",wordWrap: "break-word",maxWidth:"300px" }}>{enquiry.customerRemarks}</th>
									</tr>
									<tr>
										<td>ylx Remarks:</td>
										<th style={{textAlign:"justify",wordWrap: "break-word",maxWidth:"300px" }}>{enquiry.ylxRemarks}</th>
									</tr>
								
								
								</tbody>
							</table>
						</div>
						<div class="col-md-6 col-sm-12">
							<table  class="table borderless tickets_table">
								<tbody>
									
									<tr>
										<td width="200px">Title:</td>
										<th>{enquiry.title}</th>
									</tr>
									<tr>
										<td width="200px">Equipment Model:</td>
										<th>{enquiry.equipmentModel}</th>
									</tr>
									<tr>
										<td width="200px">Equipment Make:</td>
										<th>{enquiry.make}</th>
									</tr>
									<tr>
										<td>Delivery Address:</td>
										<th style={{textAlign:"justify",wordWrap: "break-word",maxWidth:"200px" }}>{enquiry.deliveryAddress == null ? '': `${enquiry.deliveryAddress.addressLine1}, ${enquiry.deliveryAddress.addressLine2=="" ? "" : `${enquiry.deliveryAddress.addressLine2},`} ${enquiry.deliveryAddress.city}, ${enquiry.deliveryAddress.state}, ${enquiry.deliveryAddress.country}, ${enquiry.deliveryAddress.pinCode}`}</th>
									</tr>
									<tr>
										<td>Billing Address:</td>
										<th style={{textAlign:"justify",wordWrap: "break-word",maxWidth:"200px" }}>{enquiry.billingAddress == null ? '':`${enquiry.billingAddress.addressLine1}, ${enquiry.billingAddress.addressLine2=="" ? "" : `${enquiry.billingAddress.addressLine2},`} ${enquiry.billingAddress.city}, ${enquiry.billingAddress.state}, ${enquiry.billingAddress.country}, ${enquiry.billingAddress.pinCode}`}</th>
									</tr>
									
									
								</tbody>
							</table>
						</div>
					</div>
				</div>

				
						{(this.props.enquiry.enquiryItemDto || []).map((item, index)=>{
							return(
								<fieldset className='col-lg-11 ml-4 mb-4 col-sm-12 pb-2 selected_files'  > 
								<legend style={{color: "white"}} className="ml-4">Line Item {index+1}</legend>
									
									<div className="row pt-2">
									<div class="col-md-6 col-sm-12">
							<table class="table borderless tickets_table">
								<tbody>
									<tr>
										<td>Application:</td>
										<th>{item.application}</th>
									</tr>
									<tr>
										<td>Make:</td>
										<th>{item.make}</th>
									</tr>
									<tr>
										<td>Part Name:</td>
										<th>{item.partName}</th>
									</tr>
									<tr>
										<td>Quantity:</td>
										<th>{item.quantity}</th>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="col-md-6 col-sm-12">
							<table  class="table borderless tickets_table">
								<tbody>
									<tr>
										<td>Assembly Model:</td>
										<th>{item.assemblyModel}</th>
									</tr>
									<tr>
										<td width="200px">Brand:</td>
										<th>{item.brand}</th>
									</tr>
									<tr>
										<td width="200px">Part Number:</td>
										<th>{item.partNumber}</th>
									</tr>
									<tr>
										<td width="200px">Manufacturer:</td>
										<th>{item.manufacturer}</th>
									</tr>
									
								</tbody>
							</table>
						</div>
						</div>
						</fieldset>
							)
						}) }	
						

				{this.props.images.length > 0 ?
					<fieldset className='col-lg-11 ml-4 mb-4 col-sm-12 pb-2 selected_files'  > 
							<legend style={{color: "white"}} className="ml-4 mb-4">Files</legend>
						{this.props.images.map(image=>{
							return image['doc_url'].match(fileExtensionPattern)[0] == ".pdf" ?

							
							<a href={image['doc_url']} target="_blank">
								<img  src={Pdf} alt="pdf" width='100px' height='100px' className='mr-3 pr-2' />
							</a>
								:
								image['doc_url'].match(fileExtensionPattern)[0] == ".xlsx" ?
									<a href={image['doc_url']} >
										<img src={Excel} alt="pdf" width='100px' className='mr-3 pr-2'  />
									</a>
									
								
								:

								image['doc_url'].match(fileExtensionPattern)[0] == ".docx" ?

									<a href={image['doc_url']} >
										<img src={Word} alt="pdf" width='100px' className='mr-3 pr-2'  />
									</a>
								
								:
								
									<img src={image['doc_url']}  width='200px' height='200px' className='mr-3 mr-2' onClick={() => this.setState({ lightBoxOpen: true, img: image['doc_url'] })}/>
						
						
								
						}) }	
						
						</fieldset>: ""
				}
				
			
			  {this.state.lightBoxOpen && (
				<Lightbox
				  mainSrc={this.state.img}
				 
				  onCloseRequest={() => this.setState({ lightBoxOpen: false })}
				  
				/>
			  )}
				</div>
				</div>
				</div>			

				</LoadingOverlay>
			</div>
			<div className="dashboard_layout_footer pt-2 mb-4 text-blue" >
					<a className="text-blue ml-4  pl-4"> www.yantralive.com</a>
					<img src={Yantra}  height="60px"></img>
					<a className="text-blue pr-4 mr-4">© 2021 - All Right Reserved</a>
				</div>
			</React.Fragment>
		);
	}
}


const mapStateToProps = (state, ownProps) => {
	console.log(state)
    return {
		id: state.enquiriesSlice.id,
		loading: state.enquiriesSlice.loading,
		enquiry: state.enquiriesSlice.enquiry,
		images: state.enquiriesSlice.images
		
    }
};


export default connect(mapStateToProps)(ViewEnquiry);

