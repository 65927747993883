import { createSlice, createAction} from '@reduxjs/toolkit';


export const quotationsSlice = createSlice({
    name:'orders',
    initialState: {
        loading: false,
        hasErrors: null,
        quotations:[],
        nextPage: false,
        nextPageSize: 0,
        pageNo: 1,
        imageUrl:[],
        totalElements: '',
        visible: false,
        rejectVisible: false,
        reviseSuccess: false
        
    },
    reducers: {

        setquotationsLoading : state => {
            state.loading = true
            state.hasErrors = null
        },

        setPage : (state, action) => {
            state.pageNo = action.payload
        },


        setTotalElements : (state, action) => {
            state.totalElements = action.payload.totalElements
            state.nextPage = action.payload.nextPageFlag
            state.nextPageSize = action.payload.nextSize
        },
     

        getQuotationSuccess : (state, action) => {
            state.quotations = action.payload
            state.nextPage = action.payload.nextPageFlag
            state.nextPageSize = action.payload.nextSize
            state.rejectVisible = false
            state.loading = false
            state.hasErrors = null
        },

        getQuotationFailure : (state, action) => {
            state.loading = false
            state.quotations = []
            state.hasErrors = action.payload
        },

        setVisible : (state, action) => {
            state.visible = action.payload
            state.hasErrors = null
            state.nextPage = false
            state.nextPageSize = 0
        },

        setRejectVisible : (state, action) => {
            state.rejectVisible = action.payload
            state.hasErrors = null
        },

        reviseOkSuccess : (state, payload) => {
            state.loading = false
            state.hasErrors = null
            state.reviseSuccess = true
        },

        reviseOkFailure : (state, action) => {
            state.loading = false
            state.hasErrors = action.payload
        },

        updateSuccess : (state, action) => {
            state.loading = false
            state.hasErrors = null
            state.reviseSuccess = action.payload
        },

        updateFailure: (state, action) => {
            state.loading = false
            state.hasErrors = action.payload
        },

        
    },
 
})

export const { 

    setquotationsLoading, 
    setPage,
    getQuotationSuccess, 
    getQuotationFailure,
    setTotalElements,
    setVisible,
    reviseOkSuccess,
    reviseOkFailure,
    setRejectVisible,
    updateSuccess,
    updateFailure
    

} = quotationsSlice.actions



export default quotationsSlice.reducer;


