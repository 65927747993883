import { createSlice, createAction} from '@reduxjs/toolkit';


export const profileSlice = createSlice({
    name:'profile',
    initialState: {
        loading: false,
        hasErrors: null,
        userInfo: '',
        fullname:'',
        phone:'',
        company:'',
        email:'',
        contact_person_name:'',
        gst:'',
        contact_person_phone:'',
        billing_address:'',
        shipping_address:'',
        shipping_address1: [ ],
        billing_address1: [ ],
        sms:"false",
        whatsapp:"false",
        emailmsg:"false",
        flagdissable:false,
        visible:false,
        newpwd:'',
        confirmpwd:'',
        update_id:'',
        set_id: {id:'', index: ''},
        setBilling_id: {id:'', index: ''},
        deliverySelectedOption: 'noData',
        billingSelectedOption: 'noData',
        delivery_error: {address_line_1:'',address_line_2:'', city:'', state:'',  country:'', pincode:'', name:'', contact:'',gstIn:'',isActive:0, status:"new"},
        billing_error: {address_line_1:'',address_line_2:'', city:'', state:'',  country:'', pincode:'', name:'', contact:'',gstIn:'',isActive:0, status:"new"}
        
    },
    reducers: {

        setProfileLoading : (state, action) => {
            state.loading = action.payload
        },

        setProfileSuccess : (state, action) => {
            state.update_id = action.payload.id
            state.fullname = action.payload.customer
            state.email = action.payload.email
            state.phone = action.payload.phone
            state.company = action.payload.company
            state.contact_person_name = action.payload.contactPerson1
            state.gst = action.payload.gst
            state.contact_person_phone = action.payload.mobile1
            state.hasErrors = null
            state.sms = action.payload.messagePreference != null ? action.payload.messagePreference.sms : (action.payload.phone == "" || action.payload.phone == null) ? false : true
            state.emailmsg = (action.payload.messagePreference != null ) ? (action.payload.email == "" || action.payload.email == null) ? false: action.payload.messagePreference.email : (action.payload.email == "" || action.payload.email == null) ? false : true
            state.whatsapp = (action.payload.messagePreference != null ) ? (action.payload.phone == "" || action.payload.phone == null) ? false: action.payload.messagePreference.whatsApp : (action.payload.phone == "" || action.payload.phone == null) ? false : true
        },

        setProfileFailed : (state, action) => {
            state.loading = false
            state.hasErrors = action.payload
        },


        getAddressSuccess : (state, payload) => {
            state.userInfo = payload
            state.loading = false
            state.hasErrors = null
        },

        getAddressFailure : (state, payload) => {
            state.loading = false
            state.hasErrors = payload
        },

        setAddress : (state, payload) => {
  
            console.log(payload)
            state.loading = false
            state.shipping_address1 = payload.payload.shippingAddress
            state.billing_address1 = payload.payload.billingAddress
            state.deliverySelectedOption = payload.payload.deliverySelectedOption
            state.billingSelectedOption = payload.payload.billingSelectedOption
        },
        
        setAddressFailed : (state, payload) => {
            state.loading = false
            state.hasErrors = payload
        },

        setAddressHandleChange : (state, payload) => {
            let data = payload.payload
            console.log(data)
            state.shipping_address1[data.index][data.name] = data.value
        },

        setBillingAddressHandleChange : (state, payload) => {
            let data = payload.payload
            console.log(data)
            state.billing_address1[data.index][data.name] = data.value
        },

        addShippingAddress : (state, payload) => {
            return { ...state, shipping_address1: [...state.shipping_address1, payload.payload] }
        },

        addBillingAddress : (state, payload) => {
            return { ...state, billing_address1: [...state.billing_address1, payload.payload] }
        },

        removeShippingAddress : (state, action) => {
          
            // if(shipping_address1[set_id.index].status == "new"){
            //     let shipping_address = [...this.state.shipping_address1];
            //     shipping_address.splice(set_id.index,1);
            //     this.setState({ shipping_address1 : shipping_address  });
            // } 

        },

        addShippingAddressSuccess : (state, payload) => {
            state.loading = false
        },

        addBillingAddressSuccess : (state, payload) => {
            state.loading = false
        },


        addShippingAddressFailure : (state, payload) => {
            state.loading = false
            state.hasErrors = payload.payload
        },

        addBillingAddressFailure : (state, payload) => {
            state.loading = false
            state.hasErrors = payload.payload
        },

        setShippingActive: (state, payload) => {
            state.deliverySelectedOption = payload.payload
        },

        setBillingActive: (state, payload) => {
            state.billingSelectedOption = payload.payload
        },

        setHandleChange : (state, action) => {
            debugger
            if(action.payload.name == "phone"){
                if((action.payload.value == "" || action.payload.value == undefined) && state.whatsapp == "true"){
                    state.whatsapp = "false"
                    state[action.payload.name] = action.payload.value
                }else{
                    state[action.payload.name] = action.payload.value
                }
              
                
            }else{
                state[action.payload.name] = action.payload.value
            }
            
        },

        deleteShippingAddress: (state, action) => {
            let shipping_address = [...state.shipping_address1]
			shipping_address.splice(action.payload.index,1)
            state.shipping_address1 = shipping_address
           
        },

        deleteBillingAddress: (state, action) => {
            let billing_address = [...state.billing_address1]
			billing_address.splice(action.payload.index,1)
            state.billing_address1 = billing_address
           
        }


    },
 
})

export const { 

    setProfileLoading, 
    getAddressSuccess,
    getAddressFailure, 
    setAddress,
    setAddressFailed,
    setAddressHandleChange,
    setBillingAddressHandleChange,
    addShippingAddress,
    addBillingAddress,
    addShippingAddressSuccess,
    addBillingAddressSuccess,
    addShippingAddressFailure,
    addBillingAddressFailure,
    setShippingActive,
    setBillingActive,
    setProfileSuccess,
    setHandleChange,
    deleteShippingAddress,
    deleteBillingAddress

} = profileSlice.actions



export default profileSlice.reducer;


