import React, { Component } from 'react';
import api from '../../api/index'
import { Link } from "react-router-dom";
import browserHistory from '../../config/browserHistory'
import LoadingOverlay from 'react-loading-overlay';
import { Badge, Image, Form, Input, Row, Col, Button } from 'antd';
import 'antd/dist/antd.css';
import {connect} from 'react-redux';
import { Select } from 'antd';
import { enquiryFileUpload, getEnquiryById, updateEnquiry, updateEnquiryFileUpload } from './services/enquiry';
import { fetchAddress } from '../profile/services/profile';
import moment from 'moment';
import { Collapse } from 'antd';
import { addLineItems, deleteLineItems, lineItemsHandleChange } from './enquirySlice';
import './createEnquiry.css'
import Pdf from '../../images/pdf.png';
import Excel from '../../images/excel.png';
import Word from '../../images/word.png';
import Yantra from '../../images/yantra.png';
import UserList from '../../images/user-list.png';
import { ToastContainer } from 'react-toastify';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 



const { Panel } = Collapse;

const { Option } = Select;

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
  };
  
const layout1 = {
	labelCol: { span: 10 },
	wrapperCol: { span: 10 },
};
class EditEnquiry extends Component {
	formRef = React.createRef();

	

	fileObj = [];
  	fileArray = [];
	imageArray=[];
	currentUser={};

	state={
		file: [],
		enquiry:'',
		make:'',
		model:'',
		title:'',
		message:'',
		imageUrl:[],
		isLoading:false,
		shippingAddressId: '',
		billingAddressId: '',
		lineItems : [],
		source: '',
		img: []	,
		lightBoxOpen: false,
	}



	async componentWillMount(){
		this.currentUser=JSON.parse(localStorage.getItem("currentUser"))
		let id = this.props.location.state.id
		await this.props.dispatch(fetchAddress(this.currentUser.uid))
		await this.props.dispatch(getEnquiryById(id))
		this.getEnquiry()
	}

	getEnquiry = () => {


		if(this.props.flag == true){
			this.setState({
				enquiry:this.props.enquiry,
				make:this.props.enquiry.make,
				title:this.props.enquiry.title,
				model:this.props.enquiry.equipmentModel,
				message:this.props.enquiry.message,
				imageUrl:this.props.images ? this.props.images : [],
				shippingAddressId: this.props.enquiry.deliveryAddressId,
				billingAddressId: this.props.enquiry.billingAddressId,
				source: this.props.enquiry.source
				
			})

			this.formRef.current.setFieldsValue({ 
				make:this.props.enquiry.make,
				title:this.props.enquiry.title,
				model:this.props.enquiry.equipmentModel,
				message:this.props.enquiry.message,
				shippingAddressId: this.props.enquiry.deliveryAddressId,
				billingAddressId: this.props.enquiry.billingAddressId,
				
			});
		}
		
	}

	menu =  (
		<Select defaultValue="Select shipping Address" name="shippingAddressId" onChange =  {(value) => {
			
			this.setState({shippingAddressId : value})
		}}>
			{this.props.shippingAddress.map((el, index) => (
				<Option value={el.id}>{`${el.addressLine1} ${el.addressLine2} ${el.city} ${el.state} ${el.country} ${el.pinCode}`}</Option>
			))}
		</Select>
		)

	billingAddress = (
		<Select defaultValue="Select Billing Address" name="billingAddressId" onChange = {(val) => {
			
			this.setState({billingAddressId : val})
		}} >
			{this.props.billingAddress.map((el, index) => (
				<Option value={el.id}>{`${el.addressLine1} ${el.addressLine2} ${el.city} ${el.state} ${el.country} ${el.pinCode}`}</Option>
			))}
		</Select>
		)

	fileChange = async(e)=> {
		this.fileObj.push(e.target.files)
		for (let i = 0; i < this.fileObj[0].length; i++) {
			this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
		}
		this.setState({file: this.fileArray})

		const fd = new FormData();
		for (let j = 0; j < e.target.files.length; j++) {
			fd.append('file', e.target.files[j])
		}

	

	

		const {authToken} = this.props.authToken

		api.post(`api/upload`, fd, {
			headers: {
				Authorization: `Bearer ${this.props.authToken}`
			}, 
		}).then(result => {
			let data = JSON.stringify(result.data)
			let result_data = JSON.parse(data)
			console.log(result_data)
			if(result_data.status == true){
			var tt= [];
			let imageresult
			for(let res of result_data.data){
				console.log(res)
				let temp={"doc_url":res.fileUrl}
				tt.push(temp)
				imageresult=tt = tt
			}

			console.log(imageresult)
			this.setState({imageUrl:imageresult})
		}
		}).catch(err => {
			console.log(err)
		})
	}

	handleChange=(e)=>{
		this.setState({[e.target.name]: e.target.value})
	}

	handleSubmit=(e)=>{
		e.preventDefault();
		let date = new Date()
		let data = {
			customerId: this.currentUser.uid,
			enquiryId: this.state.enquiry.enquiryId,
			title: this.state.title,
			status: "Updated",
			month:moment(date).format("MM"),
			year:moment(date).format("YYYY"),
			docs: {"docs": this.state.imageUrl},
			application: null,
			assembly: null,
			assemblyName: null,
			source: this.state.source,
			associateId:'',
			ylaId:'',
			ylxId:'',
			enquiryText:'',
			ylxRemarks:'',
			customerRemarks:'',
			enquiryText:"",
			createdAt: "",
			updatedAt: "",
			make : this.state.make,
			equipmentModel: this.state.model,
			message: this.state.message,
			billingAddressId: this.state.billingAddressId,
			deliveryAddressId: this.state.shippingAddressId,
		}

		console.log(data)

		this.props.dispatch(updateEnquiry(data))

	
	}

	removeImage=(i)=>{
	
		let imageUrl= this.state.imageUrl
		console.log(imageUrl)
		try{
			const updatedImageUrl = imageUrl.filter((item, index) => index !== i)
			console.log(updatedImageUrl)
			this.setState({imageUrl: updatedImageUrl})
		}catch(error){
			console.log(error.message)
		}
	}



	render() {
		let fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gmi
		return (
			<React.Fragment>
			<div className='enquiry_body'>
				<LoadingOverlay active={this.props.loading} spinner styles={{overlay: (base) => ({ ...base, position: 'fixed', })}}>
				<ToastContainer />
				
				<div className="row quotation_panel d-flex flex-column">  
					<h4 class="page--title h5 text-white ml-4 pl-4">Edit Enquiry</h4>
					<ul class="d-flex flex-row">
						<li className="text-white"><Link className="text-white ml-4 pl-4" to="/dashboard">Dashboard &nbsp;/ &nbsp;</Link></li>
						<li className="text-white"><Link className="text-white " to="/enquiry">My enquiry &nbsp;/ &nbsp;</Link></li>
						<li className=" "><span style={{color:'#FDC32C'}}> Edit Enquiry</span></li>
					</ul>
				</div>   

				<div className='pl-4 pr-4 ml-4 mr-4 list_table '>
						
						<div className='card card_shadow'>
							<div class="card-header pl-4 pr-4 ">
							<div className='table_top'>
								<h4 className='table_top_head d-flex flex-row '><div className="icon-top"><img src={UserList} width="20px" height="20px" ></img></div>&emsp;Edit Enquiry</h4>
								<hr className="create__enq__hr"></hr>
								
							</div>
							<Form className="enq_form mt-4 pt-4" ref={this.formRef} >
								<Row >
									<Col span={11} className = 'ml-4'>
										<Form.Item name="model" label="Equipment Model" {...layout} 
											rules={[{ required: true, message: "Please enter equipment model!" }]}
										>
											<Input autocomplete="off" name="model" onChange={this.handleChange} />
										</Form.Item>
									</Col>
									
									<Col span={10} className = 'ml-4'>
										<Form.Item name="title" label="Title" {...layout}
											rules={[{ required: true, message: "Please enter Title!" }]}
										>
											<Input autocomplete="off" name="title" onChange={this.handleChange} />
										</Form.Item>
									</Col> 
									
								</Row>
								<Row >
									
									
									<Col span={11} className = 'ml-4'>
										<Form.Item name="make" label="Make" {...layout}
										rules={[{ required: true, message: "Please enter Make!" }]}>
											<Input autocomplete="off" name="make" onChange={this.handleChange} />
										</Form.Item>
									</Col> 
									<Col span={10} className = 'ml-4'>
										<Form.Item name="message" label="Message" {...layout}
											rules={[{ required: true, message: "Please enter message" }]}
										>
											<Input.TextArea rows={3} autocomplete="off" name="message" onChange={this.handleChange} />
										</Form.Item>
									</Col>
								</Row>
								
								<Row >
									<Col span={11} className = 'ml-4 '>
										<Form.Item name="shippingAddressId" label="Shipping Address" {...layout}
											rules={[{ required: true, message: "Please enter Shipping Address!" }]}
										>
											{this.menu}
										</Form.Item>
									</Col>
									<Col span={10} className = 'ml-4 '>
										<Form.Item name="billingAddressId" label="Billing Address" {...layout}
											rules={[{ required: true, message: "Please enter Billing Address!" }]}
										>
											{this.billingAddress}
										</Form.Item>
									</Col>
								</Row>
								
								<Row >
									<Col span={14} className = 'ml-4 mr-4'>
										<Form.Item name="file" label="Attach Image/Doc/Pdf/Word/xlsx"  {...layout1}>
											<Input type="file" name='file' onChange={this.fileChange}  multiple />
													{/* <label className="custom-file-label"></label> */}
										</Form.Item>
									</Col>
								</Row>
							</Form>
						
							
							<fieldset className='col-lg-12 col-sm-12 mt-4 mb-4 selected_files'  > 
								<legend className="ml-2 mb-2 text-white">Selected Files</legend>
								{(this.state.imageUrl || []).map((url,index) => {
									return url['doc_url'].match(fileExtensionPattern)[0] == ".pdf" ?
									<Badge count={<i className='fa fa-trash bg-danger text-light p-2 rounded-circle' onClick={()=>this.removeImage(index)}/>}
									 className='mr-4 mt-2' >
										<a href={url['doc_url']} target="_blank">  
											<Image src={Pdf} alt="pdf" width='100px' height='100px' className='hoverimage'/>
										</a>
									 </Badge>
									
											:
									url['doc_url'].match(fileExtensionPattern)[0] == ".xlsx" ?
									<Badge count={<i className='fa fa-trash bg-danger text-light p-2 rounded-circle' onClick={()=>this.removeImage(index)}/>}
									 className='mr-4 mt-2' >
										<a href={ url['doc_url']} >  
											<Image src={Excel} alt="Excel" width='100px' height='100px' className='hoverimage'/>
										</a>
									</Badge>
									
									:
	
									url['doc_url'].match(fileExtensionPattern)[0] == ".docx" ?
									<Badge count={<i className='fa fa-trash bg-danger text-light p-2 rounded-circle' onClick={()=>this.removeImage(index)}/>}
									 className='mr-4 mt-2' >
										<a href={ url['doc_url']} >  
											<Image src={Word} alt="doc" width='100px' height='100px' className='hoverimage'/>
										</a>
									 </Badge>
									
									:
									
									<Badge count={<i className='fa fa-trash bg-danger text-light p-2 rounded-circle' onClick={()=>this.removeImage(index)}/>}
									 className='mr-4 mt-2' >
                  						<Image src={url['doc_url']} alt="..." width='100px' height='100px' className='hoverimage' onClick={() => this.setState({ lightBoxOpen: true, img: url['doc_url']})}/>
									</Badge>
								})}

								
							</fieldset>
							<button className='enquiry_sub_btn mt-4 mb-4' onClick={this.handleSubmit}> Save Changes </button> 
							
						
					
							</div>
					</div>	
				</div>
				
				</LoadingOverlay>
				
			</div>
			<div className="dashboard_layout_footer pt-2 mb-4 text-blue" >
				<a className="text-blue ml-4  pl-4"> www.yantralive.com</a>
				<img src={Yantra}  height="60px"></img>
				<a className="text-blue pr-4 mr-4">© 2021 - All Right Reserved</a>
			</div>
			</React.Fragment>
		);
	}
}


const mapStateToProps = (state, ownProps) => {
	console.log(state)
    return {
		uid: state.userSlice.user.payload.uid,
		authToken: state.userSlice.authToken,
		loading: state.enquiriesSlice.loading,
		shippingAddress: state.profileSlice.shipping_address1,
		billingAddress:  state.profileSlice.billing_address1,
		enquiry: state.enquiriesSlice.enquiry,
		images: state.enquiriesSlice.images ,
		flag: state.enquiriesSlice.editEnquiry,
	
    }
};


export default connect(mapStateToProps)(EditEnquiry);
