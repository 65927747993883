import { TramRounded } from '@material-ui/icons';
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name:'user',
    initialState: {
        loading: false,
        hasErrors: null,
        hide: false,
        user: '',
        user_details:'',
        otp_result: {},
        customer: '',
        authToken: ''
    },

    reducers: {

        setInitial: state => {
            state.loading = false
            state.hasErrors = null
        },

        setLoading: state => {
            state.loading = true
        },

        setHide: (state, action) =>{
            state.hide = action.payload
        },

        setHAuthToken: (state, action) =>{
            state.authToken = action.payload
        },

        
        loginSuccess: (state, payload ) => {
            state.user = payload
            state.loading = false
            state.hasErrors = null
        },

        loginFailure: (state, {payload}) => {
            state.hasErrors = payload
            state.loading = false
        },

        googleLoginData: (state, payload) =>{
            state.user = payload
            state.loading = false
            state.hasErrors = null
        },

        googleLoginSuccess: ( state, action ) => {
            state.user_details = action.payload
            state.loading = false
            state.hasErrors = null
        },

        googleLoginFailure: ( state, payload ) => {
            state.hasErrors = payload
            state.loading = false
        },

        registerSuccess: (state, payload ) => {
            state.user_details = payload
            state.loading = false
            state.hasErrors = null
        },

        registerFailure: (state, payload ) => {
            state.hasErrors = payload
            state.loading = false
        },

        otpLoginSuccess: (state, action ) => {
            state.otp_result = action.payload
            state.loading = false
            state.hide = true
            state.hasErrors = null
        },

        otpLoginFailure: (state, payload) => {
            state.hasErrors = payload
            state.loading = false
            state.hide = false
        },

        otpSuccess: (state, action ) => {
            state.user_details = action.payload
            state.loading = false
            state.hasErrors = null
            state.hide= false
            state.otp_result = ''
        },

        otpFailure: (state, action) => {
            state.hasErrors = action.payload
            state.loading = false
            state.hide = true
            state.otp_result = ''
            
        },

        layoutFetchUserSuccess: (state, payload) => {
            state.customer = payload
            state.loading = false
            state.hasErrors = null
        },

        layoutFetchUserFailure: (state, payload) => {
            state.loading = false
            state.hasErrors = payload
        },

       
    },
 
})

export const { 
    setLoading, 
    loginRequest, 
    loginFailure, 
    loginSuccess,
    googleLoginSuccess,
    googleLoginFailure,
    registerSuccess,
    registerFailure,
    otpLoginSuccess,
    otpLoginFailure,
    otpSuccess,
    otpFailure,
    setHide,
    layoutFetchUserSuccess,
    layoutFetchUserFailure,
    googleLoginData,
    setHAuthToken,
    setInitial
} 

= userSlice.actions


export default userSlice.reducer;


