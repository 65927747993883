import React, { Component } from 'react';
import api from '../../api/index'
import browserHistory from '../../config/browserHistory'
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import { fetchEnquiries } from './services/enquiry';
import { setInitialState } from './enquirySlice';
import Yantra from '../../images/yantra.png';
import UserList from '../../images/user-list.png';
import Edit from '../../images/edit1.png';
import View from '../../images/view1.png';
import { Tooltip } from '@material-ui/core';
import LightTooltip from '../../config/tooltip';
import NoData from '../../images/no-data-concept-illustration_114360-536.jpg';



class Enquiry extends Component {
	currentUser={};

	state={
		pageNo:'',
		result:'',
		enquiries:[],
		isLoading:false
	}

	componentWillMount() {
		this.setState({isLoading:true})
		this.currentUser=JSON.parse(localStorage.getItem("currentUser"))
		//this.getEnquiries(1);
		this.props.dispatch(setInitialState())
		this.props.dispatch(fetchEnquiries(1))
	}

	
	pagination=()=>{
		var page = []
		for (let i = 1; i <= Math.ceil(this.props.result/5); i++) {
			page.push(<li className="paginate_button page-item">
				{this.props.pageNo==i?
					<li className="paginate_button page-item active">
						<button aria-controls="dataTable" className="page-link rounded-circle" onClick={() =>this.props.dispatch(fetchEnquiries(i))} >{i}</button> </li>:
				<button aria-controls="dataTable" className="page-link rounded-circle" onClick={() =>this.props.dispatch(fetchEnquiries(i))}>{i}</button>
				}
		</li>)
		}
		return page;
	}

	viewEnquiry = (id) => {
		browserHistory.push({pathname:'/enquiry/view/'+id, state: {id : id}})
	}

	editEnquiry = (id) => {
		browserHistory.push({pathname:'/enquiry/edit/'+id, state: {id : id}})
	}

	onSort=(event, sortKey)=>{
		const data = this.state.enquiries;
		data.sort((a,b) => a[sortKey].localeCompare(b[sortKey]))
		this.setState({enquiries:data})
	}
	
	render() {
		console.log(window.location.pathname == "/dashboard")
		const {enquiries, loading, pageNo, result} = this.props
		console.log(`page is: ${pageNo}, res is:${result}`)
		
		return (
			<React.Fragment>
			
			<div className='enquiry_body'>
				<LoadingOverlay active={loading} spinner styles={{overlay: (base) => ({ ...base, position: 'fixed', })}}>
				<div class="panel row">
					<div className='col'><h4 className='text-white ml-4 pl-4'> All Enquiries</h4>
					<p className='text-white ml-4 pl-4'>Found Total {result} Enquiries </p></div>
					
				</div>

				<div className="pl-4 pr-4 ml-4 mr-4 list_table">
					<div className='card card_shadow'>
						<div class="card-header pl-4 pr-4 ">
							<div className='table_top d-flex flex-row justify-content-between align-middle mb-4'>
								<div className="d-flex flex-column">
									<h4 className='table_top_head d-flex flex-row '><div className="icon-top"><img src={UserList} width="20px" height="20px" ></img></div>&emsp;Enquiries List</h4>
									<p className="pl-4 note_text">&emsp; &emsp;   Note: You can edit your enquiry by clicking on the edit button</p>
								</div>
								<div className='col mt-4'><Link to='/enquiry/create-enquiry' class="btn btn-sm btn-outline-info sb_btn ">+ Add Enquiry</Link></div>
							</div>

							{enquiries.length > 0 ? 
							<div class="table-responsive tables_wrapper">
								<table class="table ">
									<thead className="enquiry_list_th">
										<tr>
											<th scope="col">#</th>
											<th scope="col" >Enquiry ID</th>
											<th scope="col" onClick={e => this.onSort(e, 'equipment_type')}>Make</th>
											<th scope="col" onClick={e => this.onSort(e, 'equipment_model')}>Model</th>
											<th scope="col" onClick={e => this.onSort(e, 'title')}>Title</th>
											<th scope="col" onClick={e => this.onSort(e, 'created_at')}>Created At</th>
											<th scope="col" onClick={e => this.onSort(e, 'updated_at')}>Updated At</th>
											<th scope="col">Status</th>
											<th scope="col">Action</th>
										</tr>
									</thead>
									<tbody className="enquiry_list_tbody">
										{enquiries ? enquiries.map((enquiry,index) => {
											return(<tr role="row" className="odd">
												<td>{(index + (pageNo-1)*5)+1}</td>
												<td>{enquiry.enquiryId}</td>
												<td className="text-truncate" style={{maxWidth: "100px", }}>{enquiry.make}</td>
												<td className="text-truncate" style={{maxWidth: "100px", }}>{enquiry.equipmentModel}</td>
												<td className="text-truncate" style={{maxWidth: "100px", }}>{enquiry.title}</td>
												<td>{moment(enquiry.createdAt).format("DD MMM YYYY hh:mm A")}</td>
												<td>{moment(enquiry.updatedAt).format("DD MMM YYYY hh:mm A")}</td>
												<td>{enquiry.status}</td>	
												<td>
												<LightTooltip title="View Enquiry" placement="top-start">
													<Link className="pr-4" to={{pathname:`/enquiry/view/${enquiry.enquiryId}`, state:{id: enquiry.enquiryId}}}><img src={View} alt="pdf"  height="20px"/></Link>
												</LightTooltip>
													{(enquiry.status == 'New' || enquiry.status == 'Updated')?
													<LightTooltip title="Edit Enquiry" placement="top-start">
														<Link className="" to={{pathname:`/enquiry/edit/${enquiry.enquiryId}`, state:{id: enquiry.enquiryId}}} >
															<img src={Edit} alt="pdf" height="15px"/>
														</Link>
													</LightTooltip>
													:
													<LightTooltip title="Can Edit New/Updated Enquiry Only" placement="top-start">
														<Link className=""><img src={Edit} alt="pdf" height="20px" /></Link>
													</LightTooltip>
												}
												</td>									
											</tr>)
										}):""}	
									</tbody>
								</table>
								<ul className="pagination float-right align-items-center mt-4 mr-4" >
									{pageNo<=1?
										<li className="paginate_button page-item previous disabled " id="dataTable_previous">
											<button className="page-link1 disabled not-allowed">Previous</button>
										</li>:
										<li className="" id="dataTable_previous">
											{/* <button className="page-link" onClick={() =>this.getResturants(this.state.pageNo-1)}>Previous</button> */}
											<button className="page-link1" onClick={() =>this.props.dispatch(fetchEnquiries(pageNo-1))} >Previous</button>
										</li>
									}
									{/* {this.pagination()} */}
									<div className="page-no">{pageNo}</div>
									{pageNo>= Math.ceil(result/5)?
										<li className="paginate_button page-item next disabled" id="dataTable_next">
											<button className="page-link disabled not-allowed" >Next</button>
										</li>:
										<li className=" " id="dataTable_next">
											<button className="page-link" onClick={() =>this.props.dispatch(fetchEnquiries(pageNo+1))}>Next</button>
										</li>
									}
								</ul>
							</div>
							:
							<React.Fragment>
								<hr className="create__enq__hr"></hr>
								<div class="table-responsive d-flex flex-column justify-content-center align-items-center">
										<span className="no_data_text mt-4 mb-2">
										You have not created any enquiries yet, what is holding you back, we are here to help you!
										</span>
										<span className="no_data_text mb-2">
										please go ahead and  click <b>Add Enquiry to submit your part requirement now </b> 
										</span>
										<img src={NoData} height="300px"></img>
								</div>
							</React.Fragment>
							}
						</div>
					</div>
				</div>
				
				</LoadingOverlay>
				
				
				
			</div>
			<div className="dashboard_layout_footer mb-4 text-blue" >
				<a href="https://www.yantralive.com/" target="_blank" className="text-blue ml-4 pl-4"> www.yantralive.com</a>
				<img src={Yantra}  height="60px"></img>
				<a className="text-blue pr-4 mr-4">© 2021 - All Right Reserved</a>
			</div>
			</React.Fragment>
			
		);
	}
}


const mapStateToProps = (state, ownProps) => {
	console.log(state)
    return {
		uid: state.userSlice.user.payload.uid,
		enquiries: state.enquiriesSlice.enquiries ,
		pageNo: state.enquiriesSlice.pageNo.payload ? state.enquiriesSlice.pageNo.payload : 1 ,
		result: state.enquiriesSlice.totalElements ,
		loading: state.enquiriesSlice.loading,
		
    }
};


export default connect(mapStateToProps)(Enquiry);
