import { toast } from "react-toastify"
import storage from "redux-persist/lib/storage"
import api from "../../../api"
import browserHistory from "../../../config/browserHistory"
import fire from "../../../config/fire"
import { getMetrics, getMetricsFailure, getMetricsSuccess } from "../dashboardSlice"


export const fetchMetrics = (id) => async (dispatch, getState) => {
   
    dispatch(getMetrics())

    const {userSlice} = getState()

    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken
  
    try {

        const response = await api.get(`api/dashboard/get/${id}`,{
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
        dispatch(getMetricsSuccess(response.data.data))
    
    } catch (error) {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!", {position: "top-center"})
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }
        }else{
            
            dispatch(getMetricsFailure(error.message))
            toast.error(error.message, {position: "top-center"})
        }
        
    }
}

