import { toast } from "react-toastify"
import api from "../../../api"
import browserHistory from "../../../config/browserHistory"
import fire from "../../../config/fire"
import {setUpdateEnquiryImageUrl, unsetUpdateEnquiryImageUrl, setEnquiryImageUrl, unsetEnquiryImageUrl, createEnquirySuccess,createEnquiryFailure, getEnquiryFailure, getEnquirySuccess, setEnquiriesLoading, setPage, updateEnquirySuccess, updateEnquiryFailure, getEnquiryByIdFailed, getEnquiryByIdSuccess } from "../enquirySlice"
import storage from "redux-persist/lib/storage"

export const fetchEnquiries = (payload) => async (dispatch, getState) => {

    const {userSlice} = getState()

    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken

    console.log(uid)
   
    dispatch(setEnquiriesLoading())
  
    try {
        // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
        // console.log(token)
        debugger

        const response = await api.get(`api/cj/enquiry/customer?`, {
            params: {
                customerId: uid,
				pageNo: payload,
                pageSize:5,
                sortBy:'id',

             },
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
            
        },)
        let result = JSON.stringify(response.data)
        let res_data = JSON.parse(result)
        
        console.log(res_data)

        if(res_data.status == true){
            dispatch(getEnquirySuccess(res_data.data))
            dispatch(setPage(payload))
        }else {
            toast.error(res_data.error.message, {position: "top-center"})
            dispatch(getEnquiryFailure(res_data.error.message))
        }

    } catch (error) {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!", {position: "top-center"})
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }
        }
        else{
        dispatch(getEnquiryFailure(error.message))
        toast.error(error.message, {position: "top-center"})
        }
    }
}


export const createEnquiry = (payload) => async (dispatch, getState) => {

   
    dispatch(setEnquiriesLoading())

    const {userSlice} = getState()

    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken
  
    try {

        // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
        // console.log(token)


        const response = await api.post('api/cj/enquiry/add', payload , {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        } )
        let result = JSON.stringify(response.data)
        let res_data = JSON.parse(result)
        
        console.log(res_data)

        if(res_data.status == true){
            dispatch(createEnquirySuccess())
            toast.success("Your Enquiry has been Submitted Successfully", {position: "top-center"})
            browserHistory.push('/enquiry')
        }else {
            toast.error(res_data.error.message, {position: "top-center"})
            dispatch(createEnquiryFailure(res_data.error.message))
        }

      
    
    } catch (error) {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!")
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }
        }else{
        dispatch(createEnquiryFailure(error.message))
        toast.error("Failed to Add, Please try again later!!", {position: "top-center"})
        }
    }
}


export const enquiryFileUpload = (payload) => async (dispatch, getState) =>  {

    
    
    let imageresult

    const {userSlice} = getState()

    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken

    // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
    // console.log(token)

    api.post(`api/upload`, payload, {
        headers: {
            'Authorization': `Bearer ${authToken}`
        }
    })

    .then(result => {
        
        let data = JSON.stringify(result.data)
        let result_data = JSON.parse(data).data
        console.log(result_data)
        var tt=[];
        for(let res of result_data){
            let temp={"doc_url":res.fileUrl}
            tt.push(temp)
            imageresult=tt
        }
        console.log(imageresult)
        dispatch(setEnquiryImageUrl(imageresult))

    }).catch(error => {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!", {position: "top-center"})
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }
        }else{
        toast.error(error.message, {position: "top-center"})
        dispatch(unsetEnquiryImageUrl(error.message))
        }
    })

}


export const updateEnquiry = (payload) => async (dispatch, getState) => {

   
    dispatch(setEnquiriesLoading())

    console.log(payload)

    const {userSlice} = getState()

    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken

  
    try {

        // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
        // console.log(token)

        const response = await api.post('api/enquiry/update', payload, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        } )
        dispatch(updateEnquirySuccess())
        browserHistory.push('/enquiry')
    
    } catch (error) {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!", {position: "top-center"})
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }
        }else{
            dispatch(updateEnquiryFailure(error.message))
            toast.error(error.message, {position: "top-center"})
        }
        
    }
}


export const getEnquiryById = (payload) => async (dispatch, getState) => {

   debugger
    // dispatch(setEnquiriesLoading())

    const {userSlice} = getState()

    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken
  
    try {

        // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
        // console.log(token)

        const response = await api.get(`api/enquiry/${payload}`, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
        
        console.log(response.data)

        let result = JSON.stringify(response.data)
        let res_data = JSON.parse(result)

        console.log(res_data)

        if(res_data.status == true){
            dispatch(getEnquiryByIdSuccess(res_data.data))
        }else{
            toast.error(res_data.error.message, {position: "top-center"})
            dispatch(getEnquiryByIdFailed(res_data.error.message))
        }

    } catch (error) {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!", {position: "top-center"})
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }else{
                toast.error(error.response.data.error.message, {position: "top-center"})
                dispatch(getEnquiryByIdFailed())
            }
        }else{
            dispatch(getEnquiryByIdFailed())
            toast.error(error.message, {position: "top-center"})
        }
    }
}



