import { createSlice, createAction} from '@reduxjs/toolkit';


export const ticketsSlice = createSlice({
    name:'tickets',
    initialState: {
        loading: false,
        hasErrors: null,
        tickets:[],
        imageUrl: [],
    },
    reducers: {
        setInitial: state => {
            state.hasErrors = null
        },

        setTicketsLoading : state => {
            state.loading = true
        },
        
        getTicketsSuccess : (state, action) => {
            state.tickets = action.payload
            state.loading = false
            state.hasErrors = null
        },

        getTicketsFailure : (state, action) => {
            state.loading = false
            state.hasErrors = action.payload
        },

        createTicketSuccess: (state, action) => {
            state.loading = false
            state.hasErrors = null
            state.imageUrl = []
            
        },

        createTicketFailure : (state, action) => {
            state.loading = false
            state.hasErrors = action.payload
            state.imageUrl = []
        },

        setImageUrl: (state, action) => {
            state.imageUrl = action.payload
        }, 

        unsetImageUrl : (state) => {
            state.imageUrl = []
        }

        
    },
 
})

export const { 

    setTicketsLoading, 
    getTicketsSuccess,
    getTicketsFailure, 
    createTicketSuccess,
    createTicketFailure,
    setImageUrl,
    unsetImageUrl,
    setInitial

} = ticketsSlice.actions



export default ticketsSlice.reducer;


