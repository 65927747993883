import React, { Component } from 'react';
import api from '../../api/index'
import browserHistory from '../../config/browserHistory'
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import {connect} from 'react-redux';
import { fetchTickets } from './services/tickets';
import { ToastContainer } from 'react-toastify';
import { setInitial } from './ticketsSlice';
import { Link } from "react-router-dom";
import Yantra from '../../images/yantra.png';
import TicketIcon from '../../images/tickets.png';
import View from '../../images/view1.png';
import NoData from '../../images/no-data-concept-illustration_114360-536.jpg';
import LightTooltip from '../../config/tooltip';



class Ticket extends Component {
  currentUser={};

	state={
		pageNo:'',
		tickets:[],
    isloading:false,
  }

  async componentWillMount() {
		this.setState({isloading:true})
    this.currentUser=JSON.parse(localStorage.getItem("currentUser"))
    this.props.dispatch(setInitial())
    await this.props.dispatch(fetchTickets())
    //this.getTickets();
  }
  


  viewTicket=(ticket)=>{
    browserHistory.push({pathname:'/ticket/view/'+ticket.id, state: {ticket : ticket}})
  }

  
  render() {
    const {tickets} = this.props
    
    return (
      <React.Fragment>
      <div className='enquiry_body' >
        <LoadingOverlay active={this.props.loading} spinner styles={{overlay: (base) => ({ ...base, position: 'fixed', })}}>
        <ToastContainer />
        
          <div className="row quotation_panel d-flex flex-column">  
            <h4 class="page--title h5 text-white ml-4 pl-4">My Tickets</h4>
            <ul class="d-flex flex-row">
              <li className="text-white"><Link style={{color:'#CACACA'}} className=" ml-4 pl-4" to="/dashboard">Dashboard &nbsp;/ &nbsp;</Link></li>
              <li className="text-white "><span >My Tickets</span></li>
            </ul>
          </div>    

          <div className="pl-4 pr-4 ml-4 mr-4  list_table">
            <div className='card card_shadow'>
              <div class="card-header pl-4 pr-4 ">
               
                <div className='table_top'>
                  <h4 className='table_top_head d-flex flex-row '><div className="icon-top"><img src={TicketIcon} width="20px" height="20px" ></img></div>&emsp;Ticket List</h4>
                  
                  <p className='pl-4 note_text'>&emsp; &emsp;   Found Total {tickets.length ? tickets.length : '0' } Tickets</p>
                </div>
                {tickets.length > 0 ? 
                <div class="table-responsive">
                   <table class="table ">
                    <thead className="enquiry_list_th">
                      <tr>
                       <th scope="col">ticketNumber</th> 
                        <th scope="col">Subject</th>
                        <th scope="col" >Created Time</th>
                        <th scope="col" >Updated Time</th>
                        <th scope="col" >Status</th>
                        <th scope="col" className='text-center'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    {(tickets || []).map(ticket => {
										return(<tr role="row" className="odd">
											<td>{ticket.zohoTicketDetails != null? ticket.zohoTicketDetails.ticketNumber : '' }</td> 
                      <td className=" text-truncate" style={{maxWidth: "100px", }}>{ticket.zohoTicketDetails != null ? ticket.zohoTicketDetails.subject : ''}</td>
                      <td>{ticket.createdAt}</td>
                      <td>{moment(ticket.updatedAt).format("DD MMM YYYY hh:mm A")}</td>
                      <td>{ticket.zohoTicketDetails != null ? ticket.zohoTicketDetails.status: ''}</td>
											<td className='text-center'>
                        <LightTooltip title="View Ticket" placement="top-start">
                          <Link className="pr-4" to={{pathname:`/ticket/view/${ticket.id}`, state: {ticket : ticket}}}><img src={View} alt="pdf"  height="20px"/></Link>
                        </LightTooltip>
                        
												{/* <span className='border rounded-circle p-2' onClick={()=>this.downloadQuatation(order.pdf_url)}><i class="fas fa-download rounded-circle" ></i></span> */}
								 		</td>				
										</tr> )
										} ) }		
                    </tbody>  
                  </table> 
                </div> 
                :
                <React.Fragment>
                <hr className="create__enq__hr"></hr>
                <div class="mt-4 table-responsive d-flex flex-column justify-content-center align-items-center">
                   
                    <span className="no_data_text mt-4 mb-2">
                      You have not created any tickets.  
                    </span>
                    <img src={NoData} height="300px"></img>
                    
  
                </div>
                </React.Fragment>
                }
              

              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
      <div className="dashboard_layout_footer mb-4 text-blue" >
				<a className="text-blue ml-4  pl-4"> www.yantralive.com</a>
				<img src={Yantra}  height="60px"></img>
				<a className="text-blue pr-4 mr-4">© 2021 - All Right Reserved</a>
			</div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
	console.log(state)
    return {
      uid: state.userSlice.user.payload.uid,
      tickets:state.ticketsSlice.tickets,
      loading: state.ticketsSlice.loading,
      
    }
};


export default connect(mapStateToProps)(Ticket);

