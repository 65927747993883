import  dashboardSlice  from '../features/dashboard/dashboardSlice';
import  userSlice from '../features/auth/userSlice';
import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist'
import AsyncStorage from 'redux-persist/lib/storage'
import ordersSlice from '../features/orders/ordersSlice';
import  fleetsSlice from '../features/fleets/fleetsSlice';
import  ticketsSlice  from '../features/tickets/ticketsSlice';
import enquiriesSlice  from '../features/enquiries/enquirySlice';
import profileSlice from '../features/profile/profileSlice';
import quotationsSlice from '../features/quotations/quotationsSlice';

const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
    whitelist: [
        'dashboardSlice', 
        'userSlice', 
        'ordersSlice', 
        'fleetsSlice',
        'ticketsSlice',
        'enquiriesSlice',
        'profileSlice',
        'quotationsSlice'
,    ]
}


const rootReducer = combineReducers({
    dashboardSlice: dashboardSlice,
    userSlice: userSlice,
    ordersSlice: ordersSlice,
    fleetsSlice: fleetsSlice,
    ticketsSlice: ticketsSlice,
    enquiriesSlice: enquiriesSlice,
    profileSlice: profileSlice,
    quotationsSlice: quotationsSlice,
});


const persistedReducer = persistReducer(persistConfig, rootReducer);

export default  persistedReducer


