import { toast } from "react-toastify"
import api from "../../../api"
import browserHistory from "../../../config/browserHistory"
import fire from "../../../config/fire"
import { setOrdersLoading, unSetOrdersLoading } from "../../orders/ordersSlice"
import { createTicketFailure, createTicketSuccess, getTicketsFailure, getTicketsSuccess, setImageUrl, setTicketsLoading, unsetImageUrl } from "../ticketsSlice"
import storage from "redux-persist/lib/storage"


export const fetchTickets = () => async (dispatch, getState) => {

    const {userSlice} = getState()

    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken

    console.log(uid)
   
    dispatch(setTicketsLoading())
  
    try {

        const response = await api.get(`zoho/ticket/select/order/${uid}`, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
        
        console.log(response.data)
        let res = JSON.stringify(response.data)
        let res_data = JSON.parse(res)

        console.log(res_data.data)

        if(res_data.status == false) {
            dispatch(getTicketsSuccess(res_data.data))
        }else{
            //toast.error(res_data.error.message)
            console.log(res_data.error.message)
            dispatch(getTicketsFailure(res_data.error.message))
            
        }

    } catch (error) {
        //toast.error(error.message)
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!")
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }
        }else{
        console.log(error.message)
        dispatch(getTicketsFailure(error.message))
        }
    }
}


export const createTicket = (payload) => async (dispatch, getState) => {
    debugger

    const {userSlice} = getState()

    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken

    console.log(uid)
   
    dispatch(setOrdersLoading())
  
    try {
        // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
        // console.log(token)

        const response = await api.post(`zoho/ticket/create`, payload, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        } )
        
        console.log(response.data)
        let res = JSON.stringify(response.data)
        let res_data = JSON.parse(res)

        console.log(res_data)

        if(res_data.status == true) {
            dispatch(createTicketSuccess())
            browserHistory.push('/tickets')
        }else{
            if(res_data.data == "Customer Email Not found"){
                toast.error("Could not create ticket,Please add your Email Id in profile page!!")
                dispatch(unSetOrdersLoading())
                dispatch(createTicketFailure())
            }else{
                dispatch(unSetOrdersLoading())
                dispatch(createTicketFailure(res_data.error.message))
                toast.error(res_data.error.message)  
            }
             
        }
    
    } catch (error) {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!")
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }else {
                toast.error("Could not create ticket,Please add your Email Id in profile page!!")
                dispatch(unSetOrdersLoading())
            }
        }else{

            toast.error(error.message)
            dispatch(createTicketFailure(error.message))
            dispatch(unSetOrdersLoading())
        }
    }
}


export const fileUpload = (payload) => async (dispatch, getState) =>  {
    
    let imageresult

    const {userSlice} = getState()

    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken

    // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
    // console.log(token)

    api.post(`api/upload`, payload, {
        headers: {
            'Authorization': `Bearer ${authToken}`
        }
    })

    .then(result => {

        let data = JSON.stringify(result.data)
        let result_data = JSON.parse(data)
        console.log(result_data)

        if(result_data.status == true){
            var tt=[];
            for(let res of (result_data.data)){
                let temp={"fileUrl": res.fileUrl}
                tt.push(temp)
                imageresult=tt
            }
            console.log(imageresult)
            dispatch(setImageUrl(imageresult))
        }else{
            toast.error(result_data.error.message)
            dispatch(unsetImageUrl(imageresult))
            
        }
       
        
    }).catch(error => {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!")
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }
        }else{
            toast.error(error.message)
            dispatch(unsetImageUrl(imageresult))
        }
    })

}


