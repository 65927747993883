import React, { Component } from 'react';
import api from '../../api/index'
import browserHistory from '../../config/browserHistory'
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import { Modal, Button } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import { Form, Input, Row, Col  } from "antd";
import {connect} from 'react-redux';
import { fetchOrders } from './services/orders';
import { createTicket, fileUpload } from '../tickets/services/tickets';
import { unsetImageUrl } from '../tickets/ticketsSlice';
import { setInitial, setOrdersLoading, unSetOrdersLoading } from './ordersSlice';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import "react-step-progress-bar/styles.css";
import './orders.css';
import Pdf from '../../images/pdf.png';
import Excel from '../../images/excel.png';
import Word from '../../images/word.png';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import { Link } from "react-router-dom";
import Cart from '../../images/shopping-cart.png';
import Yantra from '../../images/yantra.png';
import LightTooltip from '../../config/tooltip';
import TicketIcon from '../../images/tickets.png';
import Download from '../../images/direct-download (1).png';
import NoData from '../../images/no-data-concept-illustration_114360-536.jpg';
import View from '../../images/view1.png';


const layout = {
	labelCol: { span: 6},
	wrapperCol: { span: 18 },
  };

const { TextArea } = Input

class Orders extends Component {
	ticketFormRef = React.createRef();
	
	currentUser={};
	fileObj = [];
  fileArray = [];
  imageArray=[];
	imageName=[];

	state={
		pageNo:'',
		result:'',
		orders:[],
		isloading:false,
		visible:false,
		desc:'',
		subject:'',
		zoho_order_no:'',
		order_id: '',
		file: [],
		imageUrl:[],
		lightBoxOpen: false,
		img: []
	}

	

	componentWillMount() {
		this.setState({isloading:true})
		this.currentUser = this.props.uid
		this.props.dispatch(setInitial())
		this.props.dispatch(fetchOrders(1));
	}

	

	pagination=()=>{
		var page = []
		for (let i = 1; i <= Math.ceil(this.props.result/5); i++) {
			page.push(<li className="paginate_button page-item">
				{this.props.pageNo==i?
					<li className="paginate_button page-item active">
						<button aria-controls="dataTable" className="page-link rounded-circle" onClick={() =>this.props.dispatch(fetchOrders(i))} >{i}</button> </li>:
				<button aria-controls="dataTable" className="page-link rounded-circle" onClick={() =>this.props.dispatch(fetchOrders(i))}>{i}</button>
				}
		</li>)
		}
		return page;
	}

	downloadQuatation=(url)=>{
		window.window.open(url,'_blank');
	}
	
	onSort=(event, sortKey)=>{
		const data = this.state.orders;
		data.sort((a,b) => a[sortKey].localeCompare(b[sortKey]))
		this.setState({orders:data})
	}

	checkCreateTicket = (index, date, zohoOrderNo, orderId) => {
		
		if(date != null){
			let final_date = moment(date)
			let today = new Date();
			today = moment(today)
		
			let days = today.diff(final_date, 'days')
			console.log(`days: ${days}`)
			if(days <= 3){
				this.setState({
					zoho_order_no: zohoOrderNo,
					order_id: orderId,
					visible: true
				})
			}else{
				
				toast.error('Cannot create ticket, Days exceeded !!')
			}
		}else {
			this.setState({
				zoho_order_no: zohoOrderNo,
				order_id: orderId,
				visible: true
			})
		}
		
	}

	createTicket= async()=>{
		

		try{
			const value = await this.ticketFormRef.current.validateFields();
			let data={
				subject:this.state.subject,
				description:this.state.desc,
				customerId:this.currentUser,
				orderNo:this.state.zoho_order_no,
				orderId: this.state.order_id,
				ticketAttachment: {"attachments": this.props.imageUrl} 
			}
	
	
			console.log(data)
			this.props.dispatch(createTicket(data))
			this.setState({sub:'',desc:'',imageUrl: [], visible: false,})
			

		}catch (errorInfo) {
			console.log("failure", errorInfo);
			toast.info("Invalid form entry..!", {position: "top-center"})
    	}
		
		
	
	}

	fileChange = async(e)=> {
		this.props.dispatch(setOrdersLoading())
	
		this.fileObj.push(e.target.files)
		for (let i = 0; i < this.fileObj[0].length; i++) {
			this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
		}
		this.setState({file: this.fileArray})
		const fd = new FormData();
    	for (let j = 0; j < e.target.files.length; j++) {
		fd.append('file', e.target.files[j])
		}
		
		await this.props.dispatch(fileUpload(fd))
		this.props.dispatch(unSetOrdersLoading())
	}

	render() {
		let fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gmi
		const {orders, pageNo, result, loading } = this.props
		console.log(`res: ${pageNo}`)
		console.log(orders)
		return (
			<React.Fragment>
			<div className='enquiry_body'>
				<ToastContainer />
				<LoadingOverlay active={loading} spinner styles={{overlay: (base) => ({ ...base, position: 'fixed', })}}>
				
				<div className="row quotation_panel d-flex flex-column">  
					<h4 class="page--title h5 text-white ml-4 pl-4">My Orders</h4>
					<ul class="d-flex flex-row">
						<li className="text-white"><Link style={{color:'#CACACA'}} className=" ml-4 pl-4" to="/dashboard">Dashboard &nbsp;/ &nbsp;</Link></li>
						<li className="text-white "><span >My orders</span></li>
					</ul>
				</div>    
		
				<div className="pl-4 pr-4 ml-4 mr-4  list_table">
					<div className='card card_shadow'>
						<div class="card-header pl-4 pr-4 ">
							
							<div className='table_top'>
								<h4 className='table_top_head d-flex flex-row '><div className="icon-top"><img src={Cart} width="20px" height="20px" ></img></div>&emsp;Order List</h4>
								
								<p className='pl-4 note_text'>&emsp; &emsp;   Found Total {result ? result : '0' } Orders</p>
							</div>
							{orders.length > 0 ? 
							<div class="table-responsive">
								<table class="table ">
									<thead className="enquiry_list_th">
										<tr>
											<th scope="col">Order No</th>
											<th scope="col">Quatation No</th>
											{/* <th scope="col" onClick={e => this.onSort(e, 'enquiry_id')}>Enquiry Id</th> */}
											<th scope="col" onClick={e => this.onSort(e, 'title')}>Title</th>
											<th scope="col" >Amount</th>
											<th scope="col" onClick={e => this.onSort(e, 'status')}>Status</th>
											<th scope="col" onClick={e => this.onSort(e, 'status')}>Package Status</th>
											<th scope="col" onClick={e => this.onSort(e, 'created_at')}>Created At</th>
											<th scope="col" onClick={e => this.onSort(e, 'created_at')}>Updated At</th>
											<th scope="col" className='text-center'>Download</th>
											<th scope="col" className='text-center'>Action</th>
										</tr>
									</thead>
									<tbody className="enquiry_list_tbody">
									{orders? orders.map((order, index) => {
										return(
											<React.Fragment>
											<tr role="row" className="odd">

												<td className="align-middle" style={{maxWidth: "120px", textDecoration:"underline", cursor:"pointer", color:"#0D4A61" }} >
													<Link style={{color:"#0D4A61" }} className="mr-2  text-center" to={{pathname:`/order/view`, state: {order : order}}}>{order.zohoOrderNo} </Link>
												</td>
												<td className="align-middle"  style={{maxWidth: "140px", }}>{order.quotationId}</td>
												{/* <td>{order.enquiry_id}</td> */}
												<td className="align-middle text-truncate" style={{maxWidth: "100px", }}>{order.title}</td>
												<td className="align-middle">Rs {order.amount}/-</td>
												<td className="align-middle">{order.status}</td>
												<td className="align-middle">{order.packageStatus}</td>
												<td className="align-middle">{(order.createdAt)}</td>
												<td className="align-middle">{(order.updatedAt)}</td>
												<td className='align-middle text-center'>
													<span className='' style={{cursor: "pointer"}} onClick={()=>this.downloadQuatation(order.pdfUrl)}><img src={Download} height="22px"/></span>
												</td>		
												<td  className='align-middle text-center' style={{minWidth: "120px", }}>
													<LightTooltip title="View Order" placement="top-start" className="d-inline">
														<Link style={{cursor: "pointer", }}  className="mr-3" to={{pathname:`/order/view`, state: {order : order}}}>
															<img src={View} height="22px" />
														</Link>
													</LightTooltip>	
													<LightTooltip title="Create Ticket" placement="top-start" className="d-inline">
														<div style={{cursor: "pointer"}} id={`createTicket${index}`} className="" onClick={()=>this.checkCreateTicket(index, order.deliverDate,order.zohoOrderNo,order.orderId)}>
															<img src={TicketIcon} height="18px"/>
														</div>
													</LightTooltip>
												</td>	
											</tr>
											
											</React.Fragment>
											
										
										)
										}): "" }		
									</tbody>
								</table>

								<ul className="pagination float-right align-items-center mt-4 mr-4">
									{pageNo<=1?
										<li className="paginate_button page-item previous disabled" id="dataTable_previous">
											<button className="page-link1 disabled not-allowed">Previous</button>
										</li>:
										<li className="paginate_button page-item previous" id="dataTable_previous">
											{/* <button className="page-link" onClick={() =>this.getResturants(this.state.pageNo-1)}>Previous</button> */}
											<button className="page-link1" onClick={() =>this.props.dispatch(fetchOrders(pageNo-1))} >Previous</button>
										</li>
									}
									<div className="page-no">{pageNo}</div>
									{pageNo>= Math.floor(result/5)?
										<li className="paginate_button page-item next disabled" id="dataTable_next">
											<button className="page-link disabled not-allowed" >Next</button>
										</li>:
										<li className="paginate_button page-item next " id="dataTable_next">
											<button className="page-link" onClick={() =>this.props.dispatch(fetchOrders(pageNo+1))}>Next</button>
										</li>
									}
								</ul> 
							</div>

							:
							<React.Fragment>
								<hr className="create__enq__hr"></hr>
								<div class="table-responsive d-flex flex-column justify-content-center align-items-center">
										<span className="no_data_text mt-4  mb-2">
											You have not placed any orders yet, 
										</span>
										<span className="no_data_text mb-2">
											Please check your quotations  or create new enquiry to  place your first order with Yantralive. 
										</span>
										<img src={NoData} height="300px"></img>

								</div>
							</React.Fragment>
							}

							<Modal
								visible={this.state.visible}
								title={<p style={{color:"#0D4A61", fontWeight:"bold", fontSize:"18px"}}>Create New Ticket</p>}
								onCancel={() => {
									
									this.setState({visible:false,subject:'',desc:'', file: []})
									this.props.dispatch(unsetImageUrl())
									}
								}
								footer={<button style={{color:"white", backgroundColor:"#FDC32C", border:"none", outline:"none", cursor:"Pointer"}} className="mr-1 mb-2  pl-4 pr-4 pt-2 pb-2" onClick={this.createTicket}>Create</button>}
							  >
							  <Form className="enq_form pt-2" ref={this.ticketFormRef} >
							  		<Row>
									  	<Col span={24} className = ''>				
											<Form.Item name="subject" label="Subject:" {...layout}
												rules={[{ required: true, message: "Please Enter Subject!" }]}
											>
												<Input autocomplete="off" name="subject" onChange={(e)=>this.setState({subject:e.target.value})}  />
											</Form.Item>
										</Col>
									</Row>
									<Row>
										<Col span={24} className = ''>	
											<Form.Item name="desc" label="Description:" {...layout}
												rules={[{ required: true, message: "Please Enter Description!" }]}
											>
												<TextArea autocomplete="off" name="desc" rows={4} onChange={(e)=>this.setState({desc:e.target.value})} placeholder="Please describe the nature of the problem and attach photos for faster resolution" />
											</Form.Item>
										</Col>
									</Row>
									<Row>
										<Col span={24} className = ''>	
											<Form.Item name="file" label="Attach Image/Pdf/xlsx/doc" >
												<Input type="file" name='file' onChange={this.fileChange}  multiple />
														{/* <label className="custom-file-label"></label> */}
											</Form.Item>
										</Col>
									</Row>
									
								</Form>
									{/* <label className="custom-file-label"></label> */}
									{this.state.file.length > 0 ?<fieldset className='mt-4' > 

										<legend>Selected Files</legend>
										<LoadingOverlay active={loading} spinner styles={{overlay: (base) => ({ ...base, position: 'fixed', })}}>
									{(this.props.imageUrl || []).map(url => {

										return url.fileUrl.match(fileExtensionPattern)[0] == ".pdf" ?
											<img src={Pdf} alt="pdf" width='100px' className='mr-3' />
													:
											url.fileUrl.match(fileExtensionPattern)[0] == ".xlsx" ?
											<img src={Excel} alt="pdf" width='100px' className='mr-3' />
											:

											url.fileUrl.match(fileExtensionPattern)[0] == ".docx" ?
											<img src={Word} alt="doc" width='100px' className='mr-3' />
											:
						
											<img src={url.fileUrl} alt="..." width='100px' className='mr-3'  />

										
									})}
									</LoadingOverlay>
								</fieldset>:''}
							</Modal>

						</div>
					</div>
				</div>
				</LoadingOverlay>
				{this.state.lightBoxOpen && (
					<Lightbox
					  mainSrc={this.state.img}
					 
					  onCloseRequest={() => this.setState({ lightBoxOpen: false })}
					  
					/>
				  )}
			</div>
			<div className="dashboard_layout_footer mb-4 text-blue" >
				<a href="https://www.yantralive.com/" target="_blank" className="text-blue ml-4  pl-4"> www.yantralive.com</a>
				<img src={Yantra}  height="60px"></img>
				<a className="text-blue pr-4 mr-4">© 2021 - All Right Reserved</a>
			</div>
			</React.Fragment>
		);
	}
}


const mapStateToProps = (state, ownProps) => {
	console.log(state)
    return {
		uid: state.userSlice.user.payload.uid,
		orders: state.ordersSlice.orders,
		pageNo: state.ordersSlice.pageNo.payload ? state.ordersSlice.pageNo.payload : 1 ,
		result: state.ordersSlice.totalElements,
		loading: state.ordersSlice.loading,
		imageUrl: state.ticketsSlice.imageUrl
    }
};


export default connect(mapStateToProps)(Orders);

