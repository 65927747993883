import { createSlice, createAction} from '@reduxjs/toolkit';


export const enquiriesSlice = createSlice({
    name:'enquiries',
    initialState: {
        loading: false,
        hasErrors: null,
        enquiries:[],
        totalElements:'',
        pageNo: 1,
        imageUrl:[],
        enquiry:'',
        images:[],
        updateImages : [],
        id:'',
        editEnquiry: false,
        imageUpload: false,
        lineItems: [],
    },

    reducers: {

        setInitialState: (state) => {
            state.lineItems = []
            state.hasErrors = null
            state.imageUrl = []
            state.images = []
            state.enquiry = ''
        },

        setId : (state, action) => {
            state.id = action.payload
        },

        setEnquiriesLoading : state => {
            state.loading = true
        },

        setPage : (state, action) => {
            state.pageNo = action
        },

        getEnquirySuccess : (state, action) => {
            state.enquiries = action.payload.enquiryDtos
            state.totalElements = action.payload.totalElements
            state.loading = false
            state.hasErrors = null
        },

        getEnquiryFailure : (state, payload) => {
            state.loading = false
            state.hasErrors = payload
        },

        createEnquirySuccess : (state, payload) => {
            state.loading = false
            state.hasErrors = null
            state.lineItems = []
        },

        createEnquiryFailure : (state, payload) => {
            state.loading = false
            state.hasErrors = payload
        },

        setEnquiryImageUrl: (state, action) => {
            state.imageUrl = action.payload
            state.imageUpload = true
            state.loading = false
        }, 

        setUpdateEnquiryImageUrl: (state, payload) => {
            state.updateImages = payload.payload
            state.imageUpload = true
        }, 

        unsetUpdateEnquiryImageUrl : (state, payload) => {
            state.updateImages = []
            state.hasErrors = payload
            state.imageUpload = false
            state.loading = false
        },

        unsetEnquiryImageUrl : (state, payload) => {
            state.imageUrl = []
            state.hasErrors = payload
            state.imageUpload = false
            state.loading = false
        },

        updateEnquirySuccess: (state, payload) => {
            state.loading = false
            state.hasErrors = null
        },

        updateEnquiryFailure: (state, payload) => {
            state.loading = false
            state.hasErrors = payload
        },

        getEnquiryByIdSuccess: (state, action) => {
            state.enquiry = action.payload
            state.images =  action.payload.docs!=null ? action.payload.docs.docs : []
            state.editEnquiry = true
            state.loading = false
        },

        getEnquiryByIdFailed: (state) => {
           
            state.loading = false
            state.editEnquiry = false
        },

        addLineItems : (state, action) => {
           
            let lineItems = [...state.lineItems]
            lineItems.push(action.payload)
            console.log(lineItems)
            state.lineItems = lineItems
        },

        deleteLineItems: (state, action) => {
            let lineItems = [...state.lineItems]
			lineItems.splice(action.payload,1)
            state.lineItems = lineItems
           
        },

        lineItemsHandleChange : (state, action) => {
           
            let data = action.payload
            console.log(data)
            state.lineItems[data.index][data.name] = data.value
        },
        
    },
 
})

export const { 
    
    setEnquiriesLoading, 
    setPage,
    getEnquirySuccess, 
    getEnquiryFailure,
    createEnquirySuccess,
    createEnquiryFailure,
    setEnquiryImageUrl,
    unsetEnquiryImageUrl,
    updateEnquirySuccess,
    updateEnquiryFailure,
    getEnquiryByIdSuccess,
    getEnquiryByIdFailed,
    setId,
    setUpdateEnquiryImageUrl,
    unsetUpdateEnquiryImageUrl,
    addLineItems,
    deleteLineItems,
    lineItemsHandleChange,
    setInitialState,
    

} = enquiriesSlice.actions



export default enquiriesSlice.reducer;


