import { toast } from "react-toastify"
import api from "../../../api"
import browserHistory from "../../../config/browserHistory"
import fire from "../../../config/fire"
import { layoutFetchUserFailure, layoutFetchUserSuccess, setLoading } from "../../auth/userSlice"
import storage from "redux-persist/lib/storage"


export const fetchCustomer = (id) => async (dispatch, getState) => {

    debugger

    const {userSlice} = getState()

    const uid = userSlice.user.payload.uid
   

    const authToken = userSlice.authToken

    console.log(uid)
   
    dispatch(setLoading())
  
    try {

        // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
        // console.log(token)


        const response = await api.get(`api/customers/${id}`, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
           
        console.log(response.data)

        let result = JSON.stringify(response.data)
		let data = JSON.parse(result).data


        dispatch(layoutFetchUserSuccess(data))
        
    
    } catch (error) {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!")
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }
        }else{
            dispatch(layoutFetchUserFailure(error.message))
            toast.error(error.message)
        }
    }
}
