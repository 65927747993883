import { toast } from "react-toastify"
import api from "../../../api"
import browserHistory from "../../../config/browserHistory"
import fire from "../../../config/fire"
import {addBillingAddressFailure, addBillingAddressSuccess, deleteBillingAddress, setProfileSuccess, addShippingAddressFailure, addShippingAddressSuccess, setAddress, setAddressFailed, setBillingActive, setProfileLoading, setShippingActive, deleteShippingAddress} from '../profileSlice'
import storage from "redux-persist/lib/storage"




export const fetchProfile = (payload) => async (dispatch, getState) => {

    debugger

    const {userSlice} = getState()

   

    if(userSlice.user == undefined || userSlice.user == null || userSlice.user == ""){
        fire.auth().signOut();
        localStorage.removeItem('currentUser')
        window.localStorage.clear()
        browserHistory.push('/login')
    }
    
    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken

    console.log(uid)

   
    dispatch(setProfileLoading(true))
  
    try {

        // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
        // console.log(token)

        const response = await api.get(`api/customers/${uid}`, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
        console.log(response.data)

        let result = JSON.stringify(response.data)
        let data = JSON.parse(result).data
        
        console.log(data)

        dispatch(setProfileLoading(false))
        dispatch(setProfileSuccess(data))

    } catch (error) {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!")
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }
        }else{
        dispatch(setAddressFailed(error.message))
        toast.error(error.message)
        }
    }
}



export const updateProfile = (payload) => async (dispatch, getState) => {

    const {userSlice} = getState()
    
    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken

    console.log(uid)

   
    dispatch(setProfileLoading(true))
  
    try {

        // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
        // console.log(token)

        const response = await api.post(`api/customers/update`, payload, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
        console.log(response.data)

        let result = JSON.stringify(response.data)
        let data = JSON.parse(result).data
        
        console.log(data)

        dispatch(setProfileLoading(false))
        dispatch(setProfileSuccess(data))
        toast.success('Updated successfully')
        window.location.reload()

    } catch (error) {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!")
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }
        }else{
            dispatch(fetchProfile(uid))
            toast.error(error.message)
        }
    }
}



export const fetchAddress = (payload) => async (dispatch, getState) => {

    
    

    const {userSlice} = getState()
    
    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken

    console.log(payload)
   
    dispatch(setProfileLoading())
  
    try {
        
        // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
        // console.log(token)

        const response = await api.get(`api/cj/customer/address/${payload}?`, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
        console.log(response.data)

        let result = JSON.stringify(response.data)
        let data = JSON.parse(result).data
        
        console.log(data)

        let billingAddress = []
        let shippingAddress = []
        let deliverySelectedOption = ''
        let billingSelectedOption = ''

        data.map((el, i) => {
            if(el.addressType == "delivery"){
                shippingAddress.push(el)
                if(el.isActive == 1){
                    //dispatch(setShippingActive(el.id))
                    deliverySelectedOption =  el.id
                }
            }else {
                billingAddress.push(el)
                if(el.isActive == 1){
                    //dispatch(setBillingActive(el.id))
                    billingSelectedOption =  el.id
                }
            }
        })
        let final_result = {
            billingAddress: billingAddress,
            shippingAddress: shippingAddress,
            deliverySelectedOption: deliverySelectedOption,
            billingSelectedOption: billingSelectedOption
        }
        dispatch(setAddress(final_result))

    } catch (error) {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!")
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }
        }else{
            dispatch(setAddressFailed(error.message))
            toast.error(error.message)
        }
    }
}


export const createShippingAddress = (payload) => async(dispatch, getState) => {

    const {userSlice} = getState()
    
    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken
   
   console.log(payload)
    try {

        // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
        // console.log(token)

        const response = await api.post(`api/cj/customer/address/add`, payload, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
        console.log(response.data)

        let result = JSON.stringify(response.data)
        let res_data = JSON.parse(result)
        
        console.log(res_data)

        if(res_data.status == true){
            dispatch(fetchAddress(payload.customerId))

            if(payload.addressType == "delivery") {
                dispatch(addShippingAddressSuccess())
                toast.success("Added Successfully")
            }else {
                dispatch(addBillingAddressSuccess())
                toast.success("Added Successfully")
            }
        }else{

            if(payload.addressType == "delivery") {
                dispatch(addShippingAddressFailure(res_data.error.message))
            }else {
                dispatch(addBillingAddressFailure(res_data.error.message))
            }
            
            toast.error(res_data.error.message)

        }

       
        
        // window.location.reload()

    } catch (error) {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!")
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }
        }else{
            if(payload.addressType == "delivery") {
                dispatch(addShippingAddressFailure(error.message))
            }else {
                dispatch(addBillingAddressFailure(error.message))
            }
            
            toast.error(error.message)
        }
    }
}


export const updateAddress = (payload) => async(dispatch, getState) => {

    const {userSlice} = getState()
    
    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken
   
    console.log(payload)
     try {

        // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
        // console.log(token)


         const response = await api.post(`api/cj/customer/address/update`, payload, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
         console.log(response.data)
 
         let result = JSON.stringify(response.data)
         let data = JSON.parse(result).data
         
         console.log(data)
 
         dispatch(fetchAddress(payload.customerId))
 
        toast.success("Updated Successfully")
        window.location.reload()
 
     } catch (error) {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!")
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }else{
                toast.error(error.response.data.error)
            }
        }else{
            if(payload.addressType == "delivery") {
                
                dispatch(addShippingAddressFailure(error.message))
            }else {
                dispatch(addBillingAddressFailure(error.message))
            }
            
            toast.error(error.message)
        }
     }
 }
 
 



export const setAddressSelectedOption = (payload) => async(dispatch, getState) => {

    const {userSlice} = getState()

    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken

     try {

        debugger
        // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
        // console.log(token)

         const response = await api.put(`api/cj/customer/address/update/status`, payload, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
         console.log(response.data)
 
         let result = JSON.stringify(response.data)
         
         let data = JSON.parse(result).data
        
            dispatch(fetchAddress(uid))
        // if(response.data.status == "true") {
        //     if(payload.addressType == "delivery") {
        //         dispatch(setShippingActive(payload))
        //     }else {
        //         dispatch(setBillingActive(payload))
        //     }

     } catch (error) {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!")
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }
        }else{
        
         toast.error(error.message)
        }
     }
 }


 export const deleteShipping = (payload) => async(dispatch, getState) => {

    
    console.log(payload)

    const {userSlice} = getState()

    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken


    const {profileSlice} = getState()

    const shippingAddress = profileSlice.shipping_address1

     try {

        if(shippingAddress[payload.index].status == "new"){
			
            dispatch(deleteShippingAddress(payload))
		} else {

            // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
            // console.log(token)
    

            const response = await api.delete(`api/cj/customer/address/delete/${payload.id}`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            })

            let res = JSON.stringify(response.data)

            let res_data = JSON.parse(res)

            if(res_data.status == true) {
                dispatch(deleteShippingAddress(payload))
                toast.success("Deleted Successfully")
            }
        
        }

     } catch (error) {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!")
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }
        }else{
         toast.error(error.message)
        }
     }
 }


 export const deleteBilling = (payload) => async(dispatch, getState) => {

    const {userSlice} = getState()

    const uid = userSlice.user.payload.uid

    const authToken = userSlice.authToken


    
    console.log(payload)

    const {profileSlice} = getState()

    const billingAddress = profileSlice.billing_address1

     try {

        if(billingAddress[payload.index].status == "new"){
			
            dispatch(deleteBillingAddress(payload))
		} else {

            // const token = await fire.auth().currentUser.getIdToken(/* forceRefresh */ true)
            // console.log(token)

            const response = await api.delete(`api/cj/customer/address/delete/${payload.id}`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            })

            let res = JSON.stringify(response.data)

            let res_data = JSON.parse(res)

            if(res_data.status == true) {
                dispatch(deleteBillingAddress(payload))
                toast.success("Deleted Successfully")
            }
        
        }

     } catch (error) {
        if(error.response){
            if(error.response.data.status == 403){
                toast.error("Session Expired, Please Login again!!")
                fire.auth().signOut();
                localStorage.clear();
                storage.removeItem('persist:root')
                browserHistory.push('/login')
            }
        }else{
        
         toast.error(error.message)
        }
     }
 }




 
 
 
 
 





