import { configureStore, createStore,getDefaultMiddleware } from '@reduxjs/toolkit';
import persistedReducer from './rootReducer'


const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
})


export default store;


