import { createSlice, createAction} from '@reduxjs/toolkit';


export const ordersSlice = createSlice({
    name:'orders',
    initialState: {
        loading: false,
        hasErrors: null,
        orders:[],
        pageNo: 1,
        imageUrl:[],
        totalElements: ''
        
    },
    reducers: {

        setInitial: state => {
            state.hasErrors = null
        },

        setOrdersLoading : state => {
            state.loading = true
        },

        unSetOrdersLoading : state => {
            state.loading = false
        },

        setPage : (state, payload) => {
            state.pageNo = payload
        },

        getOrdersSuccess : (state, action) => {
            state.orders = action.payload.enquiryOrders
            state.totalElements = action.payload.totalElements
            state.loading = false
            state.hasErrors = null
        },

        getOrdersFailure : (state, action) => {
            state.loading = false
            state.hasErrors = action.payload
        },
        
    },
 
})

export const { 
    unSetOrdersLoading,
    setOrdersLoading, 
    setPage,
    getOrdersSuccess, 
    getOrdersFailure,
    setInitial,
} = ordersSlice.actions



export default ordersSlice.reducer;


